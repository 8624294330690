import React from "react";
import {
    Page,
    Text,
    View,
    Document,
    Font,
    StyleSheet
} from "@react-pdf/renderer";

Font.register({
    family: 'OpenSans-Regular',
    src: 'https://fonts.gstatic.com/s/opensans/v15/mem8YaGs126MiZpBA-UFVZ0e.ttf'
  });

const styles = StyleSheet.create({
    page: {
        backgroundColor: "#f6f6f5",
        textAlign: 'left',
        display: "flex",
        height: "100%",
        margin: 20
    },
    rowContainer: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        width: "50%",
        alignItems: "left",
        margin: 8
    },
    rowHrline: {
        borderBottomColor: "#2D6C9B",
        borderBottomWidth: '2'
    },
    rowContainerFull: {
        display: "inline-block",
        textAlign: "left",
        width: "100%",
        marginBottom: 8
    },
    row: {
        display: "flex",
        flexDirection: "row",
        flexWrap:"wrap"
    },
    rowFull: {
       	margin: 'auto'
    },
    column: {
        display: "flex",
        flexDirection: "column",
        flexBasis: "100%",
        flex: 1
    },
    columnFull: {
        display: "flex",
        flexDirection: "column",
        flexBasis: "100%",
        flex: 1
    },
    field_header: {
        padding: 2,
        fontSize: 12,
        fontFamily: 'OpenSans-Regular',
        fontWeight: 700
    },
    field_text: {
        padding: 2,
        fontFamily: 'OpenSans-Regular',
        fontSize: 9
    },
    sectionHeader: {
        color: '#000000',
        fontSize: 14,
        margin: 8,
        fontFamily: 'OpenSans-Regular'
    },
    subHeader: {
        color: '#000000',
        fontSize: 12,
        margin: 6,
        fontFamily: 'OpenSans-Regular'
    },
    pageNumbers: {
        position: 'absolute',
        fontSize: 14,
        bottom: 5,
        left: 0,
        right: 0,
        textAlign: 'center'
    }
});

export function WarrantyRegistrationPdfDocument(props) {
    //console.log("pdf props", props.data);
    const newWarrantyRegistration = props.data;

    return (
        <Document>
            <Page style={styles.page}>
                    <Text style={styles.sectionHeader}>Warranty Registration</Text>
                    <View style={styles.rowContainer}>
                        <View style={styles.row}>
                            <View style={styles.column}>
                                <Text style={styles.field_text}>Date Submitted: {newWarrantyRegistration.submittedon}</Text>
                                <Text style={styles.field_text}>Registration Status: {newWarrantyRegistration.status}</Text>
                            </View>
                        </View>
                    </View>
                    <Text style={styles.sectionHeader}>Dealer Information</Text>
                    <View style={styles.rowContainer}>
                        <View style={styles.row}>
                            <View style={styles.column}>
                                <Text style={styles.field_text}>{newWarrantyRegistration.admin_name}</Text>
                                <Text style={styles.field_text}>{newWarrantyRegistration.admin_email}</Text>
                            </View>
                        </View>
                    </View>
                    <Text style={styles.sectionHeader}>Product Information</Text>
                    <View style={styles.rowContainer}>
                        <View style={styles.row}>
                            <View style={styles.column}>
                                <Text style={styles.field_text}>HIN: {newWarrantyRegistration.hull_identification_number}</Text>
                                <Text style={styles.field_text}>Brand: {newWarrantyRegistration.brand}</Text>
                                <Text style={styles.field_text}>Model: {newWarrantyRegistration.model}</Text>
                                <Text style={styles.field_text}>Older Model: {newWarrantyRegistration.boat_model ? (newWarrantyRegistration.boat_model.older_model ? "Yes" : "No") : ""}</Text>
                                <Text style={styles.field_text}>Purchase Date: {newWarrantyRegistration.purchase_date}</Text>
                                <Text style={styles.field_text}>Primary Use: {newWarrantyRegistration.primary_use}</Text>
                            </View>
                        </View>
                    </View>
                    <Text style={styles.sectionHeader}>Customer Information</Text>
                    <View style={styles.rowContainer}>
                        <View style={styles.row}>
                            <View style={styles.column}>
                                <Text style={styles.field_text}>Phone: {newWarrantyRegistration.phone}</Text>
                                <Text style={styles.field_text}>Email: {newWarrantyRegistration.email}</Text>
                                <Text style={styles.field_text}>Address: </Text>
                                <Text style={styles.field_text}>{newWarrantyRegistration.first_name} {newWarrantyRegistration.last_name}</Text>
                                <Text style={styles.field_text}>{newWarrantyRegistration.address}</Text>
                                <Text style={styles.field_text}>{newWarrantyRegistration.city}, {newWarrantyRegistration.region} {newWarrantyRegistration.country} {newWarrantyRegistration.zipcode}</Text>
                            </View>
                        </View>
                    </View>
                    <Text style={styles.sectionHeader}>Registration Information</Text>
                    <View style={styles.rowContainer}>
                        <View style={styles.row}>
                            <View style={styles.column}>
                                <Text style={styles.field_header}>Motor Details</Text>
                                <Text style={styles.field_text}>{newWarrantyRegistration.motor_details || " "}</Text>
                            </View>
                        </View>
                        <View style={styles.row}>
                            <View style={styles.column}>
                                <Text style={styles.field_header}>Accessories</Text>
                                <Text style={styles.field_text}>{newWarrantyRegistration.accessories || " "}</Text>
                            </View>
                        </View>
                    </View>
                    <Text style={styles.sectionHeader}>Comments</Text>
                    <View style={styles.rowContainer}>
                        <View style={styles.row}>
                            <View style={styles.column}>
                                <Text style={styles.field_text}>{newWarrantyRegistration.comments || " "}</Text>
                            </View>
                        </View>
                    </View>
                    <Text style={styles.pageNumbers} render={({ pageNumber, totalPages }) => (
                        `${pageNumber} / ${totalPages}`
                    )} fixed />
            </Page>
        </Document>
    );
}