import React, { useState, useEffect } from 'react'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Button } from "@material-ui/core";
import { Mutation } from 'react-apollo';
import { toast } from 'react-toastify';
import { ADD_CO_OP_COMMENTS } from './queries';
import auth from 'Auth';

export default function CommentBox({ setIsComment, isComment, text, setText, co_op_id, userName }) {
    const [editorSize, setEditorSize] = useState('normal');
    const [isSubmit, setIsSubmit] = useState(false)

    const handleTextChange = (value) => {
        setText(value);
    };

    const handleMinimize = () => {
        setEditorSize('minimized');
    };

    const handleMaximize = () => {
        setEditorSize('maximized');
    };

    useEffect(() => {
        const quillToolbar = document.querySelector('.ql-toolbar');
        if (quillToolbar) {
            let minimizeButton = document.createElement('button');
            minimizeButton.innerHTML = '<svg viewBox="-0.5 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M11.5 4.25H6.5C5.43913 4.25 4.42178 4.67142 3.67163 5.42157C2.92149 6.17172 2.5 7.18913 2.5 8.25V18.25C2.5 19.3109 2.92149 20.3283 3.67163 21.0784C4.42178 21.8286 5.43913 22.25 6.5 22.25H16.5C17.5609 22.25 18.5783 21.8286 19.3284 21.0784C20.0786 20.3283 20.5 19.3109 20.5 18.25V13.25" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M21.4998 3.28998L12.0098 12.78" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M17.1396 13.37H13.4297C12.8993 13.37 12.3905 13.1593 12.0154 12.7842C11.6404 12.4092 11.4297 11.9004 11.4297 11.37V7.65997" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>';
            minimizeButton.addEventListener('click', handleMinimize);
            quillToolbar.appendChild(minimizeButton);
            minimizeButton.classList.add('minimize-btn');

            let maximizeButton = document.createElement('button');
            maximizeButton.innerHTML = '<svg viewBox="-0.5 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M11.5 4.25H6.5C5.43913 4.25 4.42178 4.67142 3.67163 5.42157C2.92149 6.17172 2.5 7.18913 2.5 8.25V18.25C2.5 19.3109 2.92149 20.3283 3.67163 21.0784C4.42178 21.8286 5.43913 22.25 6.5 22.25H16.5C17.5609 22.25 18.5783 21.8286 19.3284 21.0784C20.0786 20.3283 20.5 19.3109 20.5 18.25V13.25" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M11.4297 13.38L20.9197 3.89001" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M15.7891 3.25H19.499C20.0295 3.25 20.5382 3.46074 20.9133 3.83582C21.2883 4.21089 21.499 4.71957 21.499 5.25V8.96002" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>';
            maximizeButton.addEventListener('click', handleMaximize);
            quillToolbar.appendChild(maximizeButton);
            maximizeButton.classList.add('maximize-btn');
        }
    }, []);

    let editorStyle = {};

    if (editorSize === 'minimized') {
        editorStyle = { height: '170px' };
    } else if (editorSize === 'maximized') {
        editorStyle = { height: '450px' };
    } else {
        editorStyle = { height: '300px' };
    }

    const completedUpdate = () => {
        toast("Comment added successfully");
        setIsSubmit(false)
    }

    return (
        <>
            <ReactQuill
                value={text}
                onChange={handleTextChange}
                modules={{
                    toolbar: {
                        container: [
                            [{ 'size': ['small', false, 'large', 'huge'] }, 'clean', 'bold', 'italic', 'underline', 'strike', { list: 'bullet' }, { list: 'ordered' }, { 'direction': 'rtl' }, 'link', 'image', 'blockquote', 'code-block',],

                        ],
                    }
                }}
                style={editorStyle}
            />
            {isComment &&
                <>
                    <Mutation mutation={ADD_CO_OP_COMMENTS} onCompleted={completedUpdate}>
                        {(appCoOpComment, { data, loading, called, error }) => {
                            if (error)
                                return (
                                    <pre>
                                        Bad:{" "}
                                        {error.graphQLErrors.map(({ message }, i) => (
                                            <span key={i}>{message}</span>
                                        ))}
                                    </pre>
                                );
                            return (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={isSubmit}
                                    onClick={(e) => {
                                        appCoOpComment({
                                            variables: {
                                                objects: [{
                                                    co_op_id,
                                                    profile_id: auth.getSub(),
                                                    profile_name: userName,
                                                    comment: text,
                                                    // status: "deny"
                                                }]
                                            }
                                        }).then(() => {
                                            setIsComment(false)
                                            setText("")
                                        })
                                    }}
                                    style={{ marginRight: "8px" }}>
                                    Save
                                </Button>
                            );
                        }}
                    </Mutation>
                    <Button
                        variant="contained"
                        color="inherit"
                        onClick={() => {
                            setText('')
                            setIsComment(false)
                        }}
                    >
                        CANCEL
                    </Button>
                </>
            }
        </>
    )
}
