import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import {
  SelectionState,
  RowDetailState,
  PagingState,
  IntegratedPaging,
  IntegratedSelection,
  IntegratedSummary,
  FilteringState,
  IntegratedFiltering
} from "@devexpress/dx-react-grid";
import {
  Grid as DataGrid,
  Table,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  PagingPanel,
  TableFilterRow
} from "@devexpress/dx-react-grid-material-ui";
// Shared layouts
import { toast } from "react-toastify";
import { getAllUsers } from "queries";
import { Subscription } from "react-apollo";
import { DataTypeProvider } from '@devexpress/dx-react-grid';
// Externals
import PropTypes from "prop-types";
//import { withStyles } from "@material-ui/core";
import { Typography, withStyles } from "@material-ui/core";
import EditUserButton from "./EditUserButton";
import SendEmailVerificationButton from "./SendEmailVerificationButton";

const RowDetail = ({ row }) => (
  <div>
  <table cellSpacing="0" className="detailTable">
    <tbody>
      <tr>
        <td>Name:</td>
        <td>{row.name}</td>
      </tr>
      <tr>
        <td>Email:</td>
        <td>{row.email}</td>
      </tr>
      <tr>
        <td>Role:</td>
        <td>{row.role}</td>
      </tr>
        <tr>
          <td>
            <EditUserButton item={row} />
          </td>
          <td>
            <SendEmailVerificationButton item={row} />
          </td>
        </tr>
    </tbody>
  </table>
  </div>
);

const styles = theme => ({
  button: {
    margin: theme.spacing()
  },
  input: {
    display: "none"
  },
  root: {
    padding: theme.spacing(4),
    background: "#fafafa"
  },
  item: {
    height: "100%"
  },
  marginFix: {
    marginTop: 19,
    marginBottom: 10,
    marginLeft: 5,
    marginRight: 5
  },
  rowsSelected: {
    textAlign: "center",
    fontWeight: 300,
    textTransform: "uppercase"
  }
});

function UsersGrid(props) {
  const { classes } = props;
  const [columns] = useState([
    { name: "created_at", title: "DATE ADDED" },
    { name: "name", title: "NAME" },
    { name: "email", title: "EMAIL" },
    { name: "status", title: "STATUS" }
    // {
    //   name: "id",
    //   title: "Media Amount",
    //   getCellValue: row =>
    //     row.media_items_aggregate.aggregate
    //       ? row.media_items_aggregate.aggregate.count
    //       : undefined
    // },
  ]);

  const [selection, setSelection] = useState([]);
  const [dateColumns] = useState(['created_at']);
  const DateFormatter = ({ value }) => value.replace(/(\d{4})-(\d{2})-(\d{2})(T\d{2}:\d{2}:\d{2}.\d{5,6}\+\d{2}:\d{2})/, '$2.$3.$1');
  const DateTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={DateFormatter}
    {...props}
  />
);

  return (
    <div className={classes.root + " sectionContainer"}>
      <Paper style={{ padding: "2em" }}>
        <Typography gutterBottom variant="h2" className={classes.rowsSelected}>
          <span>Total rows selected: {selection.length}</span>
        </Typography>
        <Subscription subscription={getAllUsers}>
          {({ data, loading, error }) => {
            if (loading) return <p>Loading...</p>;
            if (error) return <p>Error</p>;

            return (
              <div className="tableStyles">
                <DataGrid rows={data.profiles} columns={columns}>
                  <DateTypeProvider
                    for={dateColumns}
                  />
                  <SelectionState
                    selection={selection}
                    onSelectionChange={setSelection}
                  />
                  <FilteringState defaultFilters={[]} />
                  <IntegratedFiltering />
                  <PagingState defaultCurrentPage={0} pageSize={5} />
                  <IntegratedSelection />
                  <IntegratedPaging />
                  <RowDetailState defaultExpandedRowIds={[0]} />
                  <Table />
                  <TableHeaderRow />
                  <TableFilterRow />
                  <TableRowDetail contentComponent={RowDetail} />
                  <TableSelection showSelectAll />
                  <PagingPanel />
                </DataGrid>
              </div>
            );
          }}
        </Subscription>
      </Paper>
    </div>
  );
}

UsersGrid.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(UsersGrid);
