import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import { Button, Grid } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { Mutation, Subscription } from "react-apollo";
import { GET_USER_MESSAGE_STATUS, UPDATE_MESSAGE_MESSAGE_STATUS, getAllMessages } from "queries";
import DeleteModal from "./DeleteModal";
import auth from "Auth";
import { toast } from "react-toastify";
import moment from "moment";

function Messages({ toggleIsMessage, isMessage, isAdmin, setEditDetail, editDetail }) {
  const isDealer = auth.getRole() === "dealer"
  const [isSubmit, setIsSubmit] = useState(false);

  const convertTime = (dateStr) => {
    const parsedDate = moment(dateStr);

    let relativeTime = parsedDate.fromNow();

    relativeTime = relativeTime.replace('a few seconds', 'secs')
      .replace('a minute', '1 min')
      .replace(' minutes', 'mins')
      .replace(' an hour', '1 hr')
      .replace(' hours', 'hrs')
      .replace('a day', '1 day')
      .replace(' days', 'days');

    return relativeTime;
  };

  const OnMessageRead = (e, updateMessage, id) => {
    setIsSubmit(true)
    const currentTimeUtc = moment.utc()
    e.preventDefault();
    updateMessage({
      variables:
      {
        id,
        is_read: 1,
        read_at: currentTimeUtc,
        updated_at: currentTimeUtc
      }
    });
  }

  const completedUpdate = () => {
    toast("Message read successfully");
    setIsSubmit(false)
  }

  return (
    <Subscription subscription={isDealer ? GET_USER_MESSAGE_STATUS : getAllMessages} variables={isDealer ? { userId: auth.getSub() } : {}} >
      {({ data, loading, error }) => {
        if (loading) return <p>Loading...</p>;
        if (error) return <p>Error</p>;
        return (
          <>
            {isDealer ?
              <>
                {data?.admin_message_status?.length === 0 ? "No Data" :
                  <>
                    {data?.admin_message_status?.map((item) => {
                      return (
                        <div className="messageItembox" key={item?.id}>
                          <Grid container spacing={2} direction="row">
                            <Grid item xs={isAdmin === "admin" ? 10 : 12}>
                              {!isMessage && (
                                <Typography
                                  variant="body2"
                                  style={{ color: "#95989A" }}
                                >
                                  {convertTime(item?.created_at)}
                                </Typography>
                              )}
                            </Grid>
                          </Grid>
                          <Grid container spacing={2} alignItems="center">
                            <Grid item xs={11}>
                              <Typography variant="body1" >
                                <div dangerouslySetInnerHTML={{ __html: (item?.admin_message?.message) || "" }}></div>
                              </Typography>
                            </Grid>
                            {
                              item?.is_read === 0 &&
                              <Grid item xs={1}>
                                <Mutation mutation={UPDATE_MESSAGE_MESSAGE_STATUS} onCompleted={completedUpdate}>
                                  {(updateMessage, { data, loading, called, error }) => {
                                    if (error)
                                      return (
                                        <pre>
                                          Bad:{" "}
                                          {error.graphQLErrors.map(({ message }, i) => (
                                            <span key={i}>{message}</span>
                                          ))}
                                        </pre>
                                      );
                                    return (
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        // className={classes.button}
                                        disabled={isSubmit}
                                        onClick={(e) => OnMessageRead(e, updateMessage, item?.id)}
                                      >
                                        Read
                                      </Button>
                                    );
                                  }}
                                </Mutation>
                              </Grid>
                            }
                          </Grid>
                        </div>
                      );
                    })}
                  </>
                }
              </>
              :
              <>
                {!editDetail && data?.admin_messages?.map((item) => {
                  return (
                    <div className="messageItembox" key={item?.id}>
                      <Grid container spacing={2} direction="row">
                        <Grid item xs={isAdmin === "admin" ? 10 : 12}>
                          {/* {!isMessage && ( */}
                          <Typography
                            variant="body2"
                            style={{ color: "#95989A" }}
                          >
                            {convertTime(item?.created_at)}
                          </Typography>
                          {/* )} */}
                        </Grid>
                        <Grid item xs={1} alignItems="center">
                          {item?.profile_id === auth.getSub() && (
                            <EditIcon
                              fontSize="small"
                              style={{
                                color: "#3B6894",
                                width: "18px",
                                margin: "0px auto",
                                display: "block",
                                cursor: 'pointer'
                              }}
                              onClick={() => {
                                toggleIsMessage(true);
                                setEditDetail(item)
                              }}
                            />
                          )}
                        </Grid>
                        <Grid item xs={1}>
                          {item?.profile_id === auth.getSub() &&
                            <DeleteModal messageId={item?.id} />
                          }
                        </Grid>
                      </Grid>
                      {/* {!isMessage && */}
                      <Grid item xs={11}>
                        <Typography variant="body1" >
                          <div dangerouslySetInnerHTML={{ __html: (item?.message) || "" }}></div>
                        </Typography>
                      </Grid>
                      {/* } */}
                    </div>
                  );
                })}
              </>
            }
          </>
        );
      }}
    </Subscription>
  );
}

export default Messages;
