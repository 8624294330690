import React, { useState, useRef, useEffect } from "react";
import { Mutation, Query } from "react-apollo";
import { withStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import {
  Paper,
  Typography,
  TextField,
  Grid,
  FormControl,
  OutlinedInput,
  InputAdornment,
  FormLabel,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import useForm from "components/useForm";
// Shared layouts
import { Dashboard as DashboardLayout } from "layouts";
// Component styles
import styles from "theme/skin";
import {
  ADD_COMMERICAL_SALES_CREDIT_CLAIM_DATA,
  DELETE_COMMERICAL_SALES_CREDIT_CLAIM_DATA,
  GET_COMMERCIAL_SALES_CREDIT_CLAIM_INFO,
  UPDATE_COMMERICAL_SALES_CREDIT_CLAIM,
  UPDATE_COMMERICAL_SALES_CREDIT_CLAIM_DATA,
} from "./queries";
import apollo from "apollo";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";

const APP_SERVER = apollo.APP_UPLOAD_SERVER_URL;

function CommericalSalesCreditClaimEdit(props) {
  const { classes, history } = props;
  const {
    match: { params },
  } = props;
  const formRef = useRef(null);

  const initialFormsData = new Array(11).fill(null).map(() => ({
    buyer_name: '',
    item_name: '',
    serial_no: '',
    dealer_cost: 0,
    credit: 0,
    total_credit: 0,
  }));

  const { values, handleChange, resetState, handleChangeCheckbox, setValues } =
    useForm(submitForm);
  const [files, setFiles] = useState({});
  const [errors, setErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false)
  const [formsData, setFormsData] = useState(initialFormsData);

  function submitForm() { }

  useEffect(() => {
    const allErrors = validateAllRows();
    setErrors(allErrors);
  }, [formsData]);

  const completedAdd = (payload) => {
    toast(
      "Government Commerical Sales Credit Claim was been succesfully updated"
    );
    history.push("/commerical-sales-credit-claim")
  };

  const validateRow = (row, rowIndex) => {
    let filteredObj = {
      "buyer_name": row?.buyer_name,
      "item_name": row?.item_name,
      "serial_no": row?.serial_no,
      "dealer_cost": row?.dealer_cost,
      "credit": row?.credit,
      "total_credit": row?.total_credit
    };

    let rowErrors = {};
    const isRowFilled = Object.values(filteredObj).some((value) => value !== null && value !== "" && value !== 0);

    if (isRowFilled) {
      if (!filteredObj.buyer_name) rowErrors.buyer_name = "Buyer name is required";
      if (!filteredObj.item_name) rowErrors.item_name = "Item name is required";
      if (!filteredObj.serial_no) rowErrors.serial_no = "Serial no is required";
      if (!filteredObj.dealer_cost) rowErrors.dealer_cost = "Dealer cost is required";
      if (!filteredObj.credit) rowErrors.credit = "Credit is required";
      if (!filteredObj.total_credit)
        rowErrors.total_credit = "Total credit is required";
    }
    return rowErrors;
  };

  const validateAllRows = () => {
    let allErrors = {};
    formsData && formsData.forEach((row, index) => {
      let rowErrors = validateRow(row, index);
      if (Object.keys(rowErrors).length > 0) {
        allErrors[index] = rowErrors;
      } else {
        delete allErrors[index];
      }
    });
    return allErrors;
  };

  const handleFileChange = (event, param) => {
    const inputFiles = event.target.files;
    if (inputFiles.length > 0) {
      const file = inputFiles[0];
      setFiles(prevState => ({
        ...prevState,
        [param]: file
      }));
    }
  };

  const handleFieldChange = (formIndex, fieldName, value) => {
    const newFormsData = formsData.map((form, index) => {
      if (index === formIndex) {
        const updatedForm = { ...form, [fieldName]: value };

        // If dealer_cost or credit changes, update total_credit as well
        if (fieldName === 'dealer_cost' || fieldName === 'credit') {
          const dealerCost = fieldName === 'dealer_cost' ? parseFloat(value) : parseFloat(form.dealer_cost);
          const credit = fieldName === 'credit' ? parseFloat(value) : parseFloat(form.credit);
          if (!isNaN(dealerCost) && !isNaN(credit)) {
            const totalCredit = (dealerCost * credit) / 100;
            updatedForm.total_credit = totalCredit.toFixed(2);
          }
        }

        return updatedForm;
      }
      return form;
    });
    setFormsData(newFormsData);
  };

  return (
    <Query
      query={GET_COMMERCIAL_SALES_CREDIT_CLAIM_INFO}
      variables={{ id: params?.id }}
    >
      {({ loading, error, data }) => {
        if (loading) return null;
        if (error) return `Error! ${error}`;
        if (values.dealer_name === undefined) {
          setValues(data?.government_commerical_sales_credit_claim_by_pk);
          const edit = data?.government_commerical_sales_credit_claim_by_pk
          setFiles({
            file1: edit?.file1,
            file2: edit?.file2,
            file3: edit?.file3,
            file4: edit?.file4
          });
          setFormsData(prevState => {
            const updateCount = Math.min(edit?.government_commerical_sales_credit_claim_data?.length, prevState?.length);

            const updatedFormsData = [...prevState];

            for (let i = 0; i < updateCount; i++) {
              updatedFormsData[i] = {
                ...updatedFormsData[i],
                buyer_name: edit?.government_commerical_sales_credit_claim_data?.[i]?.buyer_name,
                item_name: edit?.government_commerical_sales_credit_claim_data?.[i]?.item_name,
                serial_no: edit?.government_commerical_sales_credit_claim_data?.[i]?.serial_no,
                dealer_cost: edit?.government_commerical_sales_credit_claim_data?.[i]?.dealer_cost,
                credit: edit?.government_commerical_sales_credit_claim_data?.[i]?.credit,
                total_credit: edit?.government_commerical_sales_credit_claim_data?.[i]?.total_credit,
                id: edit?.government_commerical_sales_credit_claim_data?.[i]?.id,
                government_commerical_sales_credit_claim_id: edit?.government_commerical_sales_credit_claim_data?.[i]?.government_commerical_sales_credit_claim_id
              };
            }
            return updatedFormsData;
          });
        }
        if (data.government_commerical_sales_credit_claim_by_pk !== undefined) {
          return (
            <DashboardLayout>
              <Mutation
                mutation={DELETE_COMMERICAL_SALES_CREDIT_CLAIM_DATA}
              >
                {(
                  deleteCommericalSalesCreditClaimData,
                  { data, loading, called, error }
                ) => {
                  return (
                    <Mutation
                      mutation={ADD_COMMERICAL_SALES_CREDIT_CLAIM_DATA}
                    >
                      {(
                        addCommericalSalesCreditClaimData,
                        { data, loading, called, error }
                      ) => {
                        return (
                          <Mutation
                            mutation={UPDATE_COMMERICAL_SALES_CREDIT_CLAIM}
                          >
                            {(
                              updateCommericalSalesCreditClaim,
                              { data, loading, called, error }
                            ) => {
                              return (
                                <Mutation
                                  mutation={UPDATE_COMMERICAL_SALES_CREDIT_CLAIM_DATA}
                                >
                                  {(
                                    updateCommericalSalesCreditClaimData,
                                    { data, loading, called, error }
                                  ) => {
                                    return (
                                      <form
                                        ref={formRef}
                                        onSubmit={(e) => {
                                          e.preventDefault();
                                          const formErrors = validateAllRows();

                                          setErrors(formErrors);
                                          if (Object.keys(formErrors).length === 0) {
                                            setIsSubmit(true)
                                            values.country = values?.country;
                                            delete values.__typename;
                                            delete values.government_commerical_sales_credit_claim_data
                                            const uploadPromises = Object.keys(files)?.map(key => {

                                              const file = files[key];
                                              if (file && file?.name) {
                                                const data = new FormData();
                                                data.append("image", file, file?.name);
                                                return axios.post(APP_SERVER + "/", data).then(response => {
                                                  if (response.data.status === 200) {
                                                    return {
                                                      [key]: response?.data?.imageUrl
                                                    };
                                                  } else {
                                                    toast.error("Media upload failed")
                                                    throw new Error(`Upload failed for ${key}`);
                                                  }
                                                });
                                              } else {
                                                return Promise.resolve();
                                              }
                                            });

                                            Promise.all(uploadPromises)
                                              .then(uploadResults => {
                                                const allUploadUrls = Object.assign(files, ...uploadResults);
                                                const finalValues = {
                                                  ...values,
                                                  ...{ id: params?.id },
                                                  ...allUploadUrls
                                                };
                                                return updateCommericalSalesCreditClaim({
                                                  variables: finalValues
                                                }).then((value) => {
                                                  const updatedFormsData = formsData?.filter(form => form?.buyer_name !== "" && form?.id)?.map(form => ({
                                                    ...form,
                                                  }));

                                                  const bulkUpdateFormsData = async (updatedFormsData) => {
                                                    const updatePromises = updatedFormsData?.map((form) => {
                                                      const variables = {
                                                        id: form?.id,
                                                        government_commerical_sales_credit_claim_id: form?.government_commerical_sales_credit_claim_id,
                                                        buyer_name: form?.buyer_name,
                                                        item_name: form?.item_name,
                                                        serial_no: form?.serial_no.toString(),
                                                        dealer_cost: form?.dealer_cost.toString(),
                                                        credit: form?.credit.toString(),
                                                        total_credit: form?.total_credit.toString(),
                                                        updated_at: new Date().toISOString(),
                                                      };

                                                      return updateCommericalSalesCreditClaimData({ variables });
                                                    });

                                                    try {
                                                      await Promise.all(updatePromises);

                                                    } catch (error) {
                                                      console.error('Failed to update items', error);
                                                    }
                                                  };

                                                  const handleFormOperations = async (formsData) => {
                                                    try {
                                                      const createFormsData = formsData?.filter(form => form.buyer_name !== "" && !form?.id)?.map(form => ({
                                                        ...form,
                                                        government_commerical_sales_credit_claim_id: params?.id,
                                                      }));
                                                      const deleteFormsData = formsData?.filter((item) => item?.id && item?.buyer_name === "")
                                                      if (createFormsData?.length > 0) {
                                                        await addCommericalSalesCreditClaimData({
                                                          variables: {
                                                            objects: createFormsData,
                                                          },
                                                        });
                                                      }

                                                      if (updatedFormsData?.length > 0) {
                                                        await bulkUpdateFormsData(updatedFormsData);
                                                      }
                                                      if (deleteFormsData?.length > 0) {
                                                        await deleteCommericalSalesCreditClaimData({
                                                          variables: {
                                                            ids: deleteFormsData?.map((item) => item?.id),
                                                          }
                                                        });
                                                      }
                                                    } catch (error) {
                                                      console.error('An error occurred during form operations:', error);
                                                    } finally {
                                                      setFormsData(initialFormsData);
                                                      setIsSubmit(false)
                                                      resetState();
                                                      setFiles({})
                                                      completedAdd()
                                                    }
                                                  };
                                                  handleFormOperations(formsData)
                                                })
                                              })
                                              .catch(error => {
                                                console.error(error);
                                              });
                                            return false;
                                          } else {
                                            formRef.current.reportValidity();
                                          }
                                        }}
                                        autoComplete="off"
                                      >
                                        <div className={classes.root + " sectionContainer"}>
                                          <Typography gutterBottom variant="h4" className="title">
                                            <span>GOVERNMENT/COMMERICAL SALES CREDIT CLAIM</span>
                                          </Typography>
                                          <Typography
                                            gutterBottom
                                            variant="h5"
                                            className={classes.subtitle3}
                                            align="left"
                                          >
                                            FORMS WAITING FOR APPROVAL
                                          </Typography>


                                          <br></br>
                                          <br></br>
                                          <Typography
                                            gutterBottom
                                            variant="h5"
                                            className={classes.subtitle3}
                                            style={{
                                              backgroundColor: "#BED4E5",
                                              textAlign: "left",
                                              padding: "1em",
                                              color: "#23333F",
                                              margin: "0px",
                                            }}
                                          >
                                            GOVERNMENT/COMMERICAL SALES CREDIT CLAIM                                                                    </Typography>
                                          <Paper
                                            className={classes.paper}
                                            style={{
                                              padding: "2em",
                                              backgroundColor: "#F9FCFF",
                                              boxShadow: "unset",
                                            }}
                                          >
                                            <Typography
                                              gutterBottom
                                              variant="h5"
                                              align="center"
                                              className={classes.subTitle2}
                                              style={{ margin: "0px auto 15px", maxWidth: "80%" }}
                                            >
                                              <strong>CAROLINA SKIFF:</strong> 3231 Fulford Road Waycross
                                              Georgia 31503 <strong>PHONE:</strong> 912-287-0547{" "}
                                              <strong>FAX:</strong> 912-287-0533
                                            </Typography>
                                            <Typography
                                              gutterBottom
                                              variant="h6"
                                              align="center"
                                              style={{ margin: "0px auto", maxWidth: "80%" }}
                                              className={classes.subTitle2}
                                            >
                                              Carolina Skiff LLC will credit 5% of the Dealer boat cost
                                              for any Carolina Skiff product sold to a licensed
                                              commercial agency such as, Utility Commissions, Government
                                              Agency, Local State Agency, Boat Rental, Comercial Guide
                                              Fisherman or any other type of commercial business
                                              approved by Carolina Skiff LLC.
                                            </Typography>
                                            <br></br>
                                            <br></br>
                                            <div>
                                              <Grid container spacing={4}>
                                                <Grid item xs={12} sm={4}>
                                                  <TextField
                                                    required
                                                    name="dealer_name"
                                                    label="Dealer Name"
                                                    className={classes.textField}
                                                    style={{ width: "100%" }}
                                                    onChange={handleChange}
                                                    margin="normal"
                                                    value={values?.dealer_name || ""}
                                                    variant="outlined"
                                                  />
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                  <TextField
                                                    required
                                                    name="contact_name"
                                                    label="Contact Name"
                                                    className={classes.textField}
                                                    style={{ width: "100%" }}
                                                    onChange={handleChange}
                                                    margin="normal"
                                                    value={values?.contact_name || ""}
                                                    variant="outlined"
                                                  />
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                  <TextField
                                                    required
                                                    name="phone"
                                                    label="Phone"
                                                    className={classes.textField}
                                                    style={{ width: "100%" }}
                                                    onChange={handleChange}
                                                    margin="normal"
                                                    value={values?.phone || ""}
                                                    variant="outlined"
                                                  />
                                                </Grid>
                                              </Grid>

                                              <Grid container spacing={4}>
                                                <Grid item xs={12} sm={4}>
                                                  <TextField
                                                    required
                                                    name="fax"
                                                    label="Fax"
                                                    className={classes.textField}
                                                    style={{ width: "100%" }}
                                                    onChange={handleChange}
                                                    margin="normal"
                                                    value={values?.fax || ""}
                                                    variant="outlined"
                                                  />
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                  <TextField
                                                    required
                                                    name="address"
                                                    label="Address"
                                                    className={classes.textField}
                                                    style={{ width: "100%" }}
                                                    onChange={handleChange}
                                                    margin="normal"
                                                    value={values?.address || ""}
                                                    variant="outlined"
                                                  />
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                  <TextField
                                                    name="address2"
                                                    label="Address line 2 (Suites, Apt. # etc)"
                                                    className={classes.textField}
                                                    onChange={handleChange}
                                                    margin="normal"
                                                    variant="outlined"
                                                    value={values?.address2 || ""}
                                                    style={{ width: "100%" }}
                                                  />
                                                </Grid>
                                              </Grid>

                                              <Grid container spacing={4}>
                                                <Grid item xs={12} sm={4}>
                                                  <TextField
                                                    required
                                                    name="city"
                                                    label="City"
                                                    className={classes.textField}
                                                    onChange={handleChange}
                                                    margin="normal"
                                                    variant="outlined"
                                                    value={values?.city || ""}
                                                    style={{ width: "100%" }}
                                                  />
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                  <TextField
                                                    required
                                                    name="country"
                                                    label="Country"
                                                    select
                                                    className={classes.textField}
                                                    onChange={handleChange}
                                                    value={values?.country || ""}
                                                    style={{ width: "100%" }}
                                                    variant="outlined"
                                                    SelectProps={{
                                                      native: true,
                                                      MenuProps: {
                                                        className: classes.menu,
                                                      },
                                                    }}
                                                    margin="normal"
                                                  >
                                                    <option key="" value="" />
                                                    <option key="United States" value="United States">United States</option>
                                                  </TextField>
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                  <TextField
                                                    required
                                                    name="state"
                                                    label="State"
                                                    variant="outlined"
                                                    select
                                                    style={{ width: "100%" }}
                                                    className={classes.textField}
                                                    value={values?.state || ""}
                                                    onChange={handleChange}
                                                    SelectProps={{
                                                      native: true,
                                                      MenuProps: {
                                                        className: classes.menu,
                                                      },
                                                    }}
                                                    margin="normal"
                                                  >
                                                    <option key="" value="" />
                                                    <option key="Alabama" value="Alabama">
                                                      Alabama
                                                    </option>
                                                    <option key="Alaska" value="Alaska">
                                                      Alaska
                                                    </option>
                                                    <option key="Arizona" value="Arizona">
                                                      Arizona
                                                    </option>
                                                    <option key="Arkansas" value="Arkansas">
                                                      Arkansas
                                                    </option>
                                                    <option key="California" value="California">
                                                      California
                                                    </option>
                                                    <option key="Colorado" value="Colorado">
                                                      Colorado
                                                    </option>
                                                    <option key="Connecticut" value="Connecticut">
                                                      Connecticut
                                                    </option>
                                                    <option key="Delaware" value="Delaware">
                                                      Delaware
                                                    </option>
                                                    <option key="Florida" value="Florida">
                                                      Florida
                                                    </option>
                                                    <option key="Georgia" value="Georgia">
                                                      Georgia
                                                    </option>
                                                    <option key="Hawaii" value="Hawaii">
                                                      Hawaii
                                                    </option>
                                                    <option key="Idaho" value="Idaho">
                                                      Idaho
                                                    </option>
                                                    <option key="Illinois" value="Illinois">
                                                      Illinois
                                                    </option>
                                                    <option key="Indiana" value="Indiana">
                                                      Indiana
                                                    </option>
                                                    <option key="Iowa" value="Iowa">
                                                      Iowa
                                                    </option>
                                                    <option key="Kansas" value="Kansas">
                                                      Kansas
                                                    </option>
                                                    <option key="Kentucky" value="Kentucky">
                                                      Kentucky
                                                    </option>
                                                    <option key="Louisiana" value="Louisiana">
                                                      Louisiana
                                                    </option>
                                                    <option key="Maine" value="Maine">
                                                      Maine
                                                    </option>
                                                    <option key="Maryland" value="Maryland">
                                                      Maryland
                                                    </option>
                                                    <option
                                                      key="Massachusetts"
                                                      value="Massachusetts"
                                                    >
                                                      Massachusetts
                                                    </option>
                                                    <option key="Michigan" value="Michigan">
                                                      Michigan
                                                    </option>
                                                    <option key="Minnesota" value="Minnesota">
                                                      Minnesota
                                                    </option>
                                                    <option key="Mississippi" value="Mississippi">
                                                      Mississippi
                                                    </option>
                                                    <option key="Missouri" value="Missouri">
                                                      Missouri
                                                    </option>
                                                    <option key="Montana" value="Montana">
                                                      Montana
                                                    </option>
                                                    <option key="Nebraska" value="Nebraska">
                                                      Nebraska
                                                    </option>
                                                    <option key="Nevada" value="Nevada">
                                                      Nevada
                                                    </option>
                                                    <option
                                                      key="New Hampshire"
                                                      value="New Hampshire"
                                                    >
                                                      New Hampshire
                                                    </option>
                                                    <option key="New Jersey" value="New Jersey">
                                                      New Jersey
                                                    </option>
                                                    <option key="New Mexico" value="New Mexico">
                                                      New Mexico
                                                    </option>
                                                    <option key="New York" value="New York">
                                                      New York
                                                    </option>
                                                    <option
                                                      key="North Carolina"
                                                      value="North Carolina"
                                                    >
                                                      North Carolina
                                                    </option>
                                                    <option
                                                      key="North Dakota"
                                                      value="North Dakota"
                                                    >
                                                      North Dakota
                                                    </option>
                                                    <option key="Ohio" value="Ohio">
                                                      Ohio
                                                    </option>
                                                    <option key="Oklahoma" value="Oklahoma">
                                                      Oklahoma
                                                    </option>
                                                    <option key="Oregon" value="Oregon">
                                                      Oregon
                                                    </option>
                                                    <option
                                                      key="Pennsylvania"
                                                      value="Pennsylvania"
                                                    >
                                                      Pennsylvania
                                                    </option>
                                                    <option
                                                      key="Rhode Island"
                                                      value="Rhode Island"
                                                    >
                                                      Rhode Island
                                                    </option>
                                                    <option
                                                      key="South Carolina"
                                                      value="South Carolina"
                                                    >
                                                      South Carolina
                                                    </option>
                                                    <option
                                                      key="South Dakota"
                                                      value="South Dakota"
                                                    >
                                                      South Dakota
                                                    </option>
                                                    <option key="Tennessee" value="Tennessee">
                                                      Tennessee
                                                    </option>
                                                    <option key="Texas" value="Texas">
                                                      Texas
                                                    </option>
                                                    <option key="Utah" value="Utah">
                                                      Utah
                                                    </option>
                                                    <option key="Vermont" value="Vermont">
                                                      Vermont
                                                    </option>
                                                    <option key="Virginia" value="Virginia">
                                                      Virginia
                                                    </option>
                                                    <option key="Washington" value="Washington">
                                                      Washington
                                                    </option>
                                                    <option
                                                      key="West Virginia"
                                                      value="West Virginia"
                                                    >
                                                      West Virginia
                                                    </option>
                                                    <option key="Wisconsin" value="Wisconsin">
                                                      Wisconsin
                                                    </option>
                                                    <option key="Wyoming" value="Wyoming">
                                                      Wyoming
                                                    </option>
                                                  </TextField>
                                                </Grid>
                                              </Grid>
                                              <Grid container spacing={4}>
                                                <Grid item xs={12} sm={4}>
                                                  <TextField
                                                    name="zipcode"
                                                    label="Zip / Postal Code"
                                                    className={classes.textField}
                                                    onChange={handleChange}
                                                    margin="normal"
                                                    variant="outlined"
                                                    style={{ width: "100%" }}
                                                    value={values?.zipcode || ""}
                                                  />
                                                </Grid>
                                                {/* <Grid item xs={12} sm={4}>
                                                  <Typography
                                                    gutterBottom
                                                    variant="h6"
                                                    align="left"
                                                    className={classes.subTitle2}
                                                    style={{
                                                      width: "100%",
                                                      textAlign: "left",
                                                      marginTop: "25px",
                                                    }}
                                                  >
                                                    DATE: 3/25/2020{" "}
                                                  </Typography>
                                                </Grid> */}
                                              </Grid>
                                            </div>
                                            <br></br>
                                            <br></br>

                                            <div>
                                              <Typography
                                                gutterBottom
                                                variant="h6"
                                                align="center"
                                                style={{ maxWidth: "80%", margin: "0px auto 15px" }}
                                                className={classes.subTitle2}
                                              >
                                                <b>GOVERNMENT/COMMERICAL SALES CREDIT CLAIM</b>
                                              </Typography>
                                              <Typography
                                                gutterBottom
                                                variant="h6"
                                                align="center"
                                                style={{ maxWidth: "80%", margin: "0px auto 10px" }}
                                                className={classes.subTitle2}
                                              >
                                                Carolina Skiff LLC will credit 5% of the Dealer boat cost
                                                for any Carolina Skiff product sold to a licensed
                                                commercial agency such as, Utility Commissions, Government
                                                Agency, Local State Agency, Boat Rental, Comercial Guide
                                                Fisherman or any other type of commercial business
                                                approved by Carolina Skiff LLC.
                                              </Typography>

                                              <br></br>
                                              <br></br>
                                              {/* cliam form start */}
                                              <Grid container spacing={2} justify="space-around">
                                                <Grid item xs={12} md={2}>
                                                  <FormLabel
                                                    variant="h5"
                                                    align="center"
                                                    className={classes.subTitle2}
                                                    style={{
                                                      width: "100%",
                                                      display: "block",
                                                      marginBottom: "10px",
                                                    }}
                                                  >
                                                    <b>BUYER NAME</b>
                                                  </FormLabel>
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                  <FormLabel
                                                    variant="h5"
                                                    align="center"
                                                    className={classes.subTitle2}
                                                    style={{
                                                      width: "100%",
                                                      display: "block",
                                                      marginBottom: "10px",
                                                    }}
                                                  >
                                                    <b>ITEM/PRODUCT</b>
                                                  </FormLabel>
                                                </Grid>
                                                <Grid item xs={12} md={1}>
                                                  <FormLabel
                                                    variant="h5"
                                                    align="center"
                                                    className={classes.subTitle2}
                                                    style={{
                                                      width: "100%",
                                                      display: "block",
                                                      marginBottom: "10px",
                                                    }}
                                                  >
                                                    <b>SERIAL NO.</b>
                                                  </FormLabel>
                                                </Grid>
                                                <Grid item xs={12} md={1}>
                                                  <FormLabel
                                                    variant="h5"
                                                    align="center"
                                                    className={classes.subTitle2}
                                                    style={{
                                                      width: "100%",
                                                      display: "block",
                                                      marginBottom: "10px",
                                                    }}
                                                  >
                                                    <b>DEALER COST</b>
                                                  </FormLabel>
                                                </Grid>
                                                <Grid item xs={12} md={2}>
                                                  <FormLabel
                                                    variant="h5"
                                                    align="center"
                                                    className={classes.subTitle2}
                                                    style={{
                                                      width: "100%",
                                                      display: "block",
                                                      marginBottom: "10px",
                                                    }}
                                                  >
                                                    <b>% CREDIT</b>
                                                  </FormLabel>
                                                </Grid>
                                                <Grid item xs={12} md={1}>
                                                  <FormLabel
                                                    variant="h5"
                                                    align="center"
                                                    className={classes.subTitle2}
                                                    style={{
                                                      width: "100%",
                                                      display: "block",
                                                      marginBottom: "10px",
                                                    }}
                                                  >
                                                    <b>TOTAL CREDIT</b>
                                                  </FormLabel>
                                                </Grid>

                                              </Grid>
                                              {formsData && formsData?.length > 0 && formsData?.map((form, index) => {
                                                return (
                                                  <>
                                                    <Grid container spacing={2} justify="space-around" >
                                                      <Grid item xs={12} md={2}>
                                                        <OutlinedInput
                                                          error={!!(errors[index]?.buyer_name)}
                                                          required={!!(errors[index]?.buyer_name)}
                                                          name="buyer_name"
                                                          id="buyer_name"
                                                          endAdornment={
                                                            <InputAdornment position="end"> </InputAdornment>
                                                          }
                                                          value={form?.buyer_name || ""}
                                                          style={{ width: "100%" }}
                                                          aria-describedby="outlined-weight-helper-text"
                                                          inputProps={{
                                                            "aria-label": "weight",
                                                          }}
                                                          onChange={(e) => handleFieldChange(index, 'buyer_name', e.target.value)}
                                                        />
                                                      </Grid>
                                                      <Grid item xs={12} md={3}>
                                                        <OutlinedInput
                                                          error={!!(errors[index]?.item_name)}
                                                          required={!!(errors[index]?.item_name)}
                                                          name="item_name"
                                                          id="item_name"
                                                          endAdornment={
                                                            <InputAdornment position="end"> </InputAdornment>
                                                          }
                                                          value={form?.item_name || ""}
                                                          style={{ width: "100%" }}
                                                          aria-describedby="outlined-weight-helper-text"
                                                          inputProps={{
                                                            "aria-label": "weight",
                                                          }}
                                                          onChange={(e) => handleFieldChange(index, 'item_name', e.target.value)}
                                                        />
                                                      </Grid>
                                                      <Grid item xs={12} md={1}>
                                                        <OutlinedInput
                                                          error={!!(errors[index]?.serial_no)}
                                                          required={!!(errors[index]?.serial_no)}
                                                          name="serial_no"
                                                          id="serial_no"
                                                          type="number"
                                                          value={form?.serial_no || ''}
                                                          endAdornment={
                                                            <InputAdornment position="end"> </InputAdornment>
                                                          }
                                                          style={{ width: "100%" }}
                                                          aria-describedby="outlined-weight-helper-text"
                                                          onChange={(e) => handleFieldChange(index, 'serial_no', e.target.value)}
                                                        />
                                                      </Grid>
                                                      <Grid item xs={12} md={1}>
                                                        <OutlinedInput
                                                          error={!!(errors[index]?.dealer_cost)}
                                                          required={!!(errors[index]?.dealer_cost)}
                                                          name="dealer_cost"
                                                          id="dealer_cost"
                                                          type="number"
                                                          value={form?.dealer_cost || ''}
                                                          endAdornment={
                                                            <InputAdornment position="end"> </InputAdornment>
                                                          }
                                                          style={{ width: "100%" }}
                                                          aria-describedby="outlined-weight-helper-text"
                                                          inputProps={{
                                                            "aria-label": "weight",
                                                            "pattern": "\\d*\\.?\\d+",
                                                            "min": "0"
                                                          }}
                                                          onChange={(e) => {
                                                            const value = e.target.value;
                                                            if (value === '' || (!isNaN(value) && Number(value) >= 0)) {
                                                              handleFieldChange(index, 'dealer_cost', value.toString());
                                                            }
                                                          }}
                                                        />
                                                      </Grid>
                                                      <Grid item xs={12} md={2}>
                                                        <OutlinedInput
                                                          error={!!(errors[index]?.credit)}
                                                          required={!!(errors[index]?.credit)}
                                                          name="credit"
                                                          type="number"
                                                          id="credit"
                                                          endAdornment={
                                                            <InputAdornment position="end"> </InputAdornment>
                                                          }
                                                          style={{ width: "100%" }}
                                                          startAdornment={
                                                            <InputAdornment position="start">$</InputAdornment>
                                                          }
                                                          aria-describedby="outlined-weight-helper-text"
                                                          inputProps={{
                                                            "aria-label": "weight",
                                                            "pattern": "\\d*\\.?\\d+",
                                                            "min": "0"
                                                          }}
                                                          value={form?.credit || ''}
                                                          onChange={(e) => {
                                                            const value = e.target.value;
                                                            if (value === '' || (!isNaN(value) && Number(value) >= 0)) {
                                                              handleFieldChange(index, 'credit', value.toString());
                                                            }
                                                          }}
                                                        />
                                                      </Grid>
                                                      <Grid item xs={12} md={1}>
                                                        <OutlinedInput
                                                          error={!!(errors[index]?.total_credit)}
                                                          required={!!(errors[index]?.total_credit)}
                                                          name="total_credit"
                                                          id="total_credit"
                                                          disabled
                                                          type="number"
                                                          value={((parseFloat(form?.credit) * parseFloat(form?.dealer_cost)) / 100).toFixed(2)}
                                                          endAdornment={
                                                            <InputAdornment position="end"> </InputAdornment>
                                                          }
                                                          style={{ width: "100%", textAlign: "center" }}
                                                          aria-describedby="outlined-weight-helper-text"
                                                          defaultValue="50%"
                                                          align="center"
                                                          inputProps={{
                                                            "aria-label": "weight",
                                                            "pattern": "\\d*\\.?\\d+",
                                                            // "min": "0"
                                                          }}
                                                          onChange={(e) => {
                                                            const value = e.target.value;
                                                            if (value === '' || (!isNaN(value) && Number(value) >= 0)) {
                                                              handleFieldChange(index, 'total_credit', ((parseFloat(form?.credit) * parseFloat(form?.dealer_cost)) / 100).toFixed(2).toString());
                                                            }
                                                          }}
                                                        />
                                                      </Grid>
                                                    </Grid>
                                                    <Grid container spacing={2} justify="space-around" key={form?.id}>
                                                      <Grid item xs={12} md={2}>
                                                        {errors[index]?.buyer_name && (
                                                          <div className="error-message">!required</div>
                                                        )}
                                                      </Grid>

                                                      <Grid item xs={12} md={3}>
                                                        {errors[index]?.item_name && (
                                                          <div className="error-message">!required</div>
                                                        )}
                                                      </Grid>
                                                      <Grid item xs={12} md={1}>
                                                        {errors[index]?.serial_no && (
                                                          <div className="error-message">!required</div>
                                                        )}
                                                      </Grid>
                                                      <Grid item xs={12} md={1}>
                                                        {errors[index]?.dealer_cost && (
                                                          <div className="error-message">!required</div>
                                                        )}
                                                      </Grid>
                                                      <Grid item xs={12} md={2}>
                                                        {errors[index]?.credit && (
                                                          <div className="error-message">!required</div>
                                                        )}
                                                      </Grid>
                                                      <Grid item xs={12} md={1}>
                                                        {errors[index]?.total_credit && (
                                                          <div className="error-message">!required</div>
                                                        )}
                                                      </Grid>
                                                    </Grid>
                                                  </>
                                                )
                                              }
                                              )}
                                              <br></br>
                                              <Grid container justify="flex-end" spacing={4}>
                                                <Grid item xs={12} sm={4}>
                                                  <FormControl
                                                    style={{
                                                      display: "flex",
                                                      flexDirection: "row",
                                                      alignItems: "center",
                                                    }}
                                                    variant="outlined"
                                                  >
                                                    {/* <b variant="h5" align="center" className={classes.subTitle2}  style={{width:"100%",marginBottom:"10px"}}>TOTAL COST OF PROJECT</b> */}
                                                    <FormLabel className={classes.subTitle2}>
                                                      <b>Total Credit $</b>{" "}
                                                    </FormLabel>
                                                    <OutlinedInput
                                                      id="outlined-adornment-weight"
                                                      endAdornment={
                                                        <InputAdornment position="end"> </InputAdornment>
                                                      }
                                                      value={formsData?.reduce((sum, item) => {
                                                        return parseFloat(sum) + parseFloat(item?.total_credit);
                                                      }, 0).toFixed(2) || 0}
                                                      style={{
                                                        width: "80%",
                                                        maxWidth: "calc(100% - 100px)",
                                                        marginLeft: "6px",
                                                      }}
                                                      aria-describedby="outlined-weight-helper-text"
                                                      inputProps={{
                                                        "aria-label": "weight",
                                                      }}
                                                    />
                                                  </FormControl>
                                                </Grid>
                                              </Grid>
                                              {/* cliam form end */}
                                              <br></br>
                                              <br></br>

                                              <Typography
                                                gutterBottom
                                                variant="h6"
                                                align="left"
                                                className={classes.subTitle2}
                                              >
                                                <b>REQUIREMENTS</b>
                                              </Typography>
                                              <br></br>
                                              <br></br>

                                              <Grid container spacing={4} alignItems="center">
                                                <Grid item xs={12} sm={6} md={7}>
                                                  <FormControl
                                                    sx={{ m: 1, width: "25ch" }}
                                                    variant="outlined"
                                                  >
                                                    <FormLabel
                                                      variant="h5"
                                                      align="left"
                                                      className={classes.subTitle2}
                                                      style={{ width: "100%" }}
                                                    >
                                                      1. Copy of Be Of Sale dated and submitted within 20 days of sale
                                                    </FormLabel>
                                                  </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={5}>
                                                  <input
                                                    accept="image/*,application/pdf"
                                                    className={classes.input}
                                                    id="contained-button-file1"
                                                    type="file"
                                                    style={{ display: "none" }}
                                                    onChange={(e) =>
                                                      handleFileChange(e, "file1")
                                                    }
                                                  />
                                                  <FormLabel htmlFor="contained-button-file1">
                                                    <Button
                                                      variant="contained"
                                                      color="primary"
                                                      component="span"
                                                    >
                                                      CHOOSE FILE
                                                    </Button>
                                                  </FormLabel>
                                                  <FormLabel
                                                    htmlFor="contained-button-file1"
                                                    className={classes.body2}
                                                    style={{ marginLeft: "8px", cursor: "pointer" }}
                                                  >
                                                    {files?.file1?.name ? files?.file1?.name : files?.file1 || "NO FILES UPLOADED"}
                                                  </FormLabel>
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={7}>
                                                  <FormControl
                                                    sx={{ m: 1, width: "25ch" }}
                                                    variant="outlined"
                                                  >
                                                    <FormLabel
                                                      variant="h5"
                                                      align="left"
                                                      className={classes.subTitle2}
                                                      style={{ width: "100%" }}
                                                    >
                                                      2. If a Kit Boat Sale, Est all fiberglass accessories
                                                    </FormLabel>
                                                  </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={5}>
                                                  <input
                                                    accept="image/*,application/pdf"
                                                    className={classes.input}
                                                    id="contained-button-file2"
                                                    type="file"
                                                    style={{ display: "none" }}
                                                    onChange={(e) =>
                                                      handleFileChange(e, "file2")
                                                    }
                                                  />
                                                  <FormLabel htmlFor="contained-button-file2">
                                                    <Button
                                                      variant="contained"
                                                      color="primary"
                                                      component="span"
                                                    >
                                                      CHOOSE FILE
                                                    </Button>
                                                  </FormLabel>
                                                  <FormLabel
                                                    htmlFor="contained-button-file2"
                                                    className={classes.body2}
                                                    style={{ marginLeft: "8px", cursor: "pointer" }}
                                                  >
                                                    {files?.file2?.name ? files?.file2?.name : files?.file2 || "NO FILES UPLOADED"}
                                                  </FormLabel>
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={7}>
                                                  <FormControl
                                                    sx={{ m: 1, width: "25ch" }}
                                                    variant="outlined"
                                                  >
                                                    <FormLabel
                                                      variant="h5"
                                                      align="left"
                                                      className={classes.subTitle2}
                                                      style={{ width: "100%" }}
                                                    >
                                                      3. Warranty Card completed and submitted with this form and all other paperwork
                                                    </FormLabel>
                                                  </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={5}>
                                                  <input
                                                    accept="image/*,application/pdf"
                                                    className={classes.input}
                                                    id="contained-button-file3"
                                                    type="file"
                                                    style={{ display: "none" }}
                                                    onChange={(e) =>
                                                      handleFileChange(e, "file3")
                                                    }
                                                  />
                                                  <FormLabel htmlFor="contained-button-file3">
                                                    <Button
                                                      variant="contained"
                                                      color="primary"
                                                      component="span"
                                                    >
                                                      CHOOSE FILE
                                                    </Button>
                                                  </FormLabel>
                                                  <FormLabel
                                                    htmlFor="contained-button-file3"
                                                    className={classes.body2}
                                                    style={{ marginLeft: "8px", cursor: "pointer" }}
                                                  >
                                                    {files?.file3?.name ? files?.file3?.name : files?.file3 || "NO FILES UPLOADED"}
                                                  </FormLabel>
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={7}>
                                                  <FormControl
                                                    sx={{ m: 1, width: "25ch" }}
                                                    variant="outlined"
                                                  >
                                                    <FormLabel
                                                      variant="h5"
                                                      align="left"
                                                      className={classes.subTitle2}
                                                      style={{ width: "100%" }}
                                                    >
                                                      4. Copy of Business Purchase Order and or Business License and or Captain License
                                                    </FormLabel>
                                                  </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={5}>
                                                  <input
                                                    accept="image/*,application/pdf"
                                                    className={classes.input}
                                                    id="contained-button-file4"
                                                    type="file"
                                                    style={{ display: "none" }}
                                                    onChange={(e) =>
                                                      handleFileChange(e, "file4")
                                                    }
                                                  />
                                                  <FormLabel htmlFor="contained-button-file4">
                                                    <Button
                                                      variant="contained"
                                                      color="primary"
                                                      component="span"
                                                    >
                                                      CHOOSE FILE
                                                    </Button>
                                                  </FormLabel>
                                                  <FormLabel
                                                    htmlFor="contained-button-file4"
                                                    className={classes.body2}
                                                    style={{ marginLeft: "8px", cursor: "pointer" }}
                                                  >
                                                    {files?.file4?.name ? files?.file4?.name : files?.file4 || "NO FILES UPLOADED"}
                                                  </FormLabel>
                                                </Grid>
                                              </Grid>
                                            </div>
                                            <br></br>

                                            <div>
                                              <Grid container>
                                                <Grid item xs={12} sm={12}>
                                                  <TextField
                                                    name="additional_info"
                                                    label="Additional information. (OPTIONAL)"
                                                    multiline
                                                    rows="4"
                                                    variant="outlined"
                                                    className={classes.textField}
                                                    onChange={handleChange}
                                                    margin="normal"
                                                    value={values?.additional_info || ""}
                                                    style={{ width: "100%" }}
                                                  />
                                                </Grid>
                                              </Grid>
                                            </div>

                                            <Grid item xs={12} align="center">
                                              <Button
                                                color="primary"
                                                variant="contained"
                                                className={classes.button}
                                                onClick={() => {
                                                  resetState();
                                                  setFiles({})
                                                  setFormsData(initialFormsData);
                                                  history.push("/commerical-sales-credit-claim")
                                                }}
                                                style={{ width: "15em", margin: "2em 1em" }}
                                              >
                                                CANCEL
                                              </Button>

                                              <Button
                                                color="primary"
                                                variant="contained"
                                                className={classes.button}
                                                type="submit"
                                                style={{ width: "15em", margin: "2em 1em" }}
                                                disabled={isSubmit}
                                              >
                                                SUBMIT
                                              </Button>
                                            </Grid>
                                          </Paper>
                                        </div>
                                      </form>
                                    );
                                  }}
                                </Mutation>
                              );
                            }}
                          </Mutation>
                        );
                      }}
                    </Mutation>
                  );
                }}
              </Mutation>
            </DashboardLayout>
          );
        }
      }}
    </Query>
  );
}

export default compose(withRouter, withStyles(styles))(CommericalSalesCreditClaimEdit);