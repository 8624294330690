import React, { Component } from "react";
import { Mutation } from "react-apollo";
import { gql } from "apollo-boost";
import { withStyles } from "@material-ui/core/styles";
import auth from "../../Auth";
import { Query } from "react-apollo";
import { GET_USER_INFO, GET_BOAT_MODELS } from "queries";
import { toast } from "react-toastify";
import { Button, Grid, Paper, Typography, TextField } from "@material-ui/core";

// Shared layouts
import { Dashboard as DashboardLayout } from "layouts";
// Component styles
import styles from "theme/skin";

const ADD_WARRANTY = gql`
  mutation insert_warranty(
    $profile_id: String
    $admin_name: String
    $admin_email: String
    $hull_identification_number: String
    $purchase_date: String
    $brand: String
    $model: String
    $first_name: String
    $last_name: String
    $phone: String
    $email: String
    $address: String
    $address2: String
    $city: String
    $region: String
    $country: String
    $zipcode: String
    $motor_details: String
    $accessories: String
    $primary_use: String
    $model_id: uuid!
    $is_deleted: Int
  ) {
    insert_warranty_registration(
      objects: [
        {
          profile_id: $profile_id
          admin_name: $admin_name
          admin_email: $admin_email
          hull_identification_number: $hull_identification_number
          purchase_date: $purchase_date
          brand: $brand
          model: $model
          first_name: $first_name
          last_name: $last_name
          phone: $phone
          email: $email
          address: $address
          address2: $address2
          city: $city
          region: $region
          country: $country
          zipcode: $zipcode
          motor_details: $motor_details
          accessories: $accessories
          primary_use: $primary_use
          model_id: $model_id
        }
      ]
    ) {
      affected_rows
    }
  }
`;

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value
});
class Register extends Component {
  notify = () => toast("Warranty Registration Successful");
  constructor() {
    super();
    this.state = {
      hull_identification_number: "",
      hull_identification_number2: "",
      hull_identification_match: false,
      purchase_date: "",
      brand: "",
      model: "",
      first_name: "",
      last_name: "",
      phone: "",
      admin_email: auth.getEmail(),
      admin_name: "",
      email: "",
      address: "",
      address2: "",
      city: "",
      region: "",
      country: "",
      zipcode: "",
      motor_details: "",
      accessories: "",
      primary_use: ""
    };
    this.handleChange = this.handleChange.bind(this);
  }

  inputHullIdentification = event => {
    this.setState({ hull_identification_number: event.target.value });
  };
  confirmHullIdentification = event => {
    this.setState({ hull_identification_number2: event.target.value });
  };
  handleChange = evt => {
    this.setState({ [evt.target.name]: evt.target.value });
  };
  completedAdd = payload => {
    this.notify();
    this.clearForm();
  };
  clearForm = () => {
    this.setState({
      hull_identification_number: "",
      hull_identification_number2: "",
      hull_identification_match: false,
      purchase_date: "",
      brand: "",
      model: "",
      first_name: "",
      last_name: "",
      phone: "",
      admin_email: auth.getEmail(),
      admin_name: "",
      email: "",
      address: "",
      address2: "",
      city: "",
      region: "",
      country: "",
      zipcode: "",
      motor_details: "",
      accessories: "",
      primary_use: ""
    });
  };
  render() {
    const { classes } = this.props;
    const {
      hull_identification_number,
      hull_identification_number2
    } = this.state;
    return (
      <DashboardLayout title="Warranty Registration">
        <Mutation mutation={ADD_WARRANTY} onCompleted={this.completedAdd}>
          {(registerWarranty, { data, loading, error }) => (
            <form
              onSubmit={e => {
                e.preventDefault();
                if (
                  hull_identification_number === hull_identification_number2
                ) {
                  this.setState({
                    helperText: "",
                    hull_identification_match: false
                  });
                } else {
                  this.setState({
                    helperText: "Hull Identification Numbers Do Not Match",
                    hull_identification_match: true
                  });
                  return;
                }
                if (this.state.purchase_date.length === 0) {
                  toast.error("Please Select a Purchase Date", {
                    position: toast.POSITION.TOP_LEFT
                  });
                  return;
                }
                registerWarranty({
                  variables: {
                    profile_id: auth.getSub(),
                    admin_name: this.state.admin_name,
                    admin_email: this.state.admin_email,
                    hull_identification_number: this.state.hull_identification_number,
                    purchase_date: this.state.purchase_date,
                    brand: this.state.brand,
                    model: this.state.model,
                    first_name: this.state.first_name,
                    last_name: this.state.last_name,
                    phone: this.state.phone,
                    email: this.state.email,
                    address: this.state.address,
                    address2: this.state.address2,
                    city: this.state.city,
                    region: this.state.region,
                    country: this.state.country,
                    zipcode: this.state.zipcode,
                    motor_details: this.state.motor_details,
                    accessories: this.state.accessories,
                    primary_use: this.state.primary_use,
                    model_id: this.state.model_id
                  }
                });
              }}
            >
              <div className={classes.root + " sectionContainer"}>
                <Typography gutterBottom variant="h4" className="title">
                  <span>warranty registration</span>
                </Typography>

                <Paper className={classes.paper} style={{ padding: "2em" }}>
                  <Typography
                    gutterBottom
                    variant="h6"
                    className={classes.subTitle}
                  >
                    The Carolina Skiff/Sea Chaser Warranty will not take effect
                    until the warranty registration is completed. This must be
                    done within 30 days of the date of purchase.
                  </Typography>

                  <div>
                    <Typography
                      gutterBottom
                      variant="h6"
                      align="center"
                      className={classes.title}
                    >
                      Admin Information
                    </Typography>
                    <Grid container justify="space-between">
                      <Query query={GET_USER_INFO} variables={{ id: auth.getSub() }}>
                        {({ data, loading, error }) => {
                          if (loading) return <p>Loading...</p>;
                          if (error) return <p>Error</p>;

                          if (this.state.admin_name === "") {
                            this.setState({ admin_name: data.profiles_by_pk.company });
                          }
                          return (
                            <TextField
                              required
                              name="admin_name"
                              label=""
                              className={classes.textField}
                              onChange={this.handleChange}
                              margin="normal"
                              value={this.state.admin_name}
                              variant="outlined"
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          );
                        }}
                      </Query>
                      <TextField
                        required
                        name="admin_email"
                        label=""
                        className={classes.textField}
                        margin="normal"
                        value={auth.getEmail()}
                        variant="outlined"
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                  </div>

                  <div>
                    <Typography
                      gutterBottom
                      variant="h6"
                      align="center"
                      className={classes.title}
                    >
                      Product Information
                    </Typography>

                    <Grid container justify="space-between">
                      <Grid item xs={12} sm={3}>
                        <TextField
                          required
                          style={{ width: "100%" }}
                          name="hull_identification_number"
                          label="Hull Identification Number"
                          onChange={event =>
                            this.setState(
                              byPropKey(
                                "hull_identification_number",
                                event.target.value
                              )
                            )
                          }
                          className={
                            classes.textField + " " + classes.prefixDropdown
                          }
                          value={this.state.hull_identification_number}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <TextField
                          required
                          style={{ width: "100%" }}
                          name="hull_identification_number2"
                          label="Retype Hull Identification Number"
                          onChange={event =>
                            this.setState(
                              byPropKey(
                                "hull_identification_number2",
                                event.target.value
                              )
                            )
                          }
                          variant="outlined"
                          className={
                            classes.textField + " " + classes.prefixDropdown
                          }
                          helperText={this.state.helperText}
                          error={this.state.hull_identification_match}
                          value={this.state.hull_identification_number2}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <TextField
                          required
                          style={{ width: "100%" }}
                          name="purchase_date"
                          label="Purchase Date"
                          type="date"
                          onChange={this.handleChange}
                          className={
                            classes.textField + " " + classes.prefixDropdown
                          }
                          InputLabelProps={{
                            shrink: true
                          }}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Grid container justify="space-between">

                      <Grid item xs={12} sm={4}>
                        <TextField
                          name="brand"
                          select
                          label="Select Brand"
                          style={{ width: "350px" }}
                          className={
                            classes.textField + " " + classes.prefixDropdown
                          }
                          onChange={this.handleChange}
                          value={this.state.brand}
                          SelectProps={{
                            native: true,
                            MenuProps: {
                              className: classes.menu
                            }
                          }}
                          variant="outlined"
                          margin="normal"
                        >
                          <option value=""></option>
                          <option key="Carolina Skiff" value="Carolina Skiff">Carolina Skiff</option>
                          <option key="Sea Chaser" value="Sea Chaser">Sea Chaser</option>
                          <option key="Fun Chaser" value="Fun Chaser">Fun Chaser</option>
                        </TextField>
                      </Grid>
                      <Query query={GET_BOAT_MODELS}>
                        {({ data, loading, error }) => {
                          if (loading) return <p>Loading...</p>;
                          if (error) return <p>Error {error.message}</p>;
                          return (
                            <Grid item xs={12} sm={4}>
                              <TextField
                                name="model"
                                select
                                label="Model"
                                className={
                                  classes.textField + " " + classes.prefixDropdown
                                }
                                onChange={(e) => {
                                  this.handleChange(e);
                                  this.setState({ model_id: e.target.options[e.target.selectedIndex].dataset.modelId || null });
                                }}
                                value={this.state.model}
                                SelectProps={{
                                  native: true,
                                  MenuProps: {
                                    className: classes.menu
                                  }
                                }}
                                style={{ width: "350px" }}
                                variant="outlined"
                                margin="normal"
                              >
                                <option value=""></option>
                                {
                                  data && data.boat_models &&
                                  data.boat_models.map((boat) => {
                                    return <option key={`${boat.brand} - ${boat.model}`} value={boat.model} data-model-id={boat.id}>{boat.model} {boat.older_model ? ' - Older Model' : ''}</option>
                                  })
                                }
                              </TextField>
                            </Grid>
                          );
                        }}
                      </Query>
                    </Grid>
                  </div>

                  <div>
                    <Typography
                      gutterBottom
                      variant="h6"
                      align="center"
                      className={classes.title}
                    >
                      Customer Information
                    </Typography>

                    <Grid container>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          required
                          name="first_name"
                          label="First Name"
                          className={classes.textField}
                          style={{ width: "90%" }}
                          onChange={this.handleChange}
                          margin="normal"
                          value={this.state.first_name}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          required
                          name="last_name"
                          label="Last Name"
                          className={classes.textField}
                          style={{ width: "90%" }}
                          onChange={this.handleChange}
                          margin="normal"
                          value={this.state.last_name}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          required
                          name="phone"
                          label="Phone"
                          className={classes.textField}
                          style={{ width: "90%" }}
                          onChange={(e) => this.handleChange(e, 'phone')}
                          margin="normal"
                          value={this.state.phone}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>

                    <Grid container>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          required
                          name="email"
                          label="Email"
                          className={classes.textField}
                          style={{ width: "90%" }}
                          onChange={this.handleChange}
                          margin="normal"
                          value={this.state.email}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          required
                          name="address"
                          label="Address"
                          className={classes.textField}
                          style={{ width: "90%" }}
                          onChange={this.handleChange}
                          margin="normal"
                          value={this.state.address}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          name="address2"
                          label="Address line 2 (Suites, Apt. # etc)"
                          className={classes.textField}
                          onChange={this.handleChange}
                          margin="normal"
                          variant="outlined"
                          value={this.state.address2}
                          style={{ width: "90%" }}
                        />
                      </Grid>
                    </Grid>

                    <Grid container>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          required
                          name="city"
                          label="City"
                          className={classes.textField}
                          onChange={this.handleChange}
                          margin="normal"
                          variant="outlined"
                          value={this.state.city}
                          style={{ width: "90%" }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          name="country"
                          label="Country"
                          select
                          className={classes.textField}
                          onChange={this.handleChange}
                          value={this.state.country}
                          style={{ width: "90%" }}
                          variant="outlined"
                          SelectProps={{
                            native: true,
                            MenuProps: {
                              className: classes.menu
                            }
                          }}
                          margin="normal"
                        >
                          <option />
                          <option key="US" value="US">US</option>
                        </TextField>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          name="region"
                          label="State"
                          variant="outlined"
                          select
                          style={{ width: "90%" }}
                          className={classes.textField}
                          value={this.state.region}
                          onChange={this.handleChange}
                          SelectProps={{
                            native: true,
                            MenuProps: {
                              className: classes.menu
                            }
                          }}
                          margin="normal"
                        >
                          <option />
                          <option key="AL" value="AL">Alabama</option>
                          <option key="AK" value="AK">Alaska</option>
                          <option key="AZ" value="AZ">Arizona</option>
                          <option key="AR" value="AR">Arkansas</option>
                          <option key="CA" value="CA">California</option>
                          <option key="CO" value="CO">Colorado</option>
                          <option key="CT" value="CT">Connecticut</option>
                          <option key="DE" value="DE">Delaware</option>
                          <option key="FL" value="FL">Florida</option>
                          <option key="GA" value="GA">Georgia</option>
                          <option key="HI" value="HI">Hawaii</option>
                          <option key="ID" value="ID">Idaho</option>
                          <option key="IL" value="IL">Illinois</option>
                          <option key="IN" value="IN">Indiana</option>
                          <option key="IA" value="IA">Iowa</option>
                          <option key="KS" value="KS">Kansas</option>
                          <option key="KY" value="KY">Kentucky</option>
                          <option key="LA" value="LA">Louisiana</option>
                          <option key="ME" value="ME">Maine</option>
                          <option key="MD" value="MD">Maryland</option>
                          <option key="MA" value="MA">Massachusetts</option>
                          <option key="MI" value="MI">Michigan</option>
                          <option key="MN" value="MN">Minnesota</option>
                          <option key="MS" value="MS">Mississippi</option>
                          <option key="MO" value="MO">Missouri</option>
                          <option key="MT" value="MT">Montana</option>
                          <option key="NE" value="NE">Nebraska</option>
                          <option key="NV" value="NV">Nevada</option>
                          <option key="NH" value="NH">New Hampshire</option>
                          <option key="NJ" value="NJ">New Jersey</option>
                          <option key="NM" value="NM">New Mexico</option>
                          <option key="NY" value="NY">New York</option>
                          <option key="NC" value="NC">North Carolina</option>
                          <option key="ND" value="ND">North Dakota</option>
                          <option key="OH" value="OH">Ohio</option>
                          <option key="OK" value="OK">Oklahoma</option>
                          <option key="OR" value="OR">Oregon</option>
                          <option key="PA" value="PA">Pennsylvania</option>
                          <option key="RI" value="RI">Rhode Island</option>
                          <option key="SC" value="SC">South Carolina</option>
                          <option key="SD" value="SD">South Dakota</option>
                          <option key="TN" value="TN">Tennessee</option>
                          <option key="TX" value="TX">Texas</option>
                          <option key="UT" value="UT">Utah</option>
                          <option key="VT" value="VT">Vermont</option>
                          <option key="VA" value="VA">Virginia</option>
                          <option key="WA" value="WA">Washington</option>
                          <option key="WV" value="WV">West Virginia</option>
                          <option key="WI" value="WI">Wisconsin</option>
                          <option key="WY" value="WY">Wyoming</option>
                        </TextField>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          name="zipcode"
                          label="Zip / Postal Code"
                          className={classes.textField}
                          onChange={this.handleChange}
                          margin="normal"
                          variant="outlined"
                          style={{ width: "90%" }}
                          value={this.state.zipcode}
                        />
                      </Grid>
                    </Grid>
                  </div>

                  <div>
                    <Typography
                      gutterBottom
                      variant="h6"
                      align="center"
                      className={classes.title}
                    >
                      Additional Information
                    </Typography>
                    <Grid container justify="space-around">
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="motor_details"
                          label="Motor Detail"
                          multiline
                          rows="4"
                          variant="outlined"
                          className={classes.textField}
                          onChange={this.handleChange}
                          margin="normal"
                          value={this.state.motor_details}
                          style={{ width: "90%" }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="accessories"
                          label="Accessories"
                          multiline
                          rows="4"
                          variant="outlined"
                          className={classes.textField}
                          onChange={this.handleChange}
                          margin="normal"
                          value={this.state.accessories}
                          style={{ width: "90%" }}
                        />
                      </Grid>
                    </Grid>
                  </div>

                  <div>
                    <Grid container>
                      <Grid item xs={12} sm={5}>
                        <TextField
                          name="primary_use"
                          select
                          label="Primary Use"
                          className={classes.textField}
                          onChange={this.handleChange}
                          value={this.state.primary_use}
                          SelectProps={{
                            native: true,
                            MenuProps: {
                              className: classes.menu
                            }
                          }}
                          margin="normal"
                          variant="outlined"
                        >
                          <option />
                          <option key="Pleasure" value="Pleasure">
                            Pleasure
                          </option>
                          <option key="Business" value="Business">
                            Business
                          </option>
                          <option key="Fishing Charter" value="Fishing Charter">
                            Fishing Charter
                          </option>
                        </TextField>
                      </Grid>
                    </Grid>
                  </div>

                  <Grid item xs={12} align="center">
                    {loading && <p>Loading...</p>}
                    {error && (
                      <p>
                        Warranty Registration Could Not be Submitted. Please Try
                        again.
                      </p>
                    )}
                    <Button
                      color="primary"
                      variant="contained"
                      className={classes.button}
                      type="submit"
                      style={{ width: "15em", margin: "2em 1em" }}
                    >
                      SUBMIT
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      className={classes.button}
                      onClick={this.clearForm}
                      style={{ width: "15em", margin: "2em 1em" }}
                    >
                      CANCEL
                    </Button>
                  </Grid>
                </Paper>
              </div>
            </form>
          )}
        </Mutation>
      </DashboardLayout>
    );
  }
}

export default withStyles(styles)(Register);
