import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  Paper,
  List,
  ListItem,
  Typography
} from "@material-ui/core";
import { withRouter } from "react-router";
import compose from "recompose/compose";
// Shared layouts
import { Dashboard as DashboardLayout } from "layouts";

// Component styles
import styles from "theme/skin";
import { Box } from "rbx";
import './CoopPreapprovalClaimForm.scss';

function CoopPreapprovalClaim(props) {
  const { classes, history } = props;
  return (
    <DashboardLayout title="Co-OP Pre Approval Claim Forms">
      <div className={classes.root + " sectionContainer claimFormPg"}>
        <Typography gutterBottom variant="h4" className="title pg-title" >
          <span>Co op pre-approval claim forms</span>
        </Typography>

        <Box sx={{ flexGrow: 1 }} style={{ width: "88%", margin: "0px auto" }}>
          <Grid container spacing={4} >
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => { history.push("/media-pre-approval-form") }}
                size="large"
                align="center"
                style={{ width: "100%", fontSize: "0.8rem" }}
              >
                Media Pre Approval Forms
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <Button
                color="primary"
                variant="contained"
                href="#contained-buttons"
                onClick={() => { history.push("/advertising-claim-form") }}
                size="large"
                align="center"
                className={classes.subTitle2}
                style={{ width: "100%", fontSize: "0.8rem" }}
              >
                CO-OP Advertising Claim Form
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <Button
                color="primary"
                variant="contained"
                href="#contained-buttons"
                onClick={() => { history.push("/special-project-forms") }}
                size="large"
                align="center"
                style={{ width: "100%", fontSize: "0.8rem" }}
              >
                Special Project Request Form
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <Button
                color="primary"
                variant="contained"
                href="#contained-buttons"
                onClick={() => { history.push("/commerical-sales-credit-claim") }}
                size="large"
                align="center"
                style={{ width: "100%", fontSize: "0.8rem" }}
              >
                Government/Commercials Sales Credit Claim
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => { history.push("/boat-show-credit") }}
                size="large"
                align="center"
                style={{ width: "100%", fontSize: "0.8rem" }}
              >
                Boat Show Credit Form
              </Button>
            </Grid>
          </Grid>
        </Box>
      </div>
    </DashboardLayout>
  );
}

CoopPreapprovalClaim.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(withRouter, withStyles(styles))(CoopPreapprovalClaim);
