import React from "react";
import { Mutation, Query, compose } from "react-apollo";
import { withStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import useForm from "components/useForm";
import {
  Paper,
  Typography,
  TextField,
  Grid,
  FormControl,
  OutlinedInput,
  InputAdornment,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { Dashboard as DashboardLayout } from "layouts";
import styles from "theme/skin";
import {
  GET_MEDIA_PRE_APPROVAL_FORMS_INFO,
  UPDATE_MEDIA_PRE_APPROVAL_FORM,
} from "./queries";
import { withRouter } from "react-router-dom";

function MediaPreApprovalFormEdit(props) {
  const { classes, history } = props;
  const {
    match: { params },
  } = props;
  const { values, handleChange, resetState, handleChangeCheckbox, setValues } =
    useForm(submitForm);

  function submitForm() { }

  const completedAdd = (payload) => {
    toast("Media pre approval form was been succesfully updated");
    history.push("/media-pre-approval-form")
  };

  function updateForm(data, callBackMutation) {
    delete data.status;
    delete data.date_submitted;
    delete data.__typename;

    callBackMutation({
      variables: data,
    });
    return true;
  }
  return (
    <Query
      query={GET_MEDIA_PRE_APPROVAL_FORMS_INFO}
      variables={{ id: params.id }}
    >
      {({ loading, error, data }) => {
        if (loading) return null;
        if (error) return `Error! ${error}`;
        if (values.dealer_name === undefined) {
          setValues(data.media_pre_approval_forms_by_pk);
        }
        if (data.media_pre_approval_forms_by_pk !== undefined) {
          return (
            <DashboardLayout>
              <Mutation
                mutation={UPDATE_MEDIA_PRE_APPROVAL_FORM}
                onCompleted={completedAdd}
              >
                {(
                  updateMediaPreApprovalForm,
                  { data, loading, called, error }
                ) => {
                  return (
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        updateForm(values, updateMediaPreApprovalForm);
                        return false;
                      }}
                      autoComplete="off"
                    >
                      <div className={classes.root + " sectionContainer"}>
                        <Typography gutterBottom variant="h4" className="title">
                          <span>EDIT MEDIA PRE APPROVAL FORMS</span>
                        </Typography>
                        <Typography
                          gutterBottom
                          variant="h5"
                          className={classes.subtitle3}
                          style={{
                            backgroundColor: "#BED4E5",
                            textAlign: "left",
                            padding: "1em",
                            color: "#23333F",
                            margin: "0px",
                          }}
                        >
                          EDIT MEDIA PRE-APPROVAL FORM
                        </Typography>
                        <br></br>
                        <br></br>
                        <Paper
                          className={classes.paper}
                          style={{
                            padding: "2em",
                            backgroundColor: "#F9FCFF",
                            boxShadow: "unset",
                          }}
                        >
                          <Typography
                            gutterBottom
                            variant="h5"
                            align="center"
                            className={classes.subTitle2}
                            style={{ margin: "0px auto 15px", maxWidth: "80%" }}
                          >
                            <strong>CAROLINA SKIFF:</strong> 3231 Fulford Road
                            Waycross Georgia 31503 <strong>PHONE:</strong>{" "}
                            912-287-0547 <strong>FAX:</strong> 912-287-0533
                          </Typography>
                          <Typography
                            gutterBottom
                            variant="h6"
                            align="center"
                            style={{ margin: "0px auto", maxWidth: "80%" }}
                            className={classes.subTitle2}
                          >
                            Submit all paper work to Carolina Skiff Advertising
                            Department. All required paper work must be
                            submitted with this form. <br></br>For
                            qualifications and requirements of the Co-op
                            advertising program, please check your Dealer Manual
                            under the Marketing Guide section.
                          </Typography>
                          <br></br>
                          <br></br>
                          <div>
                            <Grid container spacing={4}>
                              <Grid item xs={12} sm={4}>
                                <TextField
                                  id="dealer_name"
                                  name="dealer_name"
                                  label="Dealer Name"
                                  className={classes.textField}
                                  style={{ width: "100%" }}
                                  onChange={handleChange}
                                  margin="normal"
                                  variant="outlined"
                                  value={values?.dealer_name || ""}
                                  required
                                />
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <TextField
                                  id="contact_name"
                                  name="contact_name"
                                  label="Contact Name"
                                  className={classes.textField}
                                  style={{ width: "100%" }}
                                  onChange={handleChange}
                                  margin="normal"
                                  variant="outlined"
                                  value={values?.contact_name || ""}
                                  required
                                />
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <TextField
                                  id="phone"
                                  name="phone"
                                  label="Phone"
                                  className={classes.textField}
                                  style={{ width: "100%" }}
                                  onChange={handleChange}
                                  margin="normal"
                                  value={values?.phone || ""}
                                  variant="outlined"
                                  required
                                />
                              </Grid>
                            </Grid>

                            <Grid container spacing={4}>
                              <Grid item xs={12} sm={4}>
                                <TextField
                                  id="fax"
                                  name="fax"
                                  label="Fax"
                                  className={classes.textField}
                                  style={{ width: "100%" }}
                                  onChange={handleChange}
                                  margin="normal"
                                  value={values?.fax || ""}
                                  variant="outlined"
                                  required
                                />
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <TextField
                                  id="address"
                                  name="address"
                                  label="Address"
                                  className={classes.textField}
                                  style={{ width: "100%" }}
                                  onChange={handleChange}
                                  margin="normal"
                                  value={values?.address || ""}
                                  variant="outlined"
                                  required
                                />
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <TextField
                                  id="address2"
                                  name="address2"
                                  label="Address line 2 (Suites, Apt. # etc)"
                                  className={classes.textField}
                                  onChange={handleChange}
                                  margin="normal"
                                  variant="outlined"
                                  value={values?.address2 || ""}
                                  style={{ width: "100%" }}
                                />
                              </Grid>
                            </Grid>

                            <Grid container spacing={4}>
                              <Grid item xs={12} sm={4}>
                                <TextField
                                  id="city"
                                  name="city"
                                  label="City"
                                  className={classes.textField}
                                  onChange={handleChange}
                                  margin="normal"
                                  variant="outlined"
                                  value={values?.city || ""}
                                  style={{ width: "100%" }}
                                  required
                                />
                              </Grid>
                              {console.log(values?.country, "ca", values)}
                              <Grid item xs={12} sm={4}>
                                <TextField
                                  id="country"
                                  name="country"
                                  label="Country"
                                  select
                                  className={classes.textField}
                                  onChange={handleChange}
                                  value={values?.country || ""}
                                  style={{ width: "100%" }}
                                  variant="outlined"
                                  SelectProps={{
                                    native: true,
                                    MenuProps: {
                                      className: classes.menu,
                                    },
                                  }}
                                  margin="normal"
                                >
                                  <option key="" value="" />
                                  <option key="United States" value="United States">United States</option>
                                </TextField>
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <TextField
                                  name="state"
                                  label="State"
                                  variant="outlined"
                                  select
                                  style={{ width: "100%" }}
                                  className={classes.textField}
                                  value={values?.state || ""}
                                  onChange={handleChange}
                                  margin="normal"
                                  SelectProps={{
                                    native: true,
                                    MenuProps: {
                                      className: classes.menu,
                                    },
                                  }}
                                >
                                  <option key="" value="" />
                                  <option key="Alabama" value="Alabama">
                                    Alabama
                                  </option>
                                  <option key="Alaska" value="Alaska">
                                    Alaska
                                  </option>
                                  <option key="Arizona" value="Arizona">
                                    Arizona
                                  </option>
                                  <option key="Arkansas" value="Arkansas">
                                    Arkansas
                                  </option>
                                  <option key="California" value="California">
                                    California
                                  </option>
                                  <option key="Colorado" value="Colorado">
                                    Colorado
                                  </option>
                                  <option key="Connecticut" value="Connecticut">
                                    Connecticut
                                  </option>
                                  <option key="Delaware" value="Delaware">
                                    Delaware
                                  </option>
                                  <option key="Florida" value="Florida">
                                    Florida
                                  </option>
                                  <option key="Georgia" value="Georgia">
                                    Georgia
                                  </option>
                                  <option key="Hawaii" value="Hawaii">
                                    Hawaii
                                  </option>
                                  <option key="Idaho" value="Idaho">
                                    Idaho
                                  </option>
                                  <option key="Illinois" value="Illinois">
                                    Illinois
                                  </option>
                                  <option key="Indiana" value="Indiana">
                                    Indiana
                                  </option>
                                  <option key="Iowa" value="Iowa">
                                    Iowa
                                  </option>
                                  <option key="Kansas" value="Kansas">
                                    Kansas
                                  </option>
                                  <option key="Kentucky" value="Kentucky">
                                    Kentucky
                                  </option>
                                  <option key="Louisiana" value="Louisiana">
                                    Louisiana
                                  </option>
                                  <option key="Maine" value="Maine">
                                    Maine
                                  </option>
                                  <option key="Maryland" value="Maryland">
                                    Maryland
                                  </option>
                                  <option
                                    key="Massachusetts"
                                    value="Massachusetts"
                                  >
                                    Massachusetts
                                  </option>
                                  <option key="Michigan" value="Michigan">
                                    Michigan
                                  </option>
                                  <option key="Minnesota" value="Minnesota">
                                    Minnesota
                                  </option>
                                  <option key="Mississippi" value="Mississippi">
                                    Mississippi
                                  </option>
                                  <option key="Missouri" value="Missouri">
                                    Missouri
                                  </option>
                                  <option key="Montana" value="Montana">
                                    Montana
                                  </option>
                                  <option key="Nebraska" value="Nebraska">
                                    Nebraska
                                  </option>
                                  <option key="Nevada" value="Nevada">
                                    Nevada
                                  </option>
                                  <option
                                    key="New Hampshire"
                                    value="New Hampshire"
                                  >
                                    New Hampshire
                                  </option>
                                  <option key="New Jersey" value="New Jersey">
                                    New Jersey
                                  </option>
                                  <option key="New Mexico" value="New Mexico">
                                    New Mexico
                                  </option>
                                  <option key="New York" value="New York">
                                    New York
                                  </option>
                                  <option
                                    key="North Carolina"
                                    value="North Carolina"
                                  >
                                    North Carolina
                                  </option>
                                  <option
                                    key="North Dakota"
                                    value="North Dakota"
                                  >
                                    North Dakota
                                  </option>
                                  <option key="Ohio" value="Ohio">
                                    Ohio
                                  </option>
                                  <option key="Oklahoma" value="Oklahoma">
                                    Oklahoma
                                  </option>
                                  <option key="Oregon" value="Oregon">
                                    Oregon
                                  </option>
                                  <option
                                    key="Pennsylvania"
                                    value="Pennsylvania"
                                  >
                                    Pennsylvania
                                  </option>
                                  <option
                                    key="Rhode Island"
                                    value="Rhode Island"
                                  >
                                    Rhode Island
                                  </option>
                                  <option
                                    key="South Carolina"
                                    value="South Carolina"
                                  >
                                    South Carolina
                                  </option>
                                  <option
                                    key="South Dakota"
                                    value="South Dakota"
                                  >
                                    South Dakota
                                  </option>
                                  <option key="Tennessee" value="Tennessee">
                                    Tennessee
                                  </option>
                                  <option key="Texas" value="Texas">
                                    Texas
                                  </option>
                                  <option key="Utah" value="Utah">
                                    Utah
                                  </option>
                                  <option key="Vermont" value="Vermont">
                                    Vermont
                                  </option>
                                  <option key="Virginia" value="Virginia">
                                    Virginia
                                  </option>
                                  <option key="Washington" value="Washington">
                                    Washington
                                  </option>
                                  <option
                                    key="West Virginia"
                                    value="West Virginia"
                                  >
                                    West Virginia
                                  </option>
                                  <option key="Wisconsin" value="Wisconsin">
                                    Wisconsin
                                  </option>
                                  <option key="Wyoming" value="Wyoming">
                                    Wyoming
                                  </option>
                                </TextField>
                              </Grid>
                            </Grid>
                            <Grid container spacing={4}>
                              <Grid item xs={12} sm={4}>
                                <TextField
                                  id="zipcode"
                                  name="zipcode"
                                  label="Zip / Postal Code"
                                  className={classes.textField}
                                  onChange={handleChange}
                                  margin="normal"
                                  variant="outlined"
                                  style={{ width: "100%" }}
                                  value={values?.zipcode || ""}
                                />
                              </Grid>
                              {/* <Grid item xs={12} sm={4}>
                                <Typography
                                  gutterBottom
                                  variant="h6"
                                  align="left"
                                  className={classes.subTitle2}
                                  style={{
                                    width: "100%",
                                    textAlign: "left",
                                    marginTop: "25px",
                                  }}
                                >
                                  DATE: 3/25/2020{" "}
                                </Typography>
                              </Grid> */}
                            </Grid>
                          </div>
                          <br></br>
                          <br></br>

                          <div>
                            <Typography
                              gutterBottom
                              variant="h6"
                              align="center"
                              style={{
                                maxWidth: "80%",
                                margin: "0px auto 15px",
                              }}
                              className={classes.subTitle2}
                            >
                              <b>MEDIA PRE-APPROVAL FORM</b>
                            </Typography>
                            <Typography
                              gutterBottom
                              variant="h6"
                              align="center"
                              style={{
                                maxWidth: "80%",
                                margin: "0px auto 10px",
                              }}
                              className={classes.subTitle2}
                            >
                              All types of advertising and marketing must be
                              pre-approved by the Carolina Skiff Advertising
                              Department to be eligible of co-op credits.
                            </Typography>
                            <Typography
                              gutterBottom
                              variant="h6"
                              align="center"
                              style={{ maxWidth: "80%", margin: "0px auto" }}
                              className={classes.subTitle2}
                            >
                              Please attach a layout copy of your advertisement
                              / marketing tool and mail or fax to the Co-op
                              Advertising Department at Carolina Skiff, LLC.
                              This form will be returned to you with approval or
                              with the necessary changes indicated for approval.
                              If changes are indicated, you must re-submit the
                              revised layout or copy.
                            </Typography>
                            <br></br>
                            <br></br>
                            <Grid container spacing={4} justify="space-around">
                              <Grid item xs={12} sm={4}>
                                <FormControl
                                  sx={{ m: 1, width: "25ch" }}
                                  variant="outlined"
                                >
                                  <label
                                    variant="h5"
                                    style={{
                                      width: "100%",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    ADVERTISING-TITLE
                                  </label>
                                  <OutlinedInput
                                    id="outlined-adornment-weight"
                                    endAdornment={
                                      <InputAdornment position="end">
                                        {" "}
                                      </InputAdornment>
                                    }
                                    name="advertising_title"
                                    style={{ width: "100%" }}
                                    onChange={handleChange}
                                    value={values?.advertising_title || ""}
                                    aria-describedby="outlined-weight-helper-text"
                                    inputProps={{
                                      "aria-label": "weight",
                                    }}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <FormControl
                                  sx={{ m: 1, width: "25ch" }}
                                  variant="outlined"
                                >
                                  <label
                                    variant="h5"
                                    style={{
                                      width: "100%",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    MEDIA NAME
                                  </label>
                                  <OutlinedInput
                                    id="outlined-adornment-weight"
                                    endAdornment={
                                      <InputAdornment position="end">
                                        {" "}
                                      </InputAdornment>
                                    }
                                    style={{ width: "100%" }}
                                    name="media_name"
                                    onChange={handleChange}
                                    value={values?.media_name || ""}
                                    aria-describedby="outlined-weight-helper-text"
                                    inputProps={{
                                      "aria-label": "weight",
                                    }}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <FormControl
                                  sx={{ m: 1, width: "25ch" }}
                                  variant="outlined"
                                >
                                  <label
                                    variant="h5"
                                    style={{
                                      width: "100%",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    APPROVED CREDIT %
                                  </label>
                                  <OutlinedInput
                                    id="outlined-adornment-weight"
                                    endAdornment={
                                      <InputAdornment position="end">
                                        {" "}
                                      </InputAdornment>
                                    }
                                    onChange={handleChange}
                                    name="approved_credit"
                                    value={values?.approved_credit || ""}
                                    style={{ width: "100%" }}
                                    aria-describedby="outlined-weight-helper-text"
                                    inputProps={{
                                      "aria-label": "weight",
                                    }}
                                  />
                                </FormControl>
                              </Grid>
                            </Grid>
                          </div>
                          <br></br>

                          <Typography
                            gutterBottom
                            variant="h6"
                            align="left"
                            className={classes.subTitle2}
                          >
                            <b>REQUIREMENTS</b>
                          </Typography>
                          <Typography>
                            <ol>
                              <li>
                                Print media / signs / clothing must include the
                                offical Carolina Skiff or Sea Chaser logo.
                              </li>
                              <li>
                                Any written copy must integrate the Carolina
                                Skiff or Sea Chaser as a product.
                              </li>
                              <li>
                                If a photo is used, it must be clear and showing
                                the product in a positive way.
                              </li>
                              <li>
                                No competitive brands may be mentioned or shown
                                in the same advertisements. Other requirements
                                can be found in your dealer manual.
                              </li>
                            </ol>
                          </Typography>

                          <Typography>
                            <p>
                              If you wish to receive a higher level of credit,
                              please contact the Co-op Advertising Department at
                              Carolina Skiff <br></br>to inquire on changes that
                              are needed for a higher level of credit. After
                              that, you must re-submit the advertisement.
                            </p>
                          </Typography>
                          <div>
                            <Grid container>
                              <Grid item xs={12} sm={12}>
                                <TextField
                                  id="additional_info"
                                  name="additional_info"
                                  label="Additional information. (OPTIONAL)"
                                  multiline
                                  rows="4"
                                  variant="outlined"
                                  className={classes.textField}
                                  onChange={handleChange}
                                  margin="normal"
                                  value={values?.additional_info || ""}
                                  style={{ width: "90%" }}
                                />
                              </Grid>
                            </Grid>
                          </div>

                          <Grid item xs={12} align="center">
                            <Button
                              color="primary"
                              variant="contained"
                              className={classes.button}
                              onClick={() => {
                                resetState();
                                history.push("/media-pre-approval-form")
                              }}
                              style={{ width: "15em", margin: "2em 1em" }}
                            >
                              CANCEL
                            </Button>
                            <Button
                              color="primary"
                              variant="contained"
                              className={classes.button}
                              type="submit"
                              style={{ width: "15em", margin: "2em 1em" }}
                            >
                              UPDATE FORM
                            </Button>
                          </Grid>
                        </Paper>
                      </div>
                    </form>
                  );
                }}
              </Mutation>
            </DashboardLayout>
          );
        }
      }}
    </Query>
  );
}

export default compose(withRouter, withStyles(styles))(MediaPreApprovalFormEdit);
