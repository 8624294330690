import React from 'react';
import {Redirect} from 'react-router-dom';
import auth from "../Auth";

function Home(props) {
    return (
        <div>
         { (auth.isAuthenticated()) ? <Redirect to="/dashboard" /> : auth.login() }
        </div>
    );
}
  
export default Home;