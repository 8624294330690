import React, { useState, useEffect } from 'react'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import "./MessagePopup.scss";
import { Mutation, Query } from "react-apollo";
import { ADD_ADMIN_MESSAGE, ADD_MESSAGE_READ_STATUS, GET_ALL_USER_DATA, UPDATE_ADMIN_MESSAGE } from 'queries';
import { toast } from "react-toastify";
import auth from 'Auth';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    container: {
        padding: "2em"
    },
    textField: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        width: "100%",
        margin: "1em",
        textAlign: "center"
    },
    dense: {
        marginTop: 19
    },
    formControl: {
        margin: theme.spacing(3),
        marginTop: theme.spacing(3)
    },
    addDealer: {
        width: "200px",
        fontSize: "18px",
        fontWeight: "300",
        textTransform: "uppercase",
        textAlign: "center",
    },
    submit: {
        width: "200px",
        padding: "1em",
        fontSize: "14px"
    },
    checkBoxTitle: {
        fontSize: "18px",
        textTransform: "uppercase",
        color: "#1f3340",
        fontWeight: "300",
    },
}));

function MessagePopup({ isMessage, toggleIsMessage, editDetail, setEditDetail }) {
    const [text, setText] = useState("");
    const [editorSize, setEditorSize] = useState('normal');
    const [userData, setUserData] = useState([])
    const [isSubmit, setIsSubmit] = useState(false)

    const handleTextChange = (value) => {
        setText(value);
    };

    const handleMinimize = () => {
        setEditorSize('minimized');
    };

    const handleMaximize = () => {
        setEditorSize('maximized');
    };

    useEffect(() => {
        if (editDetail) {
            setText(editDetail?.message)
        }
    }, [editDetail])

    useEffect(() => {
        if (isMessage) {
            const quillToolbar = document.querySelector('.ql-toolbar');
            if (quillToolbar) {
                let minimizeButton = document.createElement('button');
                minimizeButton.innerHTML = '<svg viewBox="-0.5 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M11.5 4.25H6.5C5.43913 4.25 4.42178 4.67142 3.67163 5.42157C2.92149 6.17172 2.5 7.18913 2.5 8.25V18.25C2.5 19.3109 2.92149 20.3283 3.67163 21.0784C4.42178 21.8286 5.43913 22.25 6.5 22.25H16.5C17.5609 22.25 18.5783 21.8286 19.3284 21.0784C20.0786 20.3283 20.5 19.3109 20.5 18.25V13.25" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M21.4998 3.28998L12.0098 12.78" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M17.1396 13.37H13.4297C12.8993 13.37 12.3905 13.1593 12.0154 12.7842C11.6404 12.4092 11.4297 11.9004 11.4297 11.37V7.65997" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>';
                minimizeButton.addEventListener('click', handleMinimize);
                quillToolbar.appendChild(minimizeButton);
                minimizeButton.classList.add('minimize-btn');

                let maximizeButton = document.createElement('button');
                maximizeButton.innerHTML = '<svg viewBox="-0.5 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M11.5 4.25H6.5C5.43913 4.25 4.42178 4.67142 3.67163 5.42157C2.92149 6.17172 2.5 7.18913 2.5 8.25V18.25C2.5 19.3109 2.92149 20.3283 3.67163 21.0784C4.42178 21.8286 5.43913 22.25 6.5 22.25H16.5C17.5609 22.25 18.5783 21.8286 19.3284 21.0784C20.0786 20.3283 20.5 19.3109 20.5 18.25V13.25" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M11.4297 13.38L20.9197 3.89001" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M15.7891 3.25H19.499C20.0295 3.25 20.5382 3.46074 20.9133 3.83582C21.2883 4.21089 21.499 4.71957 21.499 5.25V8.96002" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>';
                maximizeButton.addEventListener('click', handleMaximize);
                quillToolbar.appendChild(maximizeButton);
                maximizeButton.classList.add('maximize-btn');
            }
        }
    }, [isMessage]);

    useEffect(() => {
        const quillToolbar = document.querySelector('.ql-toolbar');
        if (quillToolbar) {
            let minimizeButton = document.createElement('button');
            minimizeButton.innerHTML = '<svg viewBox="-0.5 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M11.5 4.25H6.5C5.43913 4.25 4.42178 4.67142 3.67163 5.42157C2.92149 6.17172 2.5 7.18913 2.5 8.25V18.25C2.5 19.3109 2.92149 20.3283 3.67163 21.0784C4.42178 21.8286 5.43913 22.25 6.5 22.25H16.5C17.5609 22.25 18.5783 21.8286 19.3284 21.0784C20.0786 20.3283 20.5 19.3109 20.5 18.25V13.25" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M21.4998 3.28998L12.0098 12.78" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M17.1396 13.37H13.4297C12.8993 13.37 12.3905 13.1593 12.0154 12.7842C11.6404 12.4092 11.4297 11.9004 11.4297 11.37V7.65997" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>';
            minimizeButton.addEventListener('click', handleMinimize);
            quillToolbar.appendChild(minimizeButton);
            minimizeButton.classList.add('minimize-btn');

            let maximizeButton = document.createElement('button');
            maximizeButton.innerHTML = '<svg viewBox="-0.5 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M11.5 4.25H6.5C5.43913 4.25 4.42178 4.67142 3.67163 5.42157C2.92149 6.17172 2.5 7.18913 2.5 8.25V18.25C2.5 19.3109 2.92149 20.3283 3.67163 21.0784C4.42178 21.8286 5.43913 22.25 6.5 22.25H16.5C17.5609 22.25 18.5783 21.8286 19.3284 21.0784C20.0786 20.3283 20.5 19.3109 20.5 18.25V13.25" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M11.4297 13.38L20.9197 3.89001" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M15.7891 3.25H19.499C20.0295 3.25 20.5382 3.46074 20.9133 3.83582C21.2883 4.21089 21.499 4.71957 21.499 5.25V8.96002" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>';
            maximizeButton.addEventListener('click', handleMaximize);
            quillToolbar.appendChild(maximizeButton);
            maximizeButton.classList.add('maximize-btn');
        }
    }, []);

    let editorStyle = {};

    if (editorSize === 'minimized') {
        editorStyle = { height: '170px' };
    } else if (editorSize === 'maximized') {
        editorStyle = { height: '450px' };
    } else {
        editorStyle = { height: '300px' };
    }


    const handlePopup = () => {
        toggleIsMessage(true)
    }

    const handleSendClick = async (e, addAdminMessage, addMessageReadStatus) => {
        e.preventDefault();
        setIsSubmit(true)
        addAdminMessage({
            variables: {
                objects: [
                    {
                        profile_id: auth.getSub(),
                        message: text
                    }
                ]
            }
        })
            .then(response => {
                const messageId = response?.data?.insert_admin_messages?.returning?.[0]?.id;
                const objects = userData?.map(userId => ({
                    message_id: messageId,
                    user_id: userId,
                    is_read: 0,
                    read_at: null,
                    updated_at: null
                }));
                try {
                    addMessageReadStatus({
                        variables: {
                            objects: objects
                        }
                    });
                } catch (error) {

                }
            })
            .catch(error => {
                console.error("Error adding admin message:", error);
            });
    };

    const handleSendUpdateClick = (e, addAdminMessage) => {
        const currentTimeUtc = moment.utc()
        e.preventDefault();
        addAdminMessage({
            variables:
            {
                id: editDetail?.id,
                message: text,
                updated_at: currentTimeUtc.format()
            }
        });
    };

    const completedAdd = () => {
        toast("Message sent successfully");
        setIsSubmit(false)
        setText('')
        toggleIsMessage(false)
    }
    const completedUpdate = () => {
        toast("Message updated successfully");
        setText('')
        toggleIsMessage(false)
        setIsSubmit(false)
        setEditDetail(null)
    }

    return (
        <>
            <Query query={GET_ALL_USER_DATA} onCompleted={(data) => {
                setUserData(data?.profiles.map(profile => profile.profile_id));
            }}>
                {({ data, loading, error }) => {
                    if (loading) return <p>Loading...</p>;
                    if (error) return <p>Error</p>;
                    return (
                        <>
                            {
                                !isMessage &&
                                <Typography variant="body1" style={{ color: "#808080", cursor: 'pointer', margin: "15px 0px", padding: "7px" }} onClick={(() => handlePopup())}>
                                    Add your new message
                                </Typography>
                            }
                            {
                                isMessage &&
                                <div>
                                    <ReactQuill
                                        value={text}
                                        onChange={handleTextChange}
                                        modules={{
                                            toolbar: {
                                                container: [
                                                    [{ 'size': ['small', false, 'large', 'huge'] }, 'clean', 'bold', 'italic', 'underline', 'strike', { list: 'bullet' }, { list: 'ordered' }, { 'direction': 'rtl' }, 'link', 'image', 'blockquote', 'code-block',],

                                                ],
                                            }
                                        }}
                                        style={editorStyle}
                                    />
                                    <Grid item xs={12} style={{ margin: "15px 0px 20px" }}>
                                        {
                                            editDetail ?
                                                <Mutation mutation={UPDATE_ADMIN_MESSAGE} onCompleted={completedUpdate}>
                                                    {(updateAdminMessage, { data, loading, called, error }) => {
                                                        if (error)
                                                            return (
                                                                <pre>
                                                                    Bad:{" "}
                                                                    {error.graphQLErrors.map(({ message }, i) => (
                                                                        <span key={i}>{message}</span>
                                                                    ))}
                                                                </pre>
                                                            );
                                                        return (
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                type="submit"
                                                                onClick={(e) => handleSendUpdateClick(e, updateAdminMessage)}
                                                                style={{ marginRight: "8px" }}
                                                            >
                                                                SAVE
                                                            </Button>
                                                        );
                                                    }}
                                                </Mutation>
                                                :
                                                <Mutation mutation={ADD_ADMIN_MESSAGE} onCompleted={completedAdd}>
                                                    {(addAdminMessage, { data, loading, called, error }) => {
                                                        if (error)
                                                            return (
                                                                <pre>
                                                                    Bad:{" "}
                                                                    {error.graphQLErrors.map(({ message }, i) => (
                                                                        <span key={i}>{message}</span>
                                                                    ))}
                                                                </pre>
                                                            );
                                                        return (
                                                            <Mutation mutation={ADD_MESSAGE_READ_STATUS}>
                                                                {(addMessageReadStatus, { error }) => {
                                                                    if (error)
                                                                        return (
                                                                            <pre>
                                                                                Bad:{" "}
                                                                                {error.graphQLErrors.map(({ message }, i) => (
                                                                                    <span key={i}>{message}</span>
                                                                                ))}
                                                                            </pre>
                                                                        );
                                                                    return (
                                                                        <Button
                                                                            variant="contained"
                                                                            color="primary"
                                                                            type="submit"
                                                                            onClick={(e) => handleSendClick(e, addAdminMessage, addMessageReadStatus)}
                                                                            style={{ marginRight: "8px" }}
                                                                            disabled={isSubmit}
                                                                        >
                                                                            SAVE
                                                                        </Button>
                                                                    );
                                                                }}
                                                            </Mutation>
                                                        )

                                                    }}
                                                </Mutation>
                                        }
                                        <Button
                                            variant="contained"
                                            color="inherit"
                                            onClick={() => {
                                                setText('')
                                                toggleIsMessage(false)
                                                setEditDetail(null)
                                            }}
                                        >
                                            CANCEL
                                        </Button>
                                    </Grid>
                                </div>
                            }
                        </>
                    )

                }}
            </Query>
        </>

    )
}

export default MessagePopup