import React from "react";
import { Query } from "react-apollo";
import { getAllLeads } from "../queries";
import { Paper } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
// Shared layouts
import { Dashboard as DashboardLayout } from "layouts";
// Externals
import PropTypes from "prop-types";
import { Print } from "@material-ui/icons";
import {
  SelectionState,
  RowDetailState,
  SearchState,
  PagingState,
  SortingState,
  IntegratedSorting,
  IntegratedPaging,
  IntegratedFiltering,
  IntegratedSelection
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  PagingPanel,
  Toolbar,
  SearchPanel
} from "@devexpress/dx-react-grid-material-ui";

import LeadsFulfilledBy from "./LeadsFulfilledBy";
import { CSVLink } from "react-csv";
import Tooltip from '@material-ui/core/Tooltip';
import GridContainer from "@material-ui/core/Grid";
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import { withStyles } from "@material-ui/core";

// Component styles
import styles from "theme/skin";
import LeadsOptionsRender from "./Administration/LeadsOptionsRender";

const RowDetail = ({ row }) => (
  <div>
    <table cellSpacing="0" className="detailTable">
      <tbody>
        <tr>
          <td>Lead ID:</td>
          <td>{row.id}</td>
        </tr>
        <tr>
          <td>Lead Type:</td>
          <td>{row.lead_type}</td>
        </tr>
        <tr>
          <td>Brand:</td>
          <td>{row.brand}</td>
        </tr>
        <tr>
          <td>Status:</td>
          <td>{row.status}</td>
        </tr>
        <tr>
          <td>Address:</td>
          <td>{row.address}</td>
        </tr>
        <tr>
          <td>Country:</td>
          <td>{row.country}</td>
        </tr>
        <tr>
          <td>Email:</td>
          <td>{row.email}</td>
        </tr>
        <tr>
          <td>Phone:</td>
          <td>{row.phone}</td>
        </tr>
        <tr>
          <td>Custom Options:</td>
          <td>
            <LeadsOptionsRender options={row.options} />
          </td>
        </tr>
      </tbody>
    </table>
    <div className="flex center">
      <LeadsFulfilledBy tag={row.fulfilledby} id={row.id} />
    </div>
  </div >
);

class Leads extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "lead_type", title: "Lead Type" },
        { name: "lead_id", title: "#" },
        { name: "lead_date", title: "Date" },
        { name: "brand", title: "Brand" },
        { name: "name", title: "Name" },
        { name: "fulfilledby", title: "Fulfilled By" },
        { name: "fulfilledon", title: "Fulfilled On" }
      ],
      leadsData: [],
      selectedRows: []
    };

    this.changeSearchValue = value => this.setState({ searchValue: value });
  }

  changeSelection = (selectedRows) => {
    this.setState({ selectedRows });
  };

  render() {
    const { columns, selection, searchValue } = this.state;
    const { classes } = this.props;

    const objectToXml = require('object-to-xml');

    const handleConvertToXML = async (data) => {
      const selectedData = this.state.selectedRows.map((selectedIndex) => {
        return this.state.leadsData[selectedIndex];
      });

      if (selectedData.length === 0) {
        selectedData.push(...this.state.leadsData);
      }

      for (let index = 0; index < selectedData.length; index++) {
        const rowData = selectedData[index];

        // Convert JavaScript object to XML
        const xmlData = objectToXml({ root: rowData });

        // Create a Blob containing the XML data
        const blob = new Blob([xmlData], { type: 'application/xml' });

        // Create a download link
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(blob);
        a.download = `lead_${index + 1}.xml`;

        // Trigger the download
        a.click();

        // Wait for the previous download to complete before initiating the next one
        await new Promise((resolve) => setTimeout(resolve, 1000)); // Add a delay of 1 second between downloads
      }
    };

    return (
      <DashboardLayout title="Leads">
        <Query query={getAllLeads}>
          {({ data, loading, error }) => {
            if (loading) return <p>Loading...</p>;
            if (error) return <p>Error</p>;
            this.setState({ leadsData: data?.leads_with_dealer_info });

            for (let lead of data.leads_with_dealer_info) {
              let address = lead.address.trim();
              address = address.replace("Not Applicable", "Not-Applicable");
              let addressReversed = address.split(" ").reverse();
              lead.postalCode = addressReversed.shift();
              addressReversed.shift(); // country is already present.
              lead.state = (addressReversed.shift() || '').replace("Not-Applicable", "Not Applicable");
              lead.city = addressReversed.shift() || '';
            }
            let csvHeaders = [ // Specify order so location is left last which breaks react-csv's escaping and spills over into cells to the right. react-csv allows accessing nested json with . notation e.g. location.coordinates
              { label: 'id', key: 'id' },
              { label: 'profile_id', key: 'profile_id' },
              { label: 'dealer_name', key: 'dealer_name' },
              { label: 'lead_id', key: 'lead_id' },
              { label: 'lead_type', key: 'lead_type' },
              { label: 'created_at', key: 'created_at' },
              { label: 'status', key: 'status' },
              { label: 'address', key: 'address' },
              { label: 'country', key: 'country' },
              { label: 'email', key: 'email' },
              { label: 'phone', key: 'phone' },
              { label: 'heard_about', key: 'heard_about' },
              { label: 'magazines', key: 'magazines' },
              { label: 'time_frame', key: 'time_frame' },
              { label: 'ownership', key: 'ownership' },
              { label: 'interests', key: 'interests' },
              { label: 'age', key: 'age' },
              { label: 'annual_income', key: 'annual_income' },
              { label: 'brand', key: 'brand' },
              { label: 'lead_date', key: 'lead_date' },
              { label: 'name', key: 'name' },
              { label: 'options', key: 'options' },
              { label: 'fulfilledby', key: 'fulfilledby' },
              { label: 'fulfilledon', key: 'fulfilledon' },
              { label: 'postalCode', key: 'postalCode' },
              { label: 'state', key: 'state' },
              { label: 'city', key: 'city' },
              { label: 'location', key: 'location' },
              { label: '__typename', key: '__typename' }
            ];
            const csvLeads = data.leads_with_dealer_info.map(d => {
              let obj = {};
              for (let i in d) {
                if (typeof (d[i]) == 'object') { // Take nested JSON and attempt to put the nested JSON in one cell.
                  obj[i] = JSON.stringify(d[i]);
                } else {
                  obj[i] = d[i].toString();
                }
              }
              return obj;
            });
            return (
              <div className={classes.root + " sectionContainer"}>
                <Typography gutterBottom variant="h4" className="title">
                  <span>Leads</span>
                </Typography>
                <Paper className="tableStyles">
                  <div className={classes.header}>
                    <span className={classes.headerTitle}>All New Leads</span>

                    <GridContainer container justify="space-between" className={classes.iconHeaderContainer}>
                      <GridContainer item xs={12} sm={3}>
                        <a href="javascript:window.print()">
                          <Print className={classes.printIcon} />
                        </a>
                      </GridContainer>
                      <GridContainer item xs={12} sm={3}>
                        <CSVLink
                          data={data?.leads_with_dealer_info?.map((item) => ({
                            "Id": item?.id || "",
                            "Profile Id": item?.profile_id || "",
                            "Dealer Name": item?.dealer_name || "",
                            "Lead Id": item?.lead_id || "",
                            "Lead Type": item?.lead_type || "",
                            "Created At": item?.created_at || "",
                            "Status": item?.status || "",
                            "Address": item?.address || "",
                            "Country": item?.country || "",
                            "Email": item?.email || "",
                            "Phone": item?.phone || "",
                            "Heard About": item?.heard_about !== null ? item?.heard_about : "",
                            "Magazines": item?.magazines || "",
                            "Time Frame": item?.time_frame || "",
                            "Ownership": item?.ownership || "",
                            "Interests": item?.interests || "",
                            "Age": item?.age !== null ? item?.age : "",
                            "Annual Income": item?.annual_income !== null ? item?.annual_income : "",
                            "Brand": item?.brand || "",
                            "Lead Date": item?.lead_date || "",
                            "Name": item?.name || "",
                            "Options": item?.options || "",
                            "Fulfilledby": item?.fulfilledby || "",
                            "Fulfilledon": item?.fulfilledon || "",
                            "Location": item?.location ? `{type : ${item?.location?.type},crs:{type:${item?.location.crs?.type},properties:{name:urn:ogc:def:crs:EPSG::${item?.location?.crs?.properties?.name}}},coordinates:[${item?.location.coordinates?.[0]},${item?.location.coordinates?.[1]}]}` : "",
                            "__Typename": item?.__typename || "",
                            "PostalCode": item?.postalCode || "",
                            "State": item?.state || "",
                            "City": item?.city || ""
                          }))}
                          target="_blank" filename={"export-leads.csv"}>
                          <Tooltip title="Download To CSV">
                            <CloudDownloadOutlinedIcon />
                          </Tooltip>
                        </CSVLink>
                      </GridContainer>
                      <GridContainer item xs={12} sm={3}>
                        <Tooltip title="Download To XML" onClick={() => handleConvertToXML()}>
                          <CloudDownloadOutlinedIcon />
                        </Tooltip>
                      </GridContainer>
                    </GridContainer>
                  </div>
                  <Grid rows={data.leads_with_dealer_info} columns={columns}>
                    <SelectionState
                      selection={selection}
                      onSelectionChange={this.changeSelection}
                    />
                    <SearchState
                      value={searchValue}
                      onValueChange={this.changeSearchValue}
                    />
                    <SortingState
                      defaultSorting={[{ columnName: 'lead_id', direction: 'desc' }]}
                    />
                    <PagingState defaultCurrentPage={0} pageSize={10} />
                    <IntegratedSelection />
                    <IntegratedFiltering />
                    <IntegratedSorting />
                    <IntegratedPaging />
                    <RowDetailState defaultExpandedRowIds={[0]} />
                    <Table />
                    <TableHeaderRow showSortingControls />
                    <TableRowDetail contentComponent={RowDetail} />
                    <TableSelection showSelectAll />
                    <PagingPanel />
                    <Toolbar />
                    <SearchPanel />
                  </Grid>
                </Paper>
              </div>
            );
          }}
        </Query>
      </DashboardLayout>
    );
  }
}
Leads.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Leads);
