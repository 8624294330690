import React from "react";
import AlbumMediaItem from "./AlbumMediaItem";
import { withStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import auth from "Auth";
import apollo from "apollo";
import { DELETE_MEDIA_ITEM_BY_ID } from "queries";
import { toast } from "react-toastify";

const styles = {
  cardContainer: {
    display: "flex",
    flexWrap: "wrap"
  },
  marginFix: {
    marginTop: 19,
    marginBottom: 10,
    marginLeft: 5,
    marginRight: 5
  },
  removeAllButton: {
    width: "15em"
  },
  removeAll: {
    display: "flex",
    justifyContent: "center"
  }
};
function AlbumMediaList(props) {
  const { classes } = props;
  const client = apollo.clientForToken(auth.getIdToken());

  let idsArray = [];
  const mediaItems = props.items.map((mediaItem, index) => {
    //console.log(mediaItem);
    idsArray.push(mediaItem.id);
    return <AlbumMediaItem removeHandler={deleteMediaItem} key={index} item={mediaItem} />;
  });

  function removeAllMediaItems() {
    //console.log(idsArray);
    idsArray.map(function(mediaItemId) {
      return deleteMediaItem(mediaItemId);
    });
  }

  return (
    <div>
      <div className={classes.cardContainer}>
        {classes.cardContainer.display}
        {mediaItems}
      </div>
      <div className={classes.removeAll}>
        {mediaItems.length > 0 ? (
          <Button
            className={classes.removeAllButton}
            variant="contained"
            color="primary"
            onClick={removeAllMediaItems}
          >
            Remove All
          </Button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
  function deleteMediaItem(id) {
    //console.log("ideleteMediaItem" + id);
    client.mutate({
      mutation: DELETE_MEDIA_ITEM_BY_ID,
      variables: {
        id
      }
    });
    toast("Media Item  has been deleted");
  }
}

AlbumMediaList.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AlbumMediaList);
