import React, { Component } from "react";
import CareersApproveButton from "./CareersApproveButton";
import CareersUpdateButton from "./CareersUpdateButton";
import CareersDenyButton from "./CareersDenyButton";
import CareersActiveButton from "./CareersActiveButton";
import CareersEdit from "./CareersEdit";
export default class CareersUpdate extends Component {

    constructor({careers, status}) {
        super()
        this.state = {
            status: status,
            careers: careers,
            isEmptyState: true
        }
    }

    triggerEditWarrantyState = () => {
        this.setState({
          ...this.state,
          isEmptyState: false,
          isEditWarrantyState: true
        })
    }
    
    closeWarrantyState = () => {
        this.setState({
          ...this.state,
          isEmptyState: true,
          isEditWarrantyState: false
        })
    }

    render() {
        return (
        <div style={{textAlign: "center", padding: 10}}>
            {this.state.isEmptyState && this.state.status === "Pending" && <CareersApproveButton id={this.state.careers.id} addClick={this.triggerEditWarrantyState} />}
            {this.state.isEmptyState && <CareersUpdateButton id={this.state.careers.id} addClick={this.triggerEditWarrantyState} />}
            {this.state.isEmptyState && (this.state.status === "Active" || this.state.status === "Pending") && <CareersDenyButton id={this.state.careers.id} addClick={this.triggerEditWarrantyState} />}
            {this.state.isEmptyState && (this.state.status === "Closed") && <CareersActiveButton id={this.state.careers.id} addClick={this.triggerEditWarrantyState} />}
            {this.state.isEditWarrantyState && <CareersEdit careers={this.state.careers} addClick={this.triggerEditWarrantyState} closeEditForm={this.closeWarrantyState} />}
        </div>
        );
    }

}

