import React, { useState } from "react";
import { Query } from "react-apollo";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { Typography, Tooltip } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { CountryRegionData } from "react-country-region-selector";
import { ADD_BOATSHOWS } from "./queries";
import { Mutation } from "react-apollo";
import {
  GET_ALL_USER_DATA,
  GET_USER_INFO,
  getAllBoatShowRequests,
  getAllDealerOptions,
} from "queries";
import { toast } from "react-toastify";
import useForm from "components/useForm";
import auth from "../../Auth";
import InfoIcon from "@material-ui/icons/Info";
import Select from "react-select";
import "./BoatShowEdit.scss";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/client";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    textAlign: "center",
    margin: "auto",
  },
  dense: {
    marginTop: 19,
  },
  textColor: {
    color: "red",
    fontSize: "12px",
  },
  tooltipStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "5px",
    marginBottom: "1em",
  },
}));

const getRegions = (country) => {
  if (!country) {
    return [];
  }
  return country[2].split("|").map((regionPair) => {
    let [regionName, regionShortCode = null] = regionPair.split("~");
    return regionName;
  });
};

const BoatShowRequestForm = (props) => {
  const { values, handleChange, resetState } = useForm(submitForm);
  const classes = useStyles();
  // values.start_date = new Date().getDate()
  function submitForm() {
    //console.log(values);
  }
  function completedAdd(payload) {
    toast("Boat Show has been Added");
    resetState();
    setDealer("");
  }

  // const [selectedOptions, setSelectedOptions] = useState([]);
  const [dealer, setDealer] = useState("");

  // const UPDATE_BOAT_SHOW = gql`
  //   mutation updateBoatShow($id: uuid!, $dealer: String) {
  //     update_boat_shows(
  //       where: { id: { _eq: $id } }
  //       _set: { dealer: $dealer }
  //     ) {
  //       affected_rows
  //       returning {
  //         id
  //         dealer
  //       }
  //     }
  //   }
  // `;
  // const {
  //   loading,
  //   data: boatData,
  //   error,
  // } = useQuery(getAllBoatShowRequests, {
  //   notifyOnNetworkStatusChange: true,
  //   fetchPolicy: "cache-and-network",
  // });

  // const {
  //   loading: pLoading,
  //   data: profileData,
  //   error: pError,
  // } = useQuery(GET_ALL_USER_DATA, {
  //   notifyOnNetworkStatusChange: true,
  //   fetchPolicy: "cache-and-network",
  // });

  // const handleUpdate = async (updateBoatShows) => {
  //   const filteredData = boatData?.boat_shows?.filter(
  //     (item) => item?.dealer !== null && item?.dealer !== ""
  //   );

  //   const updates = filteredData
  //     ?.filter((item) => item?.dealer !== null)
  //     ?.map((item) => {
  //       const company =
  //         profileData?.profiles?.find((profile) => profile.name === item.dealer)
  //           ?.company || null;

  //       return { id: item.id, dealer: company };
  //     });

  //   for (const update of updates) {
  //     try {
  //       const result = await updateBoatShows({
  //         variables: { id: update?.id, dealer: update?.dealer },
  //       });
  //       console.log(result);
  //       // Handle success (e.g., show a message)
  //     } catch (error) {
  //       console.error(error);
  //       // Handle error (e.g., show error message for each update)
  //     }
  //   }
  // };

  return (
    <Query query={GET_USER_INFO} variables={{ id: auth.getSub() }}>
      {({ loading, error, data }) => {
        if (loading) return null;
        if (error) return `Error! ${error}`;

        if (data?.profiles_by_pk !== undefined) {
          const currentCompany = data?.profiles_by_pk?.company;
          //console.log("DATA")
          //console.log(data?.profiles_by_pk)
          let currentBrands = "";
          if (
            data?.profiles_by_pk.has_skiff &&
            data?.profiles_by_pk.has_funchaser &&
            data?.profiles_by_pk.has_seachaser
          ) {
            currentBrands = "Carolina Skiff/Fun Chaser/Sea Chaser";
          } else if (
            data?.profiles_by_pk.has_skiff &&
            data?.profiles_by_pk.has_funchaser &&
            !data?.profiles_by_pk.has_seachaser
          ) {
            currentBrands = "Carolina Skiff/Fun Chaser";
          } else if (
            data?.profiles_by_pk.has_skiff &&
            data?.profiles_by_pk.has_seachaser &&
            !data?.profiles_by_pk.has_funchaser
          ) {
            currentBrands = "Carolina Skiff/Sea Chaser";
          } else if (
            data?.profiles_by_pk.has_skiff &&
            !data?.profiles_by_pk.has_seachaser &&
            !data?.profiles_by_pk.has_funchaser
          ) {
            currentBrands = "Carolina Skiff";
          } else if (
            data?.profiles_by_pk.has_funchaser &&
            !data?.profiles_by_pk.has_skiff &&
            !data?.profiles_by_pk.has_seachaser
          ) {
            currentBrands = "Fun Chaser";
          } else if (
            data?.profiles_by_pk.has_seachaser &&
            !data?.profiles_by_pk.has_skiff &&
            !data?.profiles_by_pk.has_funchaser
          ) {
            currentBrands = "Sea Chaser";
          }
          return (
            <Query query={getAllDealerOptions}>
              {({ loading: profileLoading, error, data: profileData }) => {
                if (loading || profileLoading) return null;
                if (error) return `Error! ${error}`;
                return (
                  <Mutation mutation={ADD_BOATSHOWS} onCompleted={completedAdd}>
                    {(addTodo, { data, loading, called, error }) => {
                      return (
                        <form
                          onSubmit={(e) => {
                            e.preventDefault();
                            if (!values.site) {
                              toast.error("Please select a site");
                            } else if (!values?.country) {
                              toast.error("Please select a country");
                            } else if (!values?.region) {
                              toast.error("Please select a region");
                            } else {
                              console.log(dealer);
                              values.profile_id = auth.getSub();
                              values.country = values?.country?.[0];
                              values.dealer =
                                auth.getRole().toLowerCase() === "admin"
                                  ? dealer?.value
                                  : currentCompany;
                              values.brands = currentBrands;
                              // values.site = formState.site.join()
                              addTodo({
                                variables: {
                                  objects: [values],
                                },
                              });
                            }
                          }}
                          className={classes.container}
                          autoComplete="off"
                        >
                          <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                          >
                            {/* <Mutation
                              mutation={UPDATE_BOAT_SHOW}
                              onCompleted={completedAdd}
                            >
                              {(
                                updateTodo,
                                { data: boatData, loading, called, error }
                              ) => {
                                return (
                                  <button
                                    onClick={() => handleUpdate(updateTodo)}
                                  >
                                    update company name
                                  </button>
                                );
                              }}
                            </Mutation> */}
                            <Typography
                              gutterBottom
                              variant="h2"
                              style={{
                                fontSize: "20px",
                                fontWeight: "300",
                                textTransform: "uppercase",
                                paddingBottom: "1em",
                              }}
                            >
                              {auth.getRole().toLowerCase() === "admin"
                                ? "Add New Boat Show"
                                : "Request new upcoming boat show"}
                            </Typography>
                            <Grid container>
                              {/* Row 1 */}
                              <Grid item xs={12} lg={4} align="left">
                                <TextField
                                  id="name"
                                  name="name"
                                  label="Name of Show"
                                  placeholder="Name of Show"
                                  className={classes.textField}
                                  InputLabelProps={{ shrink: true }}
                                  margin="normal"
                                  onChange={handleChange}
                                  value={values.name || ""}
                                  required
                                />
                              </Grid>
                              <Grid item xs={12} lg={4} align="left">
                                <TextField
                                  id="start_date"
                                  name="start_date"
                                  label="Start Date"
                                  placeholder="Start Date"
                                  type="date"
                                  className={classes.textField}
                                  margin="normal"
                                  onChange={handleChange}
                                  value={
                                    values.start_date || new Date().getDate()
                                  }
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  required
                                />
                              </Grid>
                              <Grid item xs={12} lg={4} align="left">
                                <TextField
                                  id="end_date"
                                  name="end_date"
                                  label="End Date"
                                  placeholder="End Date"
                                  type="date"
                                  className={classes.textField}
                                  margin="normal"
                                  onChange={handleChange}
                                  value={
                                    values.end_date || new Date().getDate()
                                  }
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  required
                                />
                              </Grid>
                            </Grid>
                            <Grid container>
                              {/* Row 2 */}
                              <Grid item xs={12} lg={4} align="left">
                                <TextField
                                  id="address"
                                  name="address"
                                  label="Address"
                                  placeholder="Address"
                                  className={classes.textField}
                                  margin="normal"
                                  onChange={handleChange}
                                  value={values.address || ""}
                                  required
                                />
                              </Grid>
                              <Grid item xs={12} lg={4} align="left">
                                <TextField
                                  id="address2"
                                  name="address2"
                                  label="SUITE , APT # , Optional"
                                  placeholder="SUITE , APT # , Optional"
                                  className={classes.textField}
                                  margin="normal"
                                  onChange={handleChange}
                                  value={values.address2 || ""}
                                />
                              </Grid>
                              <Grid item xs={12} lg={4} align="left">
                                <TextField
                                  id="country"
                                  name="country"
                                  label="Country"
                                  value={values.country || ""}
                                  select
                                  onChange={handleChange}
                                  className={classes.textField}
                                  inputProps={{
                                    name: "country",
                                    id: "country",
                                  }}
                                  required
                                >
                                  {CountryRegionData?.map((option, index) => (
                                    <MenuItem key={option[0]} value={option}>
                                      {option[0]}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Grid>
                            </Grid>
                            {/* Row 3 */}
                            <Grid container>
                              <Grid item xs={12} lg={4} align="left">
                                <TextField
                                  id="city"
                                  name="city"
                                  label="City"
                                  placeholder="City"
                                  className={classes.textField}
                                  margin="normal"
                                  onChange={handleChange}
                                  value={values.city || ""}
                                  required
                                />
                              </Grid>
                              <Grid item xs={12} lg={4} align="left">
                                <TextField
                                  id="zipcode"
                                  name="zipcode"
                                  label="Zipcode"
                                  placeholder="Zipcode"
                                  className={classes.textField}
                                  margin="normal"
                                  onChange={handleChange}
                                  value={values.zipcode || ""}
                                  required
                                />
                              </Grid>
                              <Grid item xs={12} lg={4} align="left">
                                <TextField
                                  id="region"
                                  name="region"
                                  label="State/Region"
                                  value={values.region || ""}
                                  select
                                  onChange={handleChange}
                                  required
                                >
                                  {getRegions(values.country).map(
                                    (option, index) => (
                                      <MenuItem key={option[0]} value={option}>
                                        {option}
                                      </MenuItem>
                                    )
                                  )}
                                </TextField>
                              </Grid>
                            </Grid>
                            <Grid container>
                              <Grid item xs={12} align="center">
                                <TextField
                                  id="description"
                                  name="description"
                                  label="Boat Event Description"
                                  style={{ margin: 8, width: "100%" }}
                                  placeholder="Boat Event Description"
                                  helperText="Max 700 words"
                                  margin="normal"
                                  onChange={handleChange}
                                  value={values.description || ""}
                                  required
                                  multiline
                                  rows="4"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              style={{
                                marginBottom: "1em",
                              }}
                            >
                              <Grid
                                item
                                xs={12}
                                align="center"
                                className={classes.tooltipStyle}
                              >
                                {auth.getRole().toLowerCase() === "admin" ? (
                                  <Select
                                    required
                                    options={profileData?.profiles?.map(
                                      (option) => ({
                                        label: option?.company,
                                        value: option?.company,
                                      })
                                    )}
                                    isSearchable
                                    placeholder="Select a Dealer *"
                                    onChange={(res) => setDealer(res)}
                                    value={dealer}
                                    className="dealerSelect"
                                    isClearable={true}
                                  />
                                ) : null}
                                <TextField
                                  name="site"
                                  id="site"
                                  select
                                  required
                                  label="Select Site"
                                  style={{
                                    width: "350px",
                                    borderBottom: "0",
                                    margin: "0px 0px",
                                  }}
                                  className={
                                    classes.textField +
                                    " " +
                                    classes.prefixDropdown
                                  }
                                  onChange={handleChange}
                                  value={values.site}
                                  variant="outlined"
                                  margin="normal"
                                >
                                  <MenuItem value="CarolinaSkiff">
                                    Carolina Skiff
                                  </MenuItem>
                                  <MenuItem value="SeaChaser">
                                    Sea Chaser
                                  </MenuItem>
                                  <MenuItem value="CarolinaSkiff,SeaChaser">
                                    CarolinaSkiff/ SeaChaser
                                  </MenuItem>
                                </TextField>
                                {/* <p className={classes.textColor}>Please select both the sites if you want to display the boat show on both of them</p> */}
                              </Grid>
                            </Grid>
                            <Grid item xs={12} align="center">
                              <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                type="submit"
                              >
                                {auth.getRole().toLowerCase() === "admin"
                                  ? "Add New Boat Show"
                                  : "Request Boat Show"}
                              </Button>
                            </Grid>
                          </Grid>
                        </form>
                      );
                    }}
                  </Mutation>
                );
              }}
            </Query>
          );
        }
      }}
    </Query>
  );
};

export default BoatShowRequestForm;
