import React, { useState } from "react";
import PropTypes from "prop-types";
import { Dashboard as DashboardLayout } from "layouts";
import TextField from "@material-ui/core/TextField";
import { Grid, Paper, Typography, withStyles } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import { UPDATE_DEALER_PROFILE } from "queries";
import { Mutation, Query } from "react-apollo";
import { toast } from "react-toastify";
import useForm from "components/useForm";
import auth from "Auth";
import axios from "axios";
import auth0User from "components/Auth0User";
import { GET_USER_INFO } from "queries";
import { withRouter } from "react-router";
import compose from "recompose/compose";
import Geocode from "react-geocode";
import auth0 from "auth0-js";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(4),
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: "100%",
    margin: "1em",
    textAlign: "center",
  },
  dense: {
    marginTop: 19,
  },
  password: {
    marginTop: "4rem",
    marginBottom: 0,
  },
  formControl: {
    margin: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  updateDealerProfile: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(2),
    fontSize: "14px",
    fontWeight: 100,
  },
}));

const styles = (theme) => ({});
const EditDealerProfile = (props) => {
  const {
    match: { params },
  } = props;
  const { values, handleChange, resetState, handleChangeCheckbox, setValues } =
    useForm(submitForm);
  const [submitting, setSubmitting] = useState(false);

  const classes = useStyles();

  function updateUser(data, callBackMutation) {
    setSubmitting(true);

    let formData = {};
    let accessTokenPromise = auth0User.getToken();
    let AUTH_API_URL = auth0User.AUTH_API_URL();

    accessTokenPromise
      .then((accessToken) => {
        formData.profile_id = data.profile_id;
        formData.name = data.name;
        formData.email = data.email;
        formData.phone = data.phone;
        formData.company = data.company;
        formData.address = data.address;
        formData.address2 = data.address2;
        formData.city = data.city;
        formData.country = data.country;
        formData.region = data.region;
        formData.zipcode = data.zipcode;
        formData.business_email = data.business_email;

        axios
          .get(`https://maps.googleapis.com/maps/api/geocode/json`, {
            params: {
              address:
                values?.address +
                " " +
                values?.city +
                " " +
                values?.region +
                "," +
                values?.country[0] +
                " " +
                values?.zipcode,
              key: process.env.REACT_APP_GOOGLE_API_KEY,
            },
          })
          .then(function (response) {
            const lat =
              response.data.Response.View[0].Result[0].Location.DisplayPosition
                .Latitude;
            const lng =
              response.data.Response.View[0].Result[0].Location.DisplayPosition
                .Longitude;
            console.log("GEO CALL HAPPENED");
            console.log(
              response.data.Response.View[0].Result[0].Location.DisplayPosition
            );
            console.log("lat, lng");
            console.log(lat, lng);
            formData.location =
              '{ "type": "Point", "coordinates": [' + lng + ", " + lat + "] }";

            // axios({
            //   url: AUTH_API_URL + "users-by-email",
            //   method: "get",
            //   headers: { Authorization: `Bearer ${accessToken}` },
            //   params: {
            //     email: data.email
            //   }
            // })
            //   .then(function (response) {
            //     console.log("Response from email");
            //     console.log(response.data[0].user_id);

            // Checks to see if any users with that email is already in use
            // if (response.data !== undefined || response.data.length === 0) {
            console.log("Calling Data");
            console.log(formData);

            callBackMutation({
              variables: formData,
            });
            setSubmitting(false);
            return true;
            // } else {
            //   toast.error("ERROR: This email address is already being used", {
            //     position: toast.POSITION.TOP_LEFT
            //   });
            //   setSubmitting(false);
            // }
            // })
            // .catch(function (error) {
            //   toast.error("ERROR: User already exists", {
            //     position: toast.POSITION.TOP_LEFT
            //   });
            //   setSubmitting(false);
            // });
          })
          .catch((error) => {
            toast.error(
              "Error from getToken() with async( When promise gets rejected ): " +
                error,
              {
                position: toast.POSITION.TOP_LEFT,
              }
            );
            setSubmitting(false);
          });

        return true;
      })
      .catch(function (error) {
        toast.error("Error Getting GEO Location For Address: " + error, {
          position: toast.POSITION.TOP_LEFT,
        });
        //console.log(error);
      });
  }

  const handleUpdatePassword = (event) => {
    if (window.confirm("Are you sure you want to reset your password")) {
      const webAuth = new auth0.WebAuth({
        domain: "dev-v-8-slj6.auth0.com",
        clientID: "MzOcwoze6f529Uk5GQOXLFHv5bvjetOV",
      });
      webAuth.changePassword(
        {
          connection: "Username-Password-Authentication",
          email: values.email,
        },
        (err, res) => {
          if (err) {
            toast.error(
              `There was an error trying to reset your password. ${res}`,
              {
                position: toast.POSITION.TOP_LEFT,
              }
            );
          } else {
            toast("A reset password link has been sent to your email");
          }
        }
      );
    }
  };

  function submitForm() {
    //console.log(values);
  }

  function completedAdd(payload) {
    toast("Dealer was been succesfully updated");
    //resetState();
  }

  return (
    <DashboardLayout title="Edit Dealer">
      <div className={classes.root + " sectionContainer"}>
        <Typography gutterBottom variant="h4" className="title">
          <span>Update Profile</span>
        </Typography>

        <Paper className={classes.paper} style={{ margin: "2em 0px" }}>
          <Grid container>
            <Grid item xs align="center">
              <Query query={GET_USER_INFO} variables={{ id: auth.getSub() }}>
                {({ loading, error, data }) => {
                  if (loading) return null;
                  if (error) return `Error! ${error}`;

                  if (values.email === undefined) {
                    console.log("CALLED");
                    setValues(data.profiles_by_pk);
                  }
                  if (data.profiles_by_pk !== undefined) {
                    return (
                      <Mutation
                        mutation={UPDATE_DEALER_PROFILE}
                        onCompleted={completedAdd}
                      >
                        {(updateDealer) => {
                          return (
                            <>
                              <form
                                onSubmit={(e) => {
                                  e.preventDefault();
                                  updateUser(values, updateDealer);
                                  return false;
                                }}
                                className={classes.container}
                                autoComplete="off"
                              >
                                <Grid
                                  container
                                  direction="row"
                                  justify="space-between"
                                  alignItems="center"
                                >
                                  <Grid
                                    container
                                    direction="row"
                                    justify="space-between"
                                    alignItems="center"
                                    wrap="nowrap"
                                  ></Grid>
                                  {/* Row 1 */}
                                  <Grid
                                    container
                                    direction="row"
                                    justify="space-between"
                                    alignItems="center"
                                    wrap="nowrap"
                                  >
                                    <TextField
                                      id="name"
                                      name="name"
                                      label="Full Name"
                                      placeholder="Full Name"
                                      className={classes.textField}
                                      margin="normal"
                                      onChange={handleChange}
                                      value={values.name || ""}
                                      required
                                      variant="outlined"
                                    />

                                    <TextField
                                      id="phone"
                                      name="phone"
                                      label="Phone"
                                      placeholder="Phone"
                                      className={classes.textField}
                                      margin="normal"
                                      onChange={(e) => handleChange(e, "phone")}
                                      value={values.phone || ""}
                                      variant="outlined"
                                    />

                                    <TextField
                                      id="company"
                                      name="company"
                                      label="Company Name"
                                      placeholder="Company Name"
                                      className={classes.textField}
                                      margin="normal"
                                      onChange={handleChange}
                                      value={values.company || ""}
                                      required
                                      variant="outlined"
                                    />
                                  </Grid>

                                  <Grid
                                    container
                                    direction="row"
                                    justify="space-between"
                                    alignItems="center"
                                    wrap="nowrap"
                                  >
                                    <TextField
                                      id="email"
                                      name="email"
                                      label="Email"
                                      placeholder="Email"
                                      className={classes.textField}
                                      margin="normal"
                                      onChange={handleChange}
                                      value={values.email || ""}
                                      required
                                      variant="outlined"
                                    />
                                  </Grid>

                                  <Grid
                                    container
                                    direction="row"
                                    justify="space-between"
                                    alignItems="center"
                                    wrap="nowrap"
                                  >
                                    <TextField
                                      id="address"
                                      name="address"
                                      label="Address"
                                      placeholder="Address"
                                      className={classes.textField}
                                      margin="normal"
                                      onChange={handleChange}
                                      value={values.address || ""}
                                      required
                                      variant="outlined"
                                    />
                                    <TextField
                                      id="address2"
                                      name="address2"
                                      label="SUITE , APT # , Optional"
                                      placeholder="SUITE , APT # , Optional"
                                      className={classes.textField}
                                      margin="normal"
                                      onChange={handleChange}
                                      value={values.address2 || ""}
                                      variant="outlined"
                                    />
                                    <TextField
                                      id="city"
                                      name="city"
                                      label="City"
                                      placeholder="City"
                                      className={classes.textField}
                                      margin="normal"
                                      onChange={handleChange}
                                      value={values.city || ""}
                                      required
                                      variant="outlined"
                                    />
                                  </Grid>

                                  {/* Row 3 */}
                                  <Grid
                                    container
                                    direction="row"
                                    justify="space-between"
                                    alignItems="center"
                                    wrap="nowrap"
                                  >
                                    <TextField
                                      id="country"
                                      name="country"
                                      label="Country"
                                      select
                                      className={classes.textField}
                                      onChange={handleChange}
                                      value={values.country || ""}
                                      style={{ width: "90%" }}
                                      variant="outlined"
                                      SelectProps={{
                                        native: true,
                                        MenuProps: {
                                          className: classes.menu,
                                        },
                                      }}
                                      margin="normal"
                                    >
                                      <option key="" value="" />
                                      <option
                                        key="United States"
                                        value="United States"
                                      >
                                        United States
                                      </option>
                                    </TextField>
                                    <TextField
                                      name="region"
                                      label="State"
                                      variant="outlined"
                                      select
                                      style={{ width: "90%" }}
                                      className={classes.textField}
                                      value={values.region || ""}
                                      onChange={handleChange}
                                      SelectProps={{
                                        native: true,
                                        MenuProps: {
                                          className: classes.menu,
                                        },
                                      }}
                                      margin="normal"
                                    >
                                      <option key="" value="" />
                                      <option key="Alabama" value="Alabama">
                                        Alabama
                                      </option>
                                      <option key="Alaska" value="Alaska">
                                        Alaska
                                      </option>
                                      <option key="Arizona" value="Arizona">
                                        Arizona
                                      </option>
                                      <option key="Arkansas" value="Arkansas">
                                        Arkansas
                                      </option>
                                      <option
                                        key="California"
                                        value="California"
                                      >
                                        California
                                      </option>
                                      <option key="Colorado" value="Colorado">
                                        Colorado
                                      </option>
                                      <option
                                        key="Connecticut"
                                        value="Connecticut"
                                      >
                                        Connecticut
                                      </option>
                                      <option key="Delaware" value="Delaware">
                                        Delaware
                                      </option>
                                      <option key="Florida" value="Florida">
                                        Florida
                                      </option>
                                      <option key="Georgia" value="Georgia">
                                        Georgia
                                      </option>
                                      <option key="Hawaii" value="Hawaii">
                                        Hawaii
                                      </option>
                                      <option key="Idaho" value="Idaho">
                                        Idaho
                                      </option>
                                      <option key="Illinois" value="Illinois">
                                        Illinois
                                      </option>
                                      <option key="Indiana" value="Indiana">
                                        Indiana
                                      </option>
                                      <option key="Iowa" value="Iowa">
                                        Iowa
                                      </option>
                                      <option key="Kansas" value="Kansas">
                                        Kansas
                                      </option>
                                      <option key="Kentucky" value="Kentucky">
                                        Kentucky
                                      </option>
                                      <option key="Louisiana" value="Louisiana">
                                        Louisiana
                                      </option>
                                      <option key="Maine" value="Maine">
                                        Maine
                                      </option>
                                      <option key="Maryland" value="Maryland">
                                        Maryland
                                      </option>
                                      <option
                                        key="Massachusetts"
                                        value="Massachusetts"
                                      >
                                        Massachusetts
                                      </option>
                                      <option key="Michigan" value="Michigan">
                                        Michigan
                                      </option>
                                      <option key="Minnesota" value="Minnesota">
                                        Minnesota
                                      </option>
                                      <option
                                        key="Mississippi"
                                        value="Mississippi"
                                      >
                                        Mississippi
                                      </option>
                                      <option key="Missouri" value="Missouri">
                                        Missouri
                                      </option>
                                      <option key="Montana" value="Montana">
                                        Montana
                                      </option>
                                      <option key="Nebraska" value="Nebraska">
                                        Nebraska
                                      </option>
                                      <option key="Nevada" value="Nevada">
                                        Nevada
                                      </option>
                                      <option
                                        key="New Hampshire"
                                        value="New Hampshire"
                                      >
                                        New Hampshire
                                      </option>
                                      <option
                                        key="New Jersey"
                                        value="New Jersey"
                                      >
                                        New Jersey
                                      </option>
                                      <option
                                        key="New Mexico"
                                        value="New Mexico"
                                      >
                                        New Mexico
                                      </option>
                                      <option key="New York" value="New York">
                                        New York
                                      </option>
                                      <option
                                        key="North Carolina"
                                        value="North Carolina"
                                      >
                                        North Carolina
                                      </option>
                                      <option
                                        key="North Dakota"
                                        value="North Dakota"
                                      >
                                        North Dakota
                                      </option>
                                      <option key="Ohio" value="Ohio">
                                        Ohio
                                      </option>
                                      <option key="Oklahoma" value="Oklahoma">
                                        Oklahoma
                                      </option>
                                      <option key="Oregon" value="Oregon">
                                        Oregon
                                      </option>
                                      <option
                                        key="Pennsylvania"
                                        value="Pennsylvania"
                                      >
                                        Pennsylvania
                                      </option>
                                      <option
                                        key="Rhode Island"
                                        value="Rhode Island"
                                      >
                                        Rhode Island
                                      </option>
                                      <option
                                        key="South Carolina"
                                        value="South Carolina"
                                      >
                                        South Carolina
                                      </option>
                                      <option
                                        key="South Dakota"
                                        value="South Dakota"
                                      >
                                        South Dakota
                                      </option>
                                      <option key="Tennessee" value="Tennessee">
                                        Tennessee
                                      </option>
                                      <option key="Texas" value="Texas">
                                        Texas
                                      </option>
                                      <option key="Utah" value="Utah">
                                        Utah
                                      </option>
                                      <option key="Vermont" value="Vermont">
                                        Vermont
                                      </option>
                                      <option key="Virginia" value="Virginia">
                                        Virginia
                                      </option>
                                      <option
                                        key="Washington"
                                        value="Washington"
                                      >
                                        Washington
                                      </option>
                                      <option
                                        key="West Virginia"
                                        value="West Virginia"
                                      >
                                        West Virginia
                                      </option>
                                      <option key="Wisconsin" value="Wisconsin">
                                        Wisconsin
                                      </option>
                                      <option key="Wyoming" value="Wyoming">
                                        Wyoming
                                      </option>
                                    </TextField>
                                    <TextField
                                      id="zipcode"
                                      name="zipcode"
                                      label="Zipcode"
                                      placeholder="Zipcode"
                                      margin="normal"
                                      onChange={handleChange}
                                      value={values.zipcode || ""}
                                      required
                                      className={classes.textField}
                                      variant="outlined"
                                    >
                                      <MenuItem>
                                        <em>None</em>
                                      </MenuItem>
                                    </TextField>
                                  </Grid>
                                  <Grid
                                    container
                                    direction="row"
                                    justify="space-between"
                                    alignItems="center"
                                    wrap="nowrap"
                                  >
                                    <TextField
                                      id="business_email"
                                      name="business_email"
                                      label="Business Email"
                                      placeholder="Business Email"
                                      className={classes.textField}
                                      margin="normal"
                                      onChange={handleChange}
                                      value={values.business_email || ""}
                                      variant="outlined"
                                    />
                                  </Grid>
                                  {/* Row 3 */}
                                  <Grid container>
                                    <Grid item xs={12} align="center">
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.updateDealerProfile}
                                        type="submit"
                                        disabled={submitting}
                                      >
                                        {submitting
                                          ? "LOADING"
                                          : "UPDATE PROFILE"}
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </form>
                              <Grid container>
                                <Grid item xs={12} align="center">
                                  <h2 className={classes.password}>
                                    Update Password
                                  </h2>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.updateDealerProfile}
                                    type="button"
                                    onClick={handleUpdatePassword}
                                  >
                                    UPDATE PASSWORD
                                  </Button>
                                </Grid>
                              </Grid>
                            </>
                          );
                        }}
                      </Mutation>
                    );
                  }
                }}
              </Query>
            </Grid>
          </Grid>
        </Paper>
      </div>
    </DashboardLayout>
  );
};

export default compose(withRouter, withStyles(styles))(EditDealerProfile);
