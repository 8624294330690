import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography } from "@material-ui/core";
// Shared layouts
import { Dashboard as DashboardLayout } from "layouts";
// Component styles
import styles from "theme/skin";
import EditDealer from "./MyDealers/EditDealer";
import { withRouter } from "react-router";
import compose from "recompose/compose";

function MyDealersEdit(props) {
  const { classes } = props;
  return (
    <DashboardLayout title="Edit Dealer     ">
      <div className={classes.root + " sectionContainer"}>
        <Typography gutterBottom variant="h4" className="title">
          <span>Edit Dealer</span>
        </Typography>

        <Paper className={classes.paper} style={{ margin: "2em 0px" }}>
          <Grid container>
            <Grid item xs align="center">
              <EditDealer />
            </Grid>
          </Grid>
        </Paper>
      </div>
    </DashboardLayout>
  );
}

MyDealersEdit.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(withRouter, withStyles(styles))(MyDealersEdit);
