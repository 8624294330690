import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography } from "@material-ui/core";

// Shared layouts
import { Dashboard as DashboardLayout } from "layouts";

// Component styles
import styles from "theme/skin";
import AddDealer from "./MyDealers/AddDealer";
import DealersGrid from "./MyDealers/DealersGrid";

function MyDealers(props) {
  const { classes } = props;
  return (
    <DashboardLayout title="My Dealers">
      <div className={classes.root + " sectionContainer"}>
        <Typography gutterBottom variant="h4" className="title">
          <span>My Dealers</span>
        </Typography>

        <Paper className={classes.paper} style={{ margin: "2em 0px" }}>
          <Grid container>
            <Grid item xs align="center">
              <AddDealer />
            </Grid>
          </Grid>
        </Paper>

        <Paper className={classes.paper} style={{ margin: "2em 0px" }}>
          <Grid container>
            <Grid item xs align="center">
              <DealersGrid />
            </Grid>
          </Grid>
        </Paper>
      </div>
    </DashboardLayout>
  );
}

MyDealers.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(MyDealers);
