import React, { Component } from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { Typography, withStyles, Paper } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import { CountryRegionData } from "react-country-region-selector";
import { ADD_ADMIN_USER } from "queries";
import { Mutation } from "react-apollo";
import { toast } from "react-toastify";
import useForm from "components/useForm";
import auth from "Auth";
import axios from "axios";
import auth0User from "components/Auth0User";
const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: 275,
    textAlign: "center"
  },
  dense: {
    marginTop: 19
  },
  formControl: {
    margin: theme.spacing(3)
  },
  root: {
    padding: theme.spacing(4),
    background: "#fafafa"
  }
}));

const getRegions = country => {
  if (!country) {
    return [];
  }
  return country[2].split("|").map(regionPair => {
    let [regionName, regionShortCode = null] = regionPair.split("~");
    return regionName;
  });
};

const Adduser = props => {
  const { values, handleChange, resetState, handleChangeCheckbox } = useForm(
    submitForm
  );
  const classes = useStyles();

  // values.start_date = new Date().getDate()
  function submitForm() {
    //console.log(values);
  }

  function permissionsCreator() {
    let permissionsArray = [];

    if (values.ADMIN_ALL === true) {
      let permissionsArray = [];
      permissionsArray.push("admin:ADMIN_ALL");
      return permissionsArray;
    }
    if (values.ADMIN_LEADS === true) {
      permissionsArray.push("admin:ADMIN_LEADS");
    }
    if (values.ADMIN_MY_DEALERS === true) {
      permissionsArray.push("admin:ADMIN_MY_DEALERS");
    }
    if (values.ADMIN_WARRANTY_CLAIMS === true) {
      permissionsArray.push("admin:ADMIN_WARRANTY_CLAIMS");
    }
    if (values.ADMIN_WARRANTY_LOOKUP === true) {
      permissionsArray.push("admin:ADMIN_WARRANTY_LOOKUP");
    }
    if (values.ADMIN_CAREERS === true) {
      permissionsArray.push("admin:ADMIN_CAREERS");
    }
    if (values.ADMIN_CSI === true) {
      permissionsArray.push("admin:ADMIN_CSI");
    }
    if (values.ADMIN_MEDIA === true) {
      permissionsArray.push("admin:ADMIN_MEDIA");
    }
    if (values.ADMIN_CO_OP_REQ === true) {
      permissionsArray.push("admin:ADMIN_CO_OP_REQ");
    }
    if (values.ADMIN_BOAT_SHOW_REQUESTS === true) {
      permissionsArray.push("admin:ADMIN_BOAT_SHOW_REQUESTS");
    }
    if (values.ADMIN_WARRANTY_INQ === true) {
      permissionsArray.push("admin:ADMIN_WARRANTY_INQ");
    }
    if (values.ADMIN_NEW_DEALER_REQ === true) {
      permissionsArray.push("admin:ADMIN_NEW_DEALER_REQ");
    }
    if (values.ADMIN_CONTACT_REQ === true) {
      permissionsArray.push("admin:ADMIN_CONTACT_REQ");
    }
    return permissionsArray;
  }
  function createNewAdminUser(data, callBackMutation) {
    let formData = {};
    let accessTokenPromise =auth0User.getToken();
    let AUTH_API_URL = auth0User.AUTH_API_URL();

    accessTokenPromise.then((accessToken) => {  
    //console.log('Data from getToken() with async( When promise gets resolved ): ' + accessToken);  
    formData.name = data.name;
    formData.email = data.email;
    formData.role = "Admin";
    formData.parent_admin = auth.getSub();
    // formData.company = data.company;
    // formData.address = data.address;
    // formData.address2 = data.address2;
    // formData.city = data.city;
    // formData.country=values.country;
    // formData.region = data.region;
    // formData.zipcode = data.zipcode;
    //formData.country = data.country[0];
    // Geocode.setLanguage("en");
    // Geocode.fromAddress(values.city + "," + values.region).then(
    //   response => {
    //     const { lat, lng } = response.results[0].geometry.location;
    //     console.log("lat, lng");
    //     console.log(lat, lng);
    //   },
    //   error => {
    //     console.error(error);
    //   }
    //);

    axios({
      url: AUTH_API_URL + "users-by-email",
      method: "get",
      headers: { Authorization: `Bearer ${accessToken}` },
      params: {
        email: formData.email
      }
    })
      .then(function(response) {
        console.log("users-by-email Result");
        console.log(response);

        if (response.data !== undefined || response.data.length === 0) {
          console.log(" Data length" + response.data.length);
          let auth0permissions = permissionsCreator();

          axios({
            url: AUTH_API_URL + "users",
            method: "post",
            headers: { Authorization: `Bearer ${accessToken}` },
            data: {
              connection: "Username-Password-Authentication",
              email: formData.email,
              password: data.password,
              user_metadata: {
                full_name: formData.name
              },
              email_verified: false,
              app_metadata: {
                role: "Admin",
                permissions: auth0permissions
              }
            }
          })
            .then(function(response) {
              console.log("New User Response");
              console.log(response);

              let user_id = response.data.user_id;
              let permissions = permissionsCreator();
              formData.permissions = permissions;
              formData.profile_id = user_id;
              console.log(response);
              console.log("Calling Data");
              console.log(formData);
              delete data.password;

              callBackMutation({
                variables: {
                  objects: [formData]
                }
              });
              return true;
            })
            .catch(function(error) {
              console.log(error);
            });
        }
      })
      .catch(function(error) {
        console.log(error);
      });
    }).catch((error) => {  
      console.log('Error from getToken() with async( When promise gets rejected ): ' + error);  
    });
  }

  function completedAdd(payload) {
    //console.log("completeAdd has been called" + payload);
    toast("New Admin User has been created succesfully");
    resetState();
  }
  return (
    <Mutation mutation={ADD_ADMIN_USER} onCompleted={completedAdd}>
      {(addNewAdminUser, { data, loading, called, error }) => {
        if (error)
          return (
            <pre>
              Bad:{" "}
              {error.graphQLErrors.map(({ message }, i) => (
                <span key={i}>{message}</span>
              ))}
            </pre>
          );
        return (
          <div className={classes.root + " sectionContainer"}>
            <Typography gutterBottom variant="h2" className="title">
              <span>ADD NEW ADMIN USER</span>
            </Typography>
            <Paper style={{ padding: "2em" }}>
              <form
                onSubmit={e => {
                  e.preventDefault();
                  createNewAdminUser(values, addNewAdminUser);
                  return false;
                  // let permissions = permissionsCreator();
                  // let formData = {};
                  // formData.profile_id = auth.getSub();
                  // formData.name = values.fullname; //+ " " + values.lastname;
                  // formData.email = values.email;
                  // formData.permissions = permissions;
                  // delete data.fullname;
                  // e.preventDefault();
                  // addNewAdminUser({
                  //   variables: {
                  //     objects: [formData]
                  //   }
                  // });
                }}
                className={classes.container}
                autoComplete="off"
              >
                <div>
                  <Grid>
                    <TextField
                      id="name"
                      name="name"
                      label="Full Name"
                      placeholder="Full Name"
                      className={classes.textField}
                      margin="normal"
                      onChange={handleChange}
                      value={values.name}
                      required
                      variant="outlined"
                    />
                  </Grid>

                  {/* <Grid>
                    <TextField
                      id="lastname"
                      name="lastname"
                      label="Last Name"
                      placeholder="Last Name"
                      className={classes.textField}
                      margin="normal"
                      onChange={handleChange}
                      value={values.lastname}
                      required
                      variant="outlined"
                    />
                  </Grid> */}

                  <Grid>
                    <TextField
                      id="email"
                      name="email"
                      label="Email"
                      placeholder="Email"
                      type="email"
                      className={classes.textField}
                      margin="normal"
                      onChange={handleChange}
                      value={values.email}
                      required
                      variant="outlined"
                    />
                  </Grid>
                  <Grid>
                    <TextField
                      id="password"
                      name="password"
                      label="Password"
                      placeholder="Password"
                      type="password"
                      className={classes.textField}
                      margin="normal"
                      onChange={handleChange}
                      value={values.password}
                      required
                      variant="outlined"
                    />
                  </Grid>
                </div>

                {/* Row 2 */}
                <Grid item xs={12} align="left">
                  <FormControl
                    required
                    component="fieldset"
                    className={classes.formControl}
                  >
                    <FormLabel component="legend">User Permissions</FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="ADMIN_ALL"
                            checked={values.ADMIN_ALL || false}
                            onChange={handleChangeCheckbox}
                            value="ADMIN_ALL"
                          />
                        }
                        label="ALL"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="ADMIN_LEADS"
                            checked={values.ADMIN_LEADS || false}
                            onChange={handleChangeCheckbox}
                            value="ADMIN_LEADS"
                          />
                        }
                        label="ADMIN LEVEL LEADS"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="ADMIN_MY_DEALERS"
                            checked={values.ADMIN_MY_DEALERS || false}
                            onChange={handleChangeCheckbox}
                            value="ADMIN_MY_DEALERS"
                          />
                        }
                        label="ADMIN LEVEL MY DEALERS"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="ADMIN_WARRANTY_CLAIMS"
                            checked={values.ADMIN_WARRANTY_CLAIMS || false}
                            onChange={handleChangeCheckbox}
                            value="ADMIN_WARRANTY_CLAIMS"
                          />
                        }
                        label="ADMIN LEVEL WARRANTY CLAIMS"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="ADMIN_WARRANTY_LOOKUP"
                            checked={values.ADMIN_WARRANTY_LOOKUP || false}
                            onChange={handleChangeCheckbox}
                            value="ADMIN_WARRANTY_LOOKUP"
                          />
                        }
                        label="ADMIN LEVEL WARRANTY LOOKUP"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="ADMIN_CAREERS"
                            checked={values.ADMIN_CAREERS || false}
                            onChange={handleChangeCheckbox}
                            value="ADMIN_CAREERS"
                          />
                        }
                        label="ADMIN LEVEL CAREERS"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="ADMIN_CSI"
                            checked={values.ADMIN_CSI || false}
                            onChange={handleChangeCheckbox}
                            value="ADMIN_CSI"
                          />
                        }
                        label="ADMIN LEVEL CSI"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="ADMIN_MEDIA"
                            checked={values.ADMIN_MEDIA || false}
                            onChange={handleChangeCheckbox}
                            value="ADMIN_MEDIA"
                          />
                        }
                        label="ADMIN LEVEL MEDIA"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="ADMIN_CO_OP_REQ"
                            checked={values.ADMIN_CO_OP_REQ || false}
                            onChange={handleChangeCheckbox}
                            value="ADMIN_CO_OP_REQ"
                          />
                        }
                        label="ADMIN LEVEL CO OP REQUESTS"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="ADMIN_BOAT_SHOW_REQUESTS"
                            checked={values.ADMIN_BOAT_SHOW_REQUESTS || false}
                            onChange={handleChangeCheckbox}
                            value="ADMIN_BOAT_SHOW_REQUESTS"
                          />
                        }
                        label="ADMIN LEVEL BOAT SHOW REQUESTS"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="ADMIN_WARRANTY_INQ"
                            checked={values.ADMIN_WARRANTY_INQ || false}
                            onChange={handleChangeCheckbox}
                            value="ADMIN_WARRANTY_INQ"
                          />
                        }
                        label="ADMIN LEVEL WARRANTY INQURIES"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="ADMIN_NEW_DEALER_REQ"
                            checked={values.ADMIN_NEW_DEALER_REQ || false}
                            onChange={handleChangeCheckbox}
                            value="ADMIN_NEW_DEALER_REQ"
                          />
                        }
                        label="ADMIN LEVEL NEW DEALER REQUESTS"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="ADMIN_CONTACT_REQ"
                            checked={values.ADMIN_CONTACT_REQ || false}
                            onChange={handleChangeCheckbox}
                            value="ADMIN_CONTACT_REQ"
                          />
                        }
                        label="ADMIN LEVEL CONTACT REQUESTS"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>

                <Grid container direction="row" justify="center">
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    style={{ width: "150px" }}
                    type="submit"
                  >
                    ADD USER
                  </Button>
                </Grid>
              </form>
            </Paper>
          </div>
        );
      }}
    </Mutation>
  );
};

export default Adduser;
