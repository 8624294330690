import React from "react";
import {
    Page,
    Text,
    View,
    Document,
    Font,
    StyleSheet
} from "@react-pdf/renderer";

Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
  });

const styles = StyleSheet.create({
    page: {
        backgroundColor: "#f6f6f5",
        textAlign: 'left',
        display: "flex",
        height: "100%",
        margin: 10
    },
    rowContainer: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        width: "50%",
        alignItems: "left",
        margin: 8
    },
    rowHrline: {
        borderBottomColor: "#2D6C9B",
        borderBottomWidth: '2'
    },
    rowContainerFull: {
        display: "inline-block",
        textAlign: "left",
        width: "100%",
        marginBottom: 8
    },
    row: {
        display: "flex",
        flexDirection: "row",
        flexWrap:"wrap"
    },
    rowFull: {
       	margin: 'auto'
    },
    column: {
        display: "flex",
        flexDirection: "column",
        flexBasis: "100%",
        flex: 1,
        minHeight: "40px"
    },
    columnFull: {
        display: "flex",
        flexDirection: "column",
        flexBasis: "100%",
        flex: 1
    },
    field_header: {
        padding: 2,
        fontSize: 14,
        fontFamily: 'Oswald',
        fontWeight: 700
    },
    field_text: {
        padding: 2,
        fontFamily: 'Oswald',
        fontSize: 9
    },
    sectionHeader: {
        color: '#2D6C9B',
        fontSize: 14,
        margin: 8,
        fontFamily: 'Oswald'
    },
    subHeader: {
        color: '#2D6C9B',
        fontSize: 12,
        margin: 6,
        fontFamily: 'Oswald'
    },
    pageNumbers: {
        position: 'absolute',
        fontSize: 14,
        bottom: 5,
        left: 0,
        right: 0,
        textAlign: 'center'
    }
});

export function NewDealerPdfDocument(props) {
    const newDealer = props.data;
    const outboardBrands = JSON.parse(unescape(newDealer.outboard_brands));
    const arrayOutboardBrands = Object.values(outboardBrands);
    let brand = '';
    if (newDealer.carolinaskiff) {
        brand = 'Carolina Skiff';
    }
    if (newDealer.seachaser) {
        brand = 'Sea Chaser';
    }
    return (
        <Document>
            <Page style={styles.page}>
                        <Text style={styles.sectionHeader}>{brand} Dealer Application</Text>
                        <View style={styles.rowContainer}>
                            <View style={styles.row}>
                                <View style={styles.column}>
                                    <Text style={styles.field_header}>Dealer Name</Text>
                                    <Text style={styles.field_text}>{newDealer.dealer_name}</Text>
                                </View>
                            </View>
                            <View style={styles.row}>
                                <View style={styles.column}>
                                    <Text style={styles.field_header}>Legal Name</Text>
                                    <Text style={styles.field_text}>{newDealer.legal_name}</Text>
                                </View>
                            </View>
                        </View>
                        <Text style={styles.sectionHeader}>
                        Physical Business Address
                        </Text>
                        <View style={styles.rowContainer}>
                            <View style={styles.row}>
                                <View style={styles.column}>
                                    <Text style={styles.field_text}>{newDealer.address}</Text>
                                    <Text style={styles.field_text}>{newDealer.address2}</Text>
                                    <Text style={styles.field_text}>{newDealer.city}, {newDealer.region} {newDealer.country} {newDealer.zipcode}</Text>
                                </View>
                            </View>
                        </View>
                        <Text style={styles.sectionHeader}>
                        Boat Delivery (Shipping) Address
                        </Text>
                        <View style={styles.rowContainer}>
                            <View style={styles.row}>
                                <View style={styles.column}>
                                    <Text style={styles.field_text}>{newDealer.delivery_address}</Text>
                                    <Text style={styles.field_text}>{newDealer.delivery_address2}</Text>
                                    <Text style={styles.field_text}>{newDealer.delivery_city}, {newDealer.delivery_region} {newDealer.delivery_country} {newDealer.delivery_zipcode}</Text>
                                </View>
                            </View>
                        </View>
                        <Text style={styles.sectionHeader}>
                        Mailing/Billing/Invoicing Address
                        </Text>
                        <View style={styles.rowContainer}>
                            <View style={styles.row}>
                                <View style={styles.column}>
                                    <Text style={styles.field_text}>{newDealer.mailing_address}</Text>
                                    <Text style={styles.field_text}>{newDealer.mailing_address2}</Text>
                                    <Text style={styles.field_text}>{newDealer.mailing_city}, {newDealer.mailing_region} {newDealer.mailing_country} {newDealer.delivery_zipcode}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={styles.rowContainer}>
                            <View style={styles.row}>
                                <View style={styles.column}>
                                    <Text style={styles.field_header}>Business Phone 1</Text>
                                    <Text style={styles.field_text}>{newDealer.business_phone1}</Text>
                                </View>
                            </View>
                            <View style={styles.row}>
                                <View style={styles.column}>
                                    <Text style={styles.field_header}>Business Phone 2</Text>
                                    <Text style={styles.field_text}>{newDealer.business_phone2}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={styles.rowContainer}>
                            <View style={styles.row}>
                                <View style={styles.column}>
                                    <Text style={styles.field_header}>Business Fax</Text>
                                    <Text style={styles.field_text}>{newDealer.business_fax}</Text>
                                </View>
                            </View>
                            <View style={styles.row}>
                                <View style={styles.column}>
                                    <Text style={styles.field_header}>Business Contact Cell</Text>
                                    <Text style={styles.field_text}>{newDealer.business_cell}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={styles.rowContainer}>
                            <View style={styles.row}>
                                <View style={styles.column}>
                                    <Text style={styles.field_header}>Other Contact Numbers</Text>
                                    <Text style={styles.field_text}>{newDealer.business_other}</Text>
                                </View>
                            </View>
                            <View style={styles.row}>
                                <View style={styles.column}>
                                    <Text style={styles.field_header}>Business Email</Text>
                                    <Text style={styles.field_text}>{newDealer.business_email}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={styles.rowContainer}>
                            <View style={styles.row}>
                                <View style={styles.column}>
                                    <Text style={styles.field_header}>Sales Leads Email</Text>
                                    <Text style={styles.field_text}>{newDealer.business_sales_email}</Text>
                                </View>
                            </View>
                            <View style={styles.row}>
                                <View style={styles.column}>
                                    <Text style={styles.field_header}>Website URL</Text>
                                    <Text style={styles.field_text}>{newDealer.business_website}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={styles.rowHrline} wrap={false} />
                        <View style={styles.rowContainer}>
                            <View style={styles.row}>
                                <View style={styles.column}>
                                    <Text style={styles.field_header}>Contact #1 Name</Text>
                                    <Text style={styles.field_text}>{newDealer.contact_name_1}</Text>
                                </View>
                            </View>
                            <View style={styles.row}>
                                <View style={styles.column}>
                                    <Text style={styles.field_header}>Contact #1 Title</Text>
                                    <Text style={styles.field_text}>{newDealer.contact_title_1}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={styles.rowContainer}>
                            <View style={styles.row}>
                                <View style={styles.column}>
                                    <Text style={styles.field_header}>Contact #2 Name</Text>
                                    <Text style={styles.field_text}>{newDealer.contact_name_2}</Text>
                                </View>
                            </View>
                            <View style={styles.row}>
                                <View style={styles.column}>
                                    <Text style={styles.field_header}>Contact #2 Title</Text>
                                    <Text style={styles.field_text}>{newDealer.contact_title_2}</Text>
                                </View>
                            </View>
                        </View>
                        <Text style={styles.pageNumbers} render={({ pageNumber, totalPages }) => (
                            `${pageNumber} / ${totalPages}`
                        )} fixed />
            </Page>
            <Page style={styles.page}>
                        <View style={styles.rowContainer}>
                            <View style={styles.row}>
                                <View style={styles.column}>
                                    <Text style={styles.field_header}>Boat Brands You Carry/Sell</Text>
                                    {newDealer.boat_brands_sold.split('\n').map((item, key) => {
                                        return <Text key={key} style={styles.field_text}>{item}</Text>
                                    })}
                                </View>
                            </View>
                        </View>
                        <Text style={styles.sectionHeader}>
                        Outboard Brands
                        </Text>
                        <View style={styles.rowContainer}>
                            <View style={styles.row}>
                                <View style={styles.column}>
                                    {arrayOutboardBrands.map((obBrand, key) => {
                                        if(obBrand !== "undefined," || obBrand !== "none,")
                                        return <Text key={`${key}`} style={styles.field_text}>{obBrand}</Text>
                                    })}
                                </View>
                            </View>
                        </View>
                        <View style={styles.rowHrline} wrap={false} />
                        <Text style={styles.sectionHeader}>
                        Banking Information
                        </Text>
                        <View style={styles.rowContainer}>
                            <View style={styles.row}>
                                <View style={styles.column}>
                                    <Text style={styles.field_header}>Bank Name</Text>
                                    <Text style={styles.field_text}>{newDealer.bank_name}</Text>
                                </View>
                            </View>
                            <View style={styles.row}>
                                <View style={styles.column}>
                                    <Text style={styles.field_header}>Bank Account</Text>
                                    <Text style={styles.field_text}>{newDealer.bank_account}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={styles.rowContainer}>
                            <View style={styles.row}>
                                <View style={styles.column}>
                                    <Text style={styles.field_header}>Bank Contact</Text>
                                    <Text style={styles.field_text}>{newDealer.bank_contact}</Text>
                                </View>
                            </View>
                            <View style={styles.row}>
                                <View style={styles.column}>
                                    <Text style={styles.field_header}>Type of Business</Text>
                                    <Text style={styles.field_text}>{newDealer.bank_business_type}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={styles.rowContainer}>
                            <View style={styles.row}>
                                <View style={styles.column}>
                                    <Text style={styles.field_header}>Bank State</Text>
                                    <Text style={styles.field_text}>{newDealer.bank_state}</Text>
                                </View>
                            </View>
                            <View style={styles.row}>
                                <View style={styles.column}>
                                    <Text style={styles.field_header}>Federal ID# or SS#</Text>
                                    <Text style={styles.field_text}>{newDealer.bank_taxid}</Text>
                                    <Text style={styles.field_header}>Resale Tax #</Text>
                                    <Text style={styles.field_text}>{newDealer.bank_resale_taxid}</Text>
                                </View>
                            </View>
                        </View>
                        <Text style={styles.sectionHeader}>
                        Trade References
                        </Text>
                        <View style={styles.rowHrline} wrap={false} />
                        <Text style={styles.subHeader}>
                        Supplier #1
                        </Text>
                        <View style={styles.rowContainer}>
                            <View style={styles.row}>
                                <View style={styles.column}>
                                    <Text style={styles.field_header}>Supplier</Text>
                                    <Text style={styles.field_text}>{newDealer.supplier_name_1}</Text>
                                </View>
                            </View>
                            <View style={styles.row}>
                                <View style={styles.column}>
                                    <Text style={styles.field_header}>Phone</Text>
                                    <Text style={styles.field_text}>{newDealer.supplier_phone_1}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={styles.rowContainer}>
                            <View style={styles.row}>
                                <View style={styles.column}>
                                    <Text style={styles.field_header}>Address</Text>
                                    <Text style={styles.field_text}>{newDealer.supplier_address_1}</Text>
                                </View>
                            </View>
                            <View style={styles.row}>
                                <View style={styles.column}>
                                    <Text style={styles.field_header}>City</Text>
                                    <Text style={styles.field_text}>{newDealer.supplier_city_1}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={styles.rowContainer}>
                            <View style={styles.row}>
                                <View style={styles.column}>
                                    <Text style={styles.field_header}>State/Province</Text>
                                    <Text style={styles.field_text}>{newDealer.supplier_region_1}</Text>
                                </View>
                            </View>
                            <View style={styles.row}>
                                <View style={styles.column}>
                                    <Text style={styles.field_header}>Zip/Postal Code</Text>
                                    <Text style={styles.field_text}>{newDealer.supplier_zipcode_1}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={styles.rowContainer}>
                            <View style={styles.row}>
                                <View style={styles.column}>
                                    <Text style={styles.field_header}>Contact</Text>
                                    <Text style={styles.field_text}>{newDealer.supplier_contact_1}</Text>
                                </View>
                            </View>
                            <View style={styles.row}>
                                <View style={styles.column}>
                                    <Text style={styles.field_header}>Account #</Text>
                                    <Text style={styles.field_text}>{newDealer.supplier_account_1}</Text>
                                </View>
                            </View>
                        </View>
                        <Text style={styles.pageNumbers} render={({ pageNumber, totalPages }) => (
                            `${pageNumber} / ${totalPages}`
                        )} fixed />
            </Page>
            <Page style={styles.page}>
                <View style={styles.rowHrline} wrap={false} />
                    <Text style={styles.subHeader}>
                    Supplier #2
                    </Text>
                    <View style={styles.rowContainer}>
                        <View style={styles.row}>
                            <View style={styles.column}>
                                <Text style={styles.field_header}>Supplier</Text>
                                <Text style={styles.field_text}>{newDealer.supplier_name_2}</Text>
                            </View>
                        </View>
                        <View style={styles.row}>
                            <View style={styles.column}>
                                <Text style={styles.field_header}>Phone</Text>
                                <Text style={styles.field_text}>{newDealer.supplier_phone_2}</Text>
                            </View>
                        </View>
                    </View>
                    <View style={styles.rowContainer}>
                        <View style={styles.row}>
                            <View style={styles.column}>
                                <Text style={styles.field_header}>Address</Text>
                                <Text style={styles.field_text}>{newDealer.supplier_address_2}</Text>
                            </View>
                        </View>
                        <View style={styles.row}>
                            <View style={styles.column}>
                                <Text style={styles.field_header}>City</Text>
                                <Text style={styles.field_text}>{newDealer.supplier_city_2}</Text>
                            </View>
                        </View>
                    </View>
                    <View style={styles.rowContainer}>
                        <View style={styles.row}>
                            <View style={styles.column}>
                                <Text style={styles.field_header}>State/Province</Text>
                                <Text style={styles.field_text}>{newDealer.supplier_region_2}</Text>
                            </View>
                        </View>
                        <View style={styles.row}>
                            <View style={styles.column}>
                                <Text style={styles.field_header}>Zip/Postal Code</Text>
                                <Text style={styles.field_text}>{newDealer.supplier_zipcode_2}</Text>
                            </View>
                        </View>
                    </View>
                    <View style={styles.rowContainer}>
                        <View style={styles.row}>
                            <View style={styles.column}>
                                <Text style={styles.field_header}>Contact</Text>
                                <Text style={styles.field_text}>{newDealer.supplier_contact_2}</Text>
                            </View>
                        </View>
                        <View style={styles.row}>
                            <View style={styles.column}>
                                <Text style={styles.field_header}>Account #</Text>
                                <Text style={styles.field_text}>{newDealer.supplier_account_2}</Text>
                            </View>
                        </View>
                    </View>
                    <View style={styles.rowContainer}>
                        <View style={styles.row}>
                            <View style={styles.column}>
                                <Text style={styles.field_header}>Signature of Dealer</Text>
                                <Text style={styles.field_text}>{newDealer.dealer_signature }</Text>
                            </View>
                        </View>
                    </View>
                    <Text style={styles.pageNumbers} render={({ pageNumber, totalPages }) => (
                        `${pageNumber} / ${totalPages}`
                    )} fixed />
            </Page>
        </Document>
    );
}