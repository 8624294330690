import React, { Component } from "react";
// import CareersApproveButton from "./CareersApproveButton";
// import CareersDenyButton from "./CareersDenyButton";
import BoatShowsUpdateButton from "./BoatShowsUpdateButton";
import BoatShowsEdit from "./BoatShowsEdit";

export default class BoatShowsUpdate extends Component {

    constructor({boatShows, status}) {
        super()
        this.state = {
            status: status,
            boatShows: boatShows,
            isEmptyState: true
        }
    }

    triggerEditWarrantyState = () => {
        this.setState({
          ...this.state,
          isEmptyState: false,
          isEditWarrantyState: true
        })
    }

    closeWarrantyState = () => {
        this.setState({
          ...this.state,
          isEmptyState: true,
          isEditWarrantyState: false
        })
    }

    render() {
        return (
            <div style={{textAlign: "center", padding: 10}}>
                {this.state.isEmptyState && <BoatShowsUpdateButton id={this.state.boatShows.id} addClick={this.triggerEditWarrantyState} />}
                {this.state.isEditWarrantyState && <BoatShowsEdit boatShows={this.state.boatShows} addClick={this.triggerEditWarrantyState} closeEditForm={this.closeWarrantyState} />}
            </div>
        );
    }

}
