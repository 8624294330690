import React, { useState } from "react";
import { Mutation, Subscription } from "react-apollo";
import { gql } from "apollo-boost";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import { Query } from "react-apollo";
import { toast } from "react-toastify";
import { CircularProgress } from "@material-ui/core";
import useForm from "components/useForm";
import { CountryRegionData } from "react-country-region-selector";
import {
  Paper,
  Typography,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  FormLabel,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { RowDetailState } from "@devexpress/dx-react-grid";
import {
  Table,
  TableHeaderRow,
  TableRowDetail,
  Grid as Grids,
} from "@devexpress/dx-react-grid-material-ui";
// import { RequestRowDetail } from "../NewRequest/MediaPreApprovalFormRequestRowDetails";

// Shared layouts
import { Dashboard as DashboardLayout } from "layouts";
// Component styles
import styles from "theme/skin";
import style2 from "theme/typography";
import auth from "Auth";
import { Input } from "rbx";
import {
  ADD_CO_OP_REQUEST_FORMS,
  ADD_SPECIAL_PROJECT_FORMS,
  getAllSpecialProjectForms,
} from "./queries";
import { SpecialProjectFormRequestRowDetail } from "../NewRequest/SpecialProjectFormRequestRowDetail";
import moment from "moment";
import { StatusEnum } from "pages/FormsDatasheets/enum";
import { GET_USER_INFO } from "queries";

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});
const getRegions = (country) => {
  if (!country) {
    return [];
  }
  //console.log("regions");
  return country[2].split("|").map((regionPair) => {
    let [regionName, regionShortCode = null] = regionPair.split("~");
    return regionName;
  });
};
function SpecialProjectForms(props) {
  const { classes } = props;

  const { values, resetState, setValues } = useForm(submitForm);

  function submitForm() {
    //console.log(values);
  }

  const [expandedRowIds, setExpandedRowIds] = useState([0]);
  const [queryData, setQueryData] = useState();
  const [userData, setUserData] = useState();

  const formattedUTCDate = moment.utc();

  const columns = [
    { name: "date_submitted", title: "Date Submitted" },
    { name: "form", title: "Form", getCellValue: (row) => "Special Project" },
    { name: "status", title: "status" },
  ];

  const LastColumnCell = ({ column, value }) => (
    <Table.Cell
      style={
        column.name === "status"
          ? {
              color: StatusEnum[value.toLowerCase()]?.color,
              fontWeight: "bold",
            }
          : {}
      }
    >
      {column.name === "status"
        ? StatusEnum[value.toLowerCase()]?.label
        : value}
    </Table.Cell>
  );

  const byPropKey = (propertyName, value) => () => ({
    [propertyName]: value,
  });

  const completedAdd = (payload) => {
    toast("Special project created successfully");
    resetState();
  };

  const toggleRowExpand = (rowId) => {
    setExpandedRowIds((prevState) =>
      prevState.includes(rowId)
        ? prevState.filter((id) => id !== rowId)
        : [...prevState, rowId]
    );
  };

  const TableRow = ({ tableRow, ...restProps }) => (
    <Table.Row
      {...restProps}
      onClick={() => toggleRowExpand(tableRow.rowId)}
      style={{ cursor: "pointer" }}
    />
  );

  const RowDetail = ({ row }) => {
    if (queryData) {
      const rowData = queryData?.find((rows) => rows?.id === row?.id);
      return (
        <div>
          <SpecialProjectFormRequestRowDetail
            isDisplay={true}
            comments={rowData?.co_op_request?.co_op_comments}
            userName={userData?.profiles_by_pk?.name}
            isEdit={true}
            row={rowData}
            type="dealer"
          />
        </div>
      );
    }
    return <div>Row details not available.</div>;
  };

  const evaluateBoatOptions = (data) => {
    if (data?.profiles_by_pk) {
      const { has_skiff, has_seachaser, has_funchaser } = data?.profiles_by_pk;
      return has_skiff + has_seachaser + has_funchaser;
    }
  };

  const handleChange = (event) => {
    event.persist();
    setValues((values) => {
      const updatedValues = {
        ...values,
        [event.target.name]: event.target.value,
      };

      if (
        event.target.name === "total_cost" ||
        event.target.name === "devoted"
      ) {
        const totalCost =
          event.target.name === "total_cost"
            ? parseFloat(event.target.value)
            : parseFloat(updatedValues.total_cost || 0);
        const devoted =
          event.target.name === "devoted"
            ? parseFloat(event.target.value)
            : parseFloat(updatedValues.devoted || 0);

        if (!isNaN(totalCost) && !isNaN(devoted)) {
          updatedValues.total_credit = (
            (parseFloat(values?.total_cost || 0, 2) *
              parseFloat(values?.devoted || 0, 2)) /
            100
          ).toFixed(2);
          updatedValues.cost_devoted = (
            (parseFloat(values?.total_cost || 0, 2) *
              parseFloat(values?.devoted || 0, 2)) /
            100 /
            evaluateBoatOptions(userData)
          ).toFixed(2);
        }
      }

      return updatedValues;
    });
  };

  return (
    <Query
      query={GET_USER_INFO}
      variables={{ id: auth.getSub() }}
      onCompleted={(data) => setUserData(data)}
    >
      {({ data: userData, loading: userLoading, error }) => {
        return (
          <Mutation mutation={ADD_CO_OP_REQUEST_FORMS}>
            {(addCoOpRequestForms, { data, loading, called, error }) => {
              if (error)
                return (
                  <pre>
                    Bad:{" "}
                    {error.graphQLErrors.map(({ message }, i) => (
                      <span key={i}>{message}</span>
                    ))}
                  </pre>
                );
              return (
                <Mutation
                  mutation={ADD_SPECIAL_PROJECT_FORMS}
                  onCompleted={completedAdd}
                >
                  {(
                    addNewSpecialProjectForm,
                    { data, loading, called, error }
                  ) => {
                    if (error)
                      return (
                        <pre>
                          Bad:{" "}
                          {error.graphQLErrors.map(({ message }, i) => (
                            <span key={i}>{message}</span>
                          ))}
                        </pre>
                      );
                    return (
                      <DashboardLayout>
                        <form
                          onSubmit={(e) => {
                            values.profile_id = auth.getSub();
                            values.country = values.country;
                            e.preventDefault();
                            const requestData = {
                              date_submitted: formattedUTCDate.format(),
                              profile_id: auth.getSub(),
                              form_type: "special-project-request",
                              status: "new",
                            };
                            return addCoOpRequestForms({
                              variables: {
                                objects: requestData,
                              },
                            }).then((value) => {
                              const co_op_requests_id =
                                value?.data?.insert_co_op_requests
                                  ?.returning?.[0]?.id;

                              const finalValues = {
                                ...values,
                                co_op_requests_id,
                              };
                              addNewSpecialProjectForm({
                                variables: {
                                  objects: [finalValues],
                                },
                              });
                              return false;
                            });
                          }}
                          autoComplete="off"
                        >
                          <div className={classes.root + " sectionContainer"}>
                            <Typography
                              gutterBottom
                              variant="h4"
                              className="title"
                            >
                              <span>SPECIAL PROJECT FORMS</span>
                            </Typography>
                            <Typography
                              gutterBottom
                              variant="h5"
                              className={classes.subtitle3}
                              align="left"
                            >
                              FORMS WAITING FOR APPROVAL
                            </Typography>
                            <Subscription
                              subscription={getAllSpecialProjectForms}
                              variables={{ profile_id: auth.getSub() }}
                            >
                              {({ data, loading, error }) => {
                                if (data && data !== queryData) {
                                  setQueryData(data?.special_project_forms);
                                }
                                if (loading || !queryData)
                                  return (
                                    <div
                                      className="flex center"
                                      style={{ padding: "2em 0" }}
                                    >
                                      <CircularProgress color="secondary" />
                                    </div>
                                  );
                                if (error)
                                  return (
                                    <p>Error Loading Special Project Forms</p>
                                  );
                                return (
                                  <div className="custom-table-event">
                                    <Grids
                                      rows={data.special_project_forms}
                                      columns={columns}
                                    >
                                      <RowDetailState
                                        expandedRowIds={expandedRowIds}
                                      />
                                      <Table
                                        cellComponent={(props) => (
                                          <LastColumnCell {...props} />
                                        )}
                                        rowComponent={TableRow}
                                      />
                                      <TableRowDetail
                                        contentComponent={RowDetail}
                                      />
                                      <TableHeaderRow />
                                    </Grids>
                                  </div>
                                );
                              }}
                            </Subscription>
                            <br></br>
                            <br></br>
                            <Typography
                              gutterBottom
                              variant="h5"
                              className={classes.subtitle3}
                              style={{
                                backgroundColor: "#BED4E5",
                                textAlign: "left",
                                padding: "1em",
                                color: "#23333F",
                                margin: "0px",
                              }}
                            >
                              SPECIAL PROJECT FORM
                            </Typography>
                            <Paper
                              className={classes.paper}
                              style={{
                                padding: "2em",
                                backgroundColor: "#F9FCFF",
                                boxShadow: "unset",
                              }}
                            >
                              <Typography
                                gutterBottom
                                variant="h5"
                                align="center"
                                className={classes.subTitle2}
                                style={{
                                  margin: "0px auto 15px",
                                  maxWidth: "80%",
                                }}
                              >
                                <strong>CAROLINA SKIFF:</strong> 3231 Fulford
                                Road Waycross Georgia 31503{" "}
                                <strong>PHONE:</strong> 912-287-0547{" "}
                                <strong>FAX:</strong> 912-287-0533
                              </Typography>
                              <Typography
                                gutterBottom
                                variant="h6"
                                align="center"
                                style={{ margin: "0px auto", maxWidth: "80%" }}
                                className={classes.subTitle2}
                              >
                                All required paper work must be submitted with
                                this form.
                                <br></br>
                                For qualifications and requirements of the Co-op
                                advertising program, please check your Dealer
                                Manual under the Marketing Guide section.
                              </Typography>
                              <br></br>
                              <br></br>
                              <div>
                                <Grid container spacing={4}>
                                  <Grid item xs={12} sm={4}>
                                    <TextField
                                      required
                                      name="dealer_name"
                                      label="Dealer Name"
                                      className={classes.textField}
                                      style={{ width: "100%" }}
                                      onChange={handleChange}
                                      margin="normal"
                                      value={values?.dealer_name || ""}
                                      variant="outlined"
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={4}>
                                    <TextField
                                      required
                                      name="contact_name"
                                      label="Contact Name"
                                      className={classes.textField}
                                      style={{ width: "100%" }}
                                      onChange={handleChange}
                                      margin="normal"
                                      value={values?.contact_name || ""}
                                      variant="outlined"
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={4}>
                                    <TextField
                                      required
                                      name="phone"
                                      label="Phone"
                                      className={classes.textField}
                                      style={{ width: "100%" }}
                                      onChange={handleChange}
                                      margin="normal"
                                      value={values?.phone || ""}
                                      variant="outlined"
                                    />
                                  </Grid>
                                </Grid>

                                <Grid container spacing={4}>
                                  <Grid item xs={12} sm={4}>
                                    <TextField
                                      required
                                      name="fax"
                                      label="Fax"
                                      className={classes.textField}
                                      style={{ width: "100%" }}
                                      onChange={handleChange}
                                      margin="normal"
                                      value={values?.fax || ""}
                                      variant="outlined"
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={4}>
                                    <TextField
                                      required
                                      name="address"
                                      label="Address"
                                      className={classes.textField}
                                      style={{ width: "100%" }}
                                      onChange={handleChange}
                                      margin="normal"
                                      value={values?.address || ""}
                                      variant="outlined"
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={4}>
                                    <TextField
                                      name="address2"
                                      label="Address line 2 (Suites, Apt. # etc)"
                                      className={classes.textField}
                                      onChange={handleChange}
                                      margin="normal"
                                      variant="outlined"
                                      value={values?.address2 || ""}
                                      style={{ width: "100%" }}
                                    />
                                  </Grid>
                                </Grid>

                                <Grid container spacing={4}>
                                  <Grid item xs={12} sm={4}>
                                    <TextField
                                      required
                                      name="city"
                                      label="City"
                                      className={classes.textField}
                                      onChange={handleChange}
                                      margin="normal"
                                      variant="outlined"
                                      value={values?.city || ""}
                                      style={{ width: "100%" }}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={4}>
                                    <TextField
                                      required
                                      name="country"
                                      label="Country"
                                      select
                                      className={classes.textField}
                                      onChange={handleChange}
                                      value={values?.country || ""}
                                      style={{ width: "100%" }}
                                      variant="outlined"
                                      SelectProps={{
                                        native: true,
                                        MenuProps: {
                                          className: classes.menu,
                                        },
                                      }}
                                      margin="normal"
                                    >
                                      <option key="" value="" />
                                      <option
                                        key="United States"
                                        value="United States"
                                      >
                                        United States
                                      </option>
                                    </TextField>
                                  </Grid>
                                  <Grid item xs={12} sm={4}>
                                    <TextField
                                      required
                                      name="state"
                                      label="State"
                                      variant="outlined"
                                      select
                                      style={{ width: "100%" }}
                                      className={classes.textField}
                                      value={values?.state || ""}
                                      onChange={handleChange}
                                      SelectProps={{
                                        native: true,
                                        MenuProps: {
                                          className: classes.menu,
                                        },
                                      }}
                                      margin="normal"
                                    >
                                      <option key="" value="" />
                                      <option key="Alabama" value="Alabama">
                                        Alabama
                                      </option>
                                      <option key="Alaska" value="Alaska">
                                        Alaska
                                      </option>
                                      <option key="Arizona" value="Arizona">
                                        Arizona
                                      </option>
                                      <option key="Arkansas" value="Arkansas">
                                        Arkansas
                                      </option>
                                      <option
                                        key="California"
                                        value="California"
                                      >
                                        California
                                      </option>
                                      <option key="Colorado" value="Colorado">
                                        Colorado
                                      </option>
                                      <option
                                        key="Connecticut"
                                        value="Connecticut"
                                      >
                                        Connecticut
                                      </option>
                                      <option key="Delaware" value="Delaware">
                                        Delaware
                                      </option>
                                      <option key="Florida" value="Florida">
                                        Florida
                                      </option>
                                      <option key="Georgia" value="Georgia">
                                        Georgia
                                      </option>
                                      <option key="Hawaii" value="Hawaii">
                                        Hawaii
                                      </option>
                                      <option key="Idaho" value="Idaho">
                                        Idaho
                                      </option>
                                      <option key="Illinois" value="Illinois">
                                        Illinois
                                      </option>
                                      <option key="Indiana" value="Indiana">
                                        Indiana
                                      </option>
                                      <option key="Iowa" value="Iowa">
                                        Iowa
                                      </option>
                                      <option key="Kansas" value="Kansas">
                                        Kansas
                                      </option>
                                      <option key="Kentucky" value="Kentucky">
                                        Kentucky
                                      </option>
                                      <option key="Louisiana" value="Louisiana">
                                        Louisiana
                                      </option>
                                      <option key="Maine" value="Maine">
                                        Maine
                                      </option>
                                      <option key="Maryland" value="Maryland">
                                        Maryland
                                      </option>
                                      <option
                                        key="Massachusetts"
                                        value="Massachusetts"
                                      >
                                        Massachusetts
                                      </option>
                                      <option key="Michigan" value="Michigan">
                                        Michigan
                                      </option>
                                      <option key="Minnesota" value="Minnesota">
                                        Minnesota
                                      </option>
                                      <option
                                        key="Mississippi"
                                        value="Mississippi"
                                      >
                                        Mississippi
                                      </option>
                                      <option key="Missouri" value="Missouri">
                                        Missouri
                                      </option>
                                      <option key="Montana" value="Montana">
                                        Montana
                                      </option>
                                      <option key="Nebraska" value="Nebraska">
                                        Nebraska
                                      </option>
                                      <option key="Nevada" value="Nevada">
                                        Nevada
                                      </option>
                                      <option
                                        key="New Hampshire"
                                        value="New Hampshire"
                                      >
                                        New Hampshire
                                      </option>
                                      <option
                                        key="New Jersey"
                                        value="New Jersey"
                                      >
                                        New Jersey
                                      </option>
                                      <option
                                        key="New Mexico"
                                        value="New Mexico"
                                      >
                                        New Mexico
                                      </option>
                                      <option key="New York" value="New York">
                                        New York
                                      </option>
                                      <option
                                        key="North Carolina"
                                        value="North Carolina"
                                      >
                                        North Carolina
                                      </option>
                                      <option
                                        key="North Dakota"
                                        value="North Dakota"
                                      >
                                        North Dakota
                                      </option>
                                      <option key="Ohio" value="Ohio">
                                        Ohio
                                      </option>
                                      <option key="Oklahoma" value="Oklahoma">
                                        Oklahoma
                                      </option>
                                      <option key="Oregon" value="Oregon">
                                        Oregon
                                      </option>
                                      <option
                                        key="Pennsylvania"
                                        value="Pennsylvania"
                                      >
                                        Pennsylvania
                                      </option>
                                      <option
                                        key="Rhode Island"
                                        value="Rhode Island"
                                      >
                                        Rhode Island
                                      </option>
                                      <option
                                        key="South Carolina"
                                        value="South Carolina"
                                      >
                                        South Carolina
                                      </option>
                                      <option
                                        key="South Dakota"
                                        value="South Dakota"
                                      >
                                        South Dakota
                                      </option>
                                      <option key="Tennessee" value="Tennessee">
                                        Tennessee
                                      </option>
                                      <option key="Texas" value="Texas">
                                        Texas
                                      </option>
                                      <option key="Utah" value="Utah">
                                        Utah
                                      </option>
                                      <option key="Vermont" value="Vermont">
                                        Vermont
                                      </option>
                                      <option key="Virginia" value="Virginia">
                                        Virginia
                                      </option>
                                      <option
                                        key="Washington"
                                        value="Washington"
                                      >
                                        Washington
                                      </option>
                                      <option
                                        key="West Virginia"
                                        value="West Virginia"
                                      >
                                        West Virginia
                                      </option>
                                      <option key="Wisconsin" value="Wisconsin">
                                        Wisconsin
                                      </option>
                                      <option key="Wyoming" value="Wyoming">
                                        Wyoming
                                      </option>
                                    </TextField>
                                  </Grid>
                                </Grid>
                                <Grid container spacing={4}>
                                  <Grid item xs={12} sm={4}>
                                    <TextField
                                      name="zipcode"
                                      label="Zip / Postal Code"
                                      className={classes.textField}
                                      onChange={handleChange}
                                      margin="normal"
                                      variant="outlined"
                                      style={{ width: "100%" }}
                                      value={values?.zipcode || ""}
                                    />
                                  </Grid>
                                  {/* <Grid item xs={12} sm={4}>
                                    <Typography
                                      gutterBottom
                                      variant="h6"
                                      align="left"
                                      className={classes.subTitle2}
                                      style={{
                                        width: "100%",
                                        textAlign: "left",
                                        marginTop: "25px",
                                      }}
                                    >
                                      DATE: 3/25/2020{" "}
                                    </Typography>
                                  </Grid> */}
                                </Grid>
                              </div>
                              <br></br>
                              <br></br>

                              <div>
                                <Typography
                                  gutterBottom
                                  variant="h6"
                                  align="center"
                                  style={{
                                    maxWidth: "80%",
                                    margin: "0px auto 15px",
                                  }}
                                  className={classes.subTitle2}
                                >
                                  <b>SPECIAL PROJECT PROGRAM</b>
                                </Typography>
                                <Typography
                                  gutterBottom
                                  variant="h6"
                                  align="center"
                                  style={{
                                    maxWidth: "80%",
                                    margin: "0px auto 10px",
                                  }}
                                  className={classes.subTitle2}
                                >
                                  Carolina Skiff will credit up to 50% of the
                                  total project amount for special Advertising
                                  or Marketing Projects:
                                </Typography>
                                <Typography
                                  gutterBottom
                                  variant="h6"
                                  align="center"
                                  style={{
                                    maxWidth: "80%",
                                    margin: "0px auto",
                                  }}
                                  className={classes.subTitle2}
                                >
                                  banners, catalogs, direct mail, clothing, boat
                                  giveaway, live radio, live TV, and any other
                                  type of promotional materials designed to
                                  promote the Dealership and Carolina Skiff
                                  LLC..
                                  <br></br>
                                  All special projects must be pre-approved by
                                  the Carolina Skiff Advertising Department. No
                                  competitive brand my be used or shown in any
                                  way for special projects to qualify.
                                  <br></br>
                                  All Special projects credits will be applied
                                  againist the accrued allowance.
                                </Typography>
                                <br></br>
                                <br></br>
                                <Grid container>
                                  <Grid item xs={12} sm={12}>
                                    <TextField
                                      name="description"
                                      label="Description of Project"
                                      multiline
                                      rows="6"
                                      variant="outlined"
                                      className={classes.textField}
                                      onChange={handleChange}
                                      margin="normal"
                                      value={values?.description || ""}
                                      style={{ width: "100%" }}
                                    />
                                  </Grid>
                                </Grid>
                                <br></br>
                                <br></br>
                                <Grid
                                  container
                                  justify="space-around"
                                  spacing={4}
                                >
                                  <Grid item xs={12} sm={4}>
                                    <FormControl
                                      sx={{ m: 1, width: "25ch" }}
                                      variant="outlined"
                                    >
                                      <FormLabel
                                        variant="h5"
                                        align="center"
                                        className={classes.subTitle2}
                                        style={{
                                          width: "100%",
                                          marginBottom: "10px",
                                        }}
                                      >
                                        <b>TOTAL COST OF PROJECT</b>
                                      </FormLabel>
                                      <OutlinedInput
                                        id="outlined-adornment-weight"
                                        name="total_cost"
                                        onChange={handleChange}
                                        value={values.total_cost || ""}
                                        endAdornment={
                                          <InputAdornment position="end">
                                            {" "}
                                          </InputAdornment>
                                        }
                                        style={{ width: "100%" }}
                                        aria-describedby="outlined-weight-helper-text"
                                        startAdornment={
                                          <InputAdornment position="start">
                                            $
                                          </InputAdornment>
                                        }
                                        inputProps={{
                                          "aria-label": "weight",
                                        }}
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} sm={4}>
                                    <FormControl
                                      sx={{ m: 1, width: "25ch" }}
                                      variant="outlined"
                                    >
                                      <FormLabel
                                        variant="h5"
                                        align="center"
                                        className={classes.subTitle2}
                                        style={{
                                          width: "100%",
                                          marginBottom: "10px",
                                        }}
                                      >
                                        <b>% DEVOTED</b>
                                      </FormLabel>
                                      <OutlinedInput
                                        id="outlined-adornment-weight"
                                        name="devoted"
                                        value={values.devoted || ""}
                                        onChange={handleChange}
                                        endAdornment={
                                          <InputAdornment position="end">
                                            {" "}
                                          </InputAdornment>
                                        }
                                        style={{ width: "100%" }}
                                        aria-describedby="outlined-weight-helper-text"
                                        inputProps={{
                                          "aria-label": "weight",
                                        }}
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} sm={4}>
                                    <FormControl
                                      sx={{ m: 1, width: "25ch" }}
                                      variant="outlined"
                                    >
                                      <FormLabel
                                        variant="h5"
                                        align="center"
                                        className={classes.subTitle2}
                                        style={{
                                          width: "100%",
                                          marginBottom: "10px",
                                        }}
                                      >
                                        <b>COST OF DEVOTED %</b>
                                      </FormLabel>
                                      <OutlinedInput
                                        id="outlined-adornment-weight"
                                        name="cost_devoted"
                                        disabled
                                        value={(
                                          (parseFloat(
                                            values?.total_cost || 0,
                                            2
                                          ) *
                                            parseFloat(
                                              values?.devoted || 0,
                                              2
                                            )) /
                                          100 /
                                          evaluateBoatOptions(userData)
                                        ).toFixed(2)}
                                        onChange={handleChange}
                                        endAdornment={
                                          <InputAdornment position="end">
                                            {" "}
                                          </InputAdornment>
                                        }
                                        style={{ width: "100%" }}
                                        aria-describedby="outlined-weight-helper-text"
                                        inputProps={{
                                          "aria-label": "weight",
                                        }}
                                      />
                                    </FormControl>
                                  </Grid>
                                </Grid>
                                <br></br>
                                <Grid container justify="flex-end" spacing={4}>
                                  <Grid item xs={12} sm={4}>
                                    <FormControl
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                      }}
                                      variant="outlined"
                                    >
                                      {/* <b variant="h5" align="center" className={classes.subTitle2}  style={{width:"100%",marginBottom:"10px"}}>TOTAL COST OF PROJECT</b> */}
                                      <FormLabel className={classes.subTitle2}>
                                        <b>Total Credit $</b>{" "}
                                      </FormLabel>
                                      <OutlinedInput
                                        id="outlined-adornment-weight"
                                        name="total_credit"
                                        disabled
                                        value={(
                                          (parseFloat(
                                            values?.total_cost || 0,
                                            2
                                          ) *
                                            parseFloat(
                                              values?.devoted || 0,
                                              2
                                            )) /
                                          100
                                        ).toFixed(2)}
                                        onChange={handleChange}
                                        endAdornment={
                                          <InputAdornment position="end">
                                            {" "}
                                          </InputAdornment>
                                        }
                                        style={{
                                          width: "80%",
                                          maxWidth: "calc(100% - 100px)",
                                          marginLeft: "6px",
                                        }}
                                        aria-describedby="outlined-weight-helper-text"
                                        inputProps={{
                                          "aria-label": "weight",
                                        }}
                                      />
                                    </FormControl>
                                  </Grid>
                                </Grid>
                              </div>
                              <br></br>
                              <br></br>
                              <div>
                                <Grid container>
                                  <Grid item xs={12} sm={12}>
                                    <TextField
                                      name="additional_info"
                                      label="Additional information. (OPTIONAL)"
                                      multiline
                                      rows="4"
                                      variant="outlined"
                                      className={classes.textField}
                                      onChange={handleChange}
                                      margin="normal"
                                      value={values?.additional_info || ""}
                                      style={{ width: "100%" }}
                                    />
                                  </Grid>
                                </Grid>
                              </div>

                              <Grid item xs={12} align="center">
                                {/* {loading && <p>Loading...</p>}
                            {error && (
                            <p>
                                Warranty Registration Could Not be Submitted. Please Try
                                again.
                            </p>
                            )} */}
                                <Button
                                  color="primary"
                                  variant="contained"
                                  className={classes.button}
                                  type="submit"
                                  style={{ width: "15em", margin: "2em 1em" }}
                                >
                                  SUBMIT
                                </Button>
                              </Grid>
                            </Paper>
                          </div>
                        </form>
                      </DashboardLayout>
                    );
                  }}
                </Mutation>
              );
            }}
          </Mutation>
        );
      }}
    </Query>
  );
}

export default withStyles(styles)(SpecialProjectForms);
