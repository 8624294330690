import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import { saveAs } from "file-saver";

const styles = {
  card: {
    maxWidth: "210px",
    width: "200px",
    marginRight: 25,
    marginBottom: 25,
    justifyContent: "end"
  },
  media: {
    height: "100px",
    width: "90%",
    margin: "0 auto"
  }
};

const APP_SERVER = "https://carolina-skiff-dealernet.s3.amazonaws.com/warranty_claims/";

function getFileName(url) {
  return url.substring(url.lastIndexOf("/") + 1);
}

function downloadImageHandler(imagelink,profileid) {
  //console.log("image Download has been called");
  var imageURL = APP_SERVER + profileid + "/" + imagelink;
  saveAs(imageURL, getFileName(imageURL));
}

function WarrantyClaimMediaItem(props) {
  const { classes } = props;
  const { item } = props;
  const { profileid } = props;

  if (item.media_type === "") item.media_type = "image";

  return (
    <Card className={classes.card}>
      <CardActionArea>
        <CardMedia
          component={item.media_type}
          className={classes.media}
          image={APP_SERVER + profileid + "/" + item}
          title={item.name}
        />
      </CardActionArea>
      <CardActions className="flex center">
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={() => downloadImageHandler(item, profileid)}
          style={{ width: "100px" }}
        >
          Download
        </Button>
      </CardActions>
    </Card>
  );
}

WarrantyClaimMediaItem.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(WarrantyClaimMediaItem);
