import React, { Component } from "react";
import {Mutation, Query} from "react-apollo";
import {default as GridUi} from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableRowDetail,
  PagingPanel,
  SearchPanel,
  Toolbar
} from "@devexpress/dx-react-grid-material-ui";
import {
  RowDetailState,
  PagingState,
  SearchState,
  IntegratedPaging,
  IntegratedFiltering
} from "@devexpress/dx-react-grid";
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import {
  Typography,
  withStyles,
  TextField,
  Select,
  Button,
  Dialog,
  DialogContent,
  DialogActions
} from "@material-ui/core";
import PropTypes from "prop-types";
import { gql } from "apollo-boost";
import WarrantyRegistrationTransfer from './WarrantyRegistrationTransfer';
import { PDFDownloadLink } from "@react-pdf/renderer";
import { WarrantyRegistrationPdfDocument } from "./WarrantyRegistrationPdfDocument";
import {SOFT_DELETE_WARRANTY_REGISTRATION} from "../../../queries";

export const getById = gql`
    query getCurrentOwner($id: uuid!) {
        warranty_registration(
            where: { status: { _eq: "New" }, id: { _eq: $id } }
        ) {
            id
            profile_id
            admin_name
            admin_email
            hull_identification_number
            purchase_date
            brand
            model
            first_name
            last_name
            phone
            email
            address
            address2
            city
            region
            country
            zipcode
            motor_details
            accessories
            primary_use
            submittedon
            status
            warranty_id
            comments
            model_id
            boat_model {
                older_model
            }
        }
    }
`

export const getPreviousOwners = gql`
    query getPreviousOwners($hull_identification_number: String!) {
        warranty_registration(
            where: { status: { _eq: "Archived" }, hull_identification_number: { _eq: $hull_identification_number } }
        ) {
            id
            profile_id
            hull_identification_number
            first_name
            last_name
            address
            address2
            city
            region
            country
            zipcode
            email
            phone
            purchase_date
            primary_use
            submittedon
            status
            warranty_id
            comments
        }
    }
`
const styles = theme => ({
  button: {
    margin: theme.spacing()
  },
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: 275
  },
  navBar: {
      display : 'flex',
      flexWrap: 'no-wrap',
  }
})

const customStyles = {
  deleteBtn: {
    float: 'right',
    margin: 8,
    width: "205px",
    color: '#fff',
    backgroundColor: '#dc3545',
    borderColor: '#dc3545'
  },
  confirmDialogCancelContainer: {
    marginRight: 'auto'
  }
}

class RowDetail extends React.PureComponent {
  constructor(props, refreshWarrantyRegistrations) {
    super(props);
    this.state = {
      dialogOpen: false
    };
    this.refreshWarrantyRegistrations = refreshWarrantyRegistrations;
  }
  
  render() {
    return <div>
      <GridUi container spacing={3}>
        <GridUi item xs={9} sm={9}>
          <PDFDownloadLink
              document={<WarrantyRegistrationPdfDocument data={this.props.row}/>}
              fileName={"warranty_registration_" + this.props.row.warranty_id + "_" + this.props.row.first_name + "_" + this.props.row.last_name + ".pdf"}
              color="secondary"
              className="MuiButtonBase-root MuiButton-root MuiButton-containedSecondary MuiButton-contained"
          >
            {({blob, url, loading, error}) =>
                loading ? "Loading document..." : "Print To Pdf"
            }
          </PDFDownloadLink>
        </GridUi>
        <GridUi item xs={3} sm={3}>
          <Button
              variant="contained"
              style={customStyles.deleteBtn}
              onClick={e => this.setState({dialogOpen: true})}
          >Delete Warranty</Button>
          <Dialog open={this.state.dialogOpen}>
            <DialogContent dividers>
              <p>Are you sure you would like to delete the warranty registration
                for <strong>{this.props.row.first_name} {this.props.row.last_name} - {this.props.row.email}</strong>?</p>
            </DialogContent>
            <DialogActions>
              <div style={customStyles.confirmDialogCancelContainer}>
                <Button autoFocus
                        variant="contained"
                        color="default"
                        onClick={() => this.setState({dialogOpen: false})}
                >Cancel</Button>
              </div>
              <Mutation
                  mutation={SOFT_DELETE_WARRANTY_REGISTRATION}
                  onCompleted={() =>
                  {
                    this.setState({dialogOpen: false});
                    this.refreshWarrantyRegistrations();
                  }}
              >
                {(inactivateWarrantyRegistrationRequest, { loading, error }) => {
                  return (
                      <div>
                        {loading && <p>Loading...</p>}
                        {error && <p>Error :( Please try again</p>}
                        {!loading && !error && <Button
                            variant="contained"
                            color="secondary"
                            style={styles.button}
                            onClick={() => {
                              inactivateWarrantyRegistrationRequest({
                                variables: {
                                  id: this.props.row.id
                                }
                              });
                            }}
                        >Yes</Button>}
                      </div>
                  );
                }}
              </Mutation>
            </DialogActions>
          </Dialog>
        </GridUi>
      </GridUi>
      <table cellSpacing="0" className="detailTable">
        <tbody>
        <tr>
          <td>Purchase Date:</td>
          <td>{this.props.row.purchase_date ? this.props.row.purchase_date : "-"}</td>
        </tr>
        <tr>
          <td>Admin Name:</td>
          <td>{this.props.row.admin_name ? this.props.row.admin_name : "-"}</td>
        </tr>
        <tr>
          <td>Admin Email:</td>
          <td>{this.props.row.admin_email ? this.props.row.admin_email : "-"}</td>
        </tr>
        <tr>
          <td>HIN:</td>
          <td>
            {this.props.row.hull_identification_number
                ? this.props.row.hull_identification_number
                : "-"}
          </td>
        </tr>
        <tr>
          <td>Brand:</td>
          <td>{this.props.row.brand ? this.props.row.brand : "-"}</td>
        </tr>
        <tr>
          <td>Model:</td>
          <td>{this.props.row.model ? this.props.row.model : "-"}</td>
        </tr>
        <tr>
          <td>Older Model:</td>
          <td>{this.props.row.boat_model ? (this.props.row.boat_model.older_model ? "Yes" : "No") : "-"}</td>
        </tr>
        <tr>
          <td>Motor Details:</td>
          <td>{this.props.row.motor_details ? this.props.row.motor_details : "-"}</td>
        </tr>
        <tr>
          <td>Accessories:</td>
          <td>{this.props.row.accessories ? this.props.row.accessories : "-"}</td>
        </tr>
        <tr>
          <td>Primary Use:</td>
          <td>{this.props.row.primary_use ? this.props.row.primary_use : "-"}</td>
        </tr>
        <tr>
          <td>First Name</td>
          <td>{this.props.row.first_name ? this.props.row.first_name : "-"}</td>
        </tr>
        <tr>
          <td>Last Name</td>
          <td>{this.props.row.last_name ? this.props.row.last_name : "-"}</td>
        </tr>
        <tr>
          <td>Phone</td>
          <td>{this.props.row.phone ? this.props.row.phone : "-"}</td>
        </tr>
        <tr>
          <td>Email</td>
          <td>{this.props.row.email ? this.props.row.email : "-"}</td>
        </tr>
        <tr>
          <td>Submitted On:</td>
          <td>{this.props.row.submittedon ? this.props.row.submittedon : "-"}</td>
        </tr>
        <tr>
          <td>Status:</td>
          <td>{this.props.row.status ? this.props.row.status : "-"}</td>
        </tr>
        <tr>
          <td>Address:</td>
          <td>{this.props.row.address ? this.props.row.address : "-"}</td>
        </tr>
        <tr>
          <td>Address2:</td>
          <td>{this.props.row.address2 ? this.props.row.address2 : "-"}</td>
        </tr>
        <tr>
          <td>City:</td>
          <td>{this.props.row.city ? this.props.row.city : "-"}</td>
        </tr>
        <tr>
          <td>State:</td>
          <td>{this.props.row.region}</td>
        </tr>
        <tr>
          <td>Country:</td>
          <td>{this.props.row.country ? this.props.row.country : "-"}</td>
        </tr>
        <tr>
          <td>Zipcode:</td>
          <td>{this.props.row.zipcode ? this.props.row.zipcode : "-"}</td>
        </tr>
        <tr>
          <td>Comments:</td>
          <td>{this.props.row.comments ? this.props.row.comments : "-"}</td>
        </tr>
        </tbody>
      </table>
    </div>
  }
}

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

class WarrantyRegistrationTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentOwnerColumns: [
        { name: "name", title: "Name" , getCellValue: row => (row.first_name + row.last_name ? row.first_name + ' ' + row.last_name : undefined),},
        { name: "email", title: "Email" },
        { name: "phone", title: "Phone" },
        { name: 'address', title: 'Location' , getCellValue: row => (row.address + row.city + row.region + row.country ? row.address + ' ' + row.city + ' ' + row.region + ' ' + row.country : undefined),},
        { name: "purchase_date", title: "Purchase Date" }
      ],
      previousOwnersColumns: [
        { name: "name", title: "Name" , getCellValue: row => (row.first_name + row.last_name ? row.first_name + ' ' + row.last_name : undefined),},
        { name: "email", title: "Email" },
        { name: 'address', title: 'Location' , getCellValue: row => (row.address + row.city + row.region + row.country ? row.address + ' ' + row.city + ' ' + row.region + ' ' + row.country : undefined),},
        { name: "status", title: "Status" },
        { name: "purchase_date", title: "Purchase Date" }
      ],
      activeTabIndex: 0,
    };
  }

  handleTabChange = (event, value) => {
      this.setState({ activeTabIndex: value });
  };

  rowDetailFactory(innerProps) {
    return new RowDetail(innerProps, this.props.refreshWarrantyRegistrations);
  }

  render() {
     const { classes } = this.props;
     const {
       currentOwnerColumns,
       previousOwnersColumns,
       activeTabIndex
     } = this.state;
    return (
      <div>
        <div className={classes.root}>
          <AppBar position="static">
            <Tabs value={activeTabIndex} onChange={this.handleTabChange}>
              <Tab label="Current Owner" />
              <Tab label="Previous Owners" />
            </Tabs>
          </AppBar>
          {activeTabIndex === 0 && (
            <TabContainer>
                <div style={{textAlign: "center", fontWeight: "600", padding: 10}}>Current Registered Owner</div>
              <Query query={getById} variables={{ id: this.props.rowData.id }}>
                    {({ data, loading, error }) => {
                    if (loading) return <p>Loading...</p>;
                    if (error) return <p>Error</p>;
                    return (
                        <div className={classes.root}>
                          <Paper>
                            <Grid rows={data.warranty_registration} columns={currentOwnerColumns}>
                            <PagingState defaultCurrentPage={0} pageSize={5} />
                            <RowDetailState defaultExpandedRowIds={[0]} />
                            <Table />
                            <TableHeaderRow />
                            <TableRowDetail contentComponent={this.rowDetailFactory.bind(this)} />
                            </Grid>
                        </Paper>
                        {data.warranty_registration.map((registration) => (
                          <WarrantyRegistrationTransfer key={registration.id} registration={registration} />
                        ))}
                        </div>
                    );
                    }}
                </Query>
            </TabContainer>
          )}
          {activeTabIndex === 1 && (
            <TabContainer>
              <Query query={getPreviousOwners} variables={{ hull_identification_number: this.props.rowData.hull_identification_number }}>
                    {({ data, loading, error }) => {
                    if (loading) return <p>Loading...</p>;
                    if (error) return <p>Error</p>;
                    return (
                        <div className={classes.root}>
                        <Paper>
                            <Grid rows={data.warranty_registration} columns={previousOwnersColumns}>
                            <PagingState defaultCurrentPage={0} pageSize={5} />
                            <IntegratedPaging />
                            <RowDetailState defaultExpandedRowIds={[0]} />
                            <Table />
                            <TableHeaderRow />
                            <TableRowDetail contentComponent={this.rowDetailFactory.bind(this)} />
                            <PagingPanel />
                            <Toolbar />
                            </Grid>
                        </Paper>
                        </div>
                    );
                    }}
                </Query>
            </TabContainer>
          )}
        </div>
      </div>
    );
  }
}

WarrantyRegistrationTabs.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(WarrantyRegistrationTabs);