import React from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { Typography, Paper } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { Mutation } from "react-apollo";
import { toast } from "react-toastify";
import useForm from "components/useForm";
import auth from "Auth";
import { Subscription } from "react-apollo";
import apollo from "apollo";
import {
  ADD_MEDIA,
  getAllMediaAlbumNamesSubscription,
  ADD_MEDIA_ITEMS
} from "queries";
import { DropzoneArea } from "material-ui-dropzone";
import axios from "axios";

const APP_SERVER = apollo.APP_UPLOAD_SERVER_URL;

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    padding: "2em 2em 0px 2em"
  },
  textField: {
    width: 220
  },
  labelField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: 10,
    textAlign: "center",
    margin: "auto"
  },
  dense: {
    marginTop: 19
  },
  gridStyle: {
    marginTop: 19,
    marginBottom: 10
  },
  dropZoneAreaStyle: {
    marginTop: 19,
    marginBottom: 10,
    marginLeft: 5,
    marginRight: 5
  },
  addMediaHeading: {
    textAlign: "center",
    fontSize: "20px",
    padding: "1em 0px"
  },
  description: {
    textAlign: "center",
    fontSize: "10px"
  },
  chooseFiles: {
    textTransform: "none",
    padding: "0.75em 1.5em"
  }
}));

const MediaUploader = props => {

  const client = apollo.clientForToken(auth.getIdToken());
  const { values, handleChange, resetState } = useForm(submitForm);
  const classes = useStyles();
  function addMediaItemsMutation(mediaItem) {
    client.mutate({
      mutation: ADD_MEDIA_ITEMS,
      variables: {
        objects: [mediaItem]
      }
    });
  }

  var media_id;

  values.open = false;
  values.files = [];

  // values.start_date = new Date().getDate()
  function submitForm() {
    console.log(values);
  }

  function uploadImagesandUpdateRecord() {
    //console.log("uploadImagesandUpdateRecord called");
    //variable image can be video as well
    const uploaders = values.files.map(image => {
      const data = new FormData();
      data.append("image", image, image.name);
      console.log(image);
      let mediaType = "image";
      if (image.type.indexOf("video") !== -1) {
        mediaType = "video";
      }

      // Make an AJAX upload request using Axios
      return axios.post(APP_SERVER + '/', data).then(response => {
        if (response.status === 200) {
          let mediaItem = {};
          mediaItem.media_id = media_id;
          mediaItem.name = response.data.imageOrignalName;
          mediaItem.preview_link = response.data.imageUrl;
          mediaItem.description = response.config.url;
          mediaItem.media_type = mediaType;
          toast("Media Item" + mediaItem.name);
          addMediaItemsMutation(mediaItem);
        }
      });
    });

    // Once all the files are uploaded
    axios
      .all(uploaders)
      .then(() => {
        console.log("done");
      })
      .catch(err => alert(err.message));
  }

  function handleAreaChange(files) {
    if(files){
      values.files = files;
    }
    console.log(files);
  }

  function completedAdd(payload) {
    //console.log("completeAdd has been called" + payload);
    media_id = payload.insert_media.returning[0].id;
    //console.log(payload.insert_media.returning[0].id);
    toast("New Album has been created");
    uploadImagesandUpdateRecord();
    resetState();
    values.files.length = 0;
  }

  return (
    <Paper>
      <Typography gutterBottom variant="h4" className={classes.addMediaHeading}>
        <span>ADD MEDIA</span>
      </Typography>
      <Typography gutterBottom variant="h6" className={classes.description}>
        <span>
          Select which brand and upload new images or videos to that brand’s album.{" "}
        </span>
      </Typography>

      <Mutation mutation={ADD_MEDIA} onCompleted={completedAdd}>
        {(addMedia, { data, loading, called, error }) => {
          if (loading) return <p>Loading</p>;
          return (
            <form
              onSubmit={e => {
                try {
                  e.preventDefault();
                  values.profile_id = auth.getSub();
                  var dataP = {};
                  if (
                    typeof values.album_name === "undefined" &&
                    typeof values.brand === "undefined" &&
                    typeof values.new_albumname === "undefined"
                  ) {
                    toast.error("Please Select Brand , and Choose Existing Album or  Create New Album", {
                      position: toast.POSITION.TOP_LEFT
                    });
                    return false;
                  }

                  if (values.files === undefined || values.files.length === 0) {
                    toast.error("Please Upload Media", {
                      position: toast.POSITION.TOP_LEFT
                    });
                    return false;
                  }
                  //create new Album
                  if (values.album_name !== "" && typeof values.album_name !== "undefined") {
                    dataP.album_name = values.album_name.album_name;
                    // dataP.brand = values.brand;
                    dataP.description = "Adding Images with Existing Album";
                    //e.preventDefault();
                    media_id = values.album_name.id;
                    uploadImagesandUpdateRecord();
                    resetState();
                  } else {
                    if (values.brand !== "" && typeof values.brand !== "undefined") {
                      if (typeof values.new_albumname !== "undefined" && values.new_albumname !== "") {
                        dataP.album_name = values.new_albumname;
                        dataP.brand = values.brand;
                        dataP.description = "Adding Images with New Brand and Items";
                        e.preventDefault();
                        addMedia({
                          variables: {
                            objects: [dataP]
                          }
                        });
                      } else {
                        toast.error("Please Enter New Album Name or Choose Existing", {
                          position: toast.POSITION.TOP_LEFT
                        });
                        return false;
                      }
                    }
                    toast.error("Please Select Brand , and Choose Existing Album or  Create New Album", {
                      position: toast.POSITION.TOP_LEFT
                    });
                    return false;
                  }

                } catch (error) {
                  //console.log(error);
                  toast.error("Error: " + error, {
                    position: toast.POSITION.TOP_LEFT
                  });
                }
              }}
              className={classes.container}
              autoComplete="off"
            >
              <Grid
                container
                direction="row"
                alignItems="center"
                justify="space-between"
              >
                <TextField
                  id="brand"
                  name="brand"
                  label="Select Brand"
                  value={values.brand || ""}
                  select
                  onChange={handleChange}
                  className={classes.textField}
                  inputProps={{
                    name: "brand",
                    id: "brand"
                  }}
                  variant="outlined"
                  required
                >
                  <MenuItem />
                  <MenuItem value="Carolina Skiff">Carolina Skiff</MenuItem>
                  <MenuItem value="Sea Chaser">Sea Chaser</MenuItem>
                  <MenuItem value="Fun Chaser">Fun Chaser</MenuItem>
                </TextField>

                <Subscription subscription={getAllMediaAlbumNamesSubscription}>
                  {({ loading, error, data }) => {
                    if (loading)
                      return (
                        <TextField
                          variant="outlined"
                          key="Loading"
                          value="Loading.."
                        ></TextField>
                      );
                    if (error) {
                      //console.log(error);
                      return <p>Error...</p>;
                    }
                    return (
                      <TextField
                        id="album_name"
                        name="album_name"
                        label="Add to Existing Album.. "
                        value={values.album_name || ""}
                        select
                        variant="outlined"
                        onChange={handleChange}
                        className={classes.textField}
                        ref="album_name"
                        inputProps={{
                          name: "album_name",
                          id: "album_name"
                        }}
                      >
                        {!loading && data.media.length > 0
                          ? data.media.map((option, index) => (
                              <MenuItem key={option.id} value={option}>
                                {option.album_name}
                              </MenuItem>
                            ))
                          : ""}
                      </TextField>
                    );
                  }}
                </Subscription>

                <TextField
                  id="new_albumname"
                  name="new_albumname"
                  label="Create Album Name"
                  placeholder="Create Album Name"
                  className={classes.textField}
                  margin="normal"
                  onChange={handleChange}
                  value={values.new_albumname || ""}
                  InputLabelProps={{
                    shrink: true
                  }}
                  variant="outlined"
                />

               {/*  <span>OR</span> */}

                {/* <Button
                  variant="contained"
                  color="primary"
                  className={classes.chooseFiles}
                >
                  Choose File (s)
                </Button> */}
              </Grid>

              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid
                  item
                  xs={12}
                  align="center"
                  className={classes.dropZoneAreaStyle}
                >
                <DropzoneArea
                    id="dropZoneArea"
                    filesLimit={20}
                    maxFileSize={1500000000}
                    onChange={handleAreaChange}
                  />
                </Grid>

                <Grid item xs={12} align="center" className={classes.gridStyle}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onChange={handleChange}
                    type="submit"
                  >
                    Upload Media
                  </Button>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Mutation>
    </Paper>
  );
};

export default MediaUploader;
