import React, { useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { Typography, withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { getAllDealerOptions, ADD_WARRANTY_REGISTRATIONS, GET_BOAT_MODELS } from "queries";
import { useLazyQuery } from "@apollo/client";
import { Mutation } from "react-apollo";
import { toast } from "react-toastify";
import useForm from "components/useForm";
import { CountryRegionData } from "react-country-region-selector";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import auth from "Auth";

const useStyles = makeStyles(theme => ({
  container: {
    padding: "2em"
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: "100%",
    margin: "1em",
    textAlign: "center"
  },
  dense: {
    marginTop: 19
  },
  formControl: {
    margin: theme.spacing(3),
    marginTop: theme.spacing(3)
  },
  addWarrantyRegistration: {
    fontSize: "18px",
    fontWeight: "300",
    textTransform: "uppercase",
    textAlign: "center",
  }
}));

const getRegions = country => {
  if (!country) {
    return [];
  }

  return country.split("|").map(regionPair => {
    let [regionName, regionShortCode = null] = regionPair.split("~");
    return regionName;
  });
};

const AddWarrantyRegistration = props => {
  const { values, handleChange, resetState, setValues } = useForm(
    submitForm
  );
  const [getAllDealers, allDealerOptions] = useLazyQuery(getAllDealerOptions);
  const [getAllBoatModels, allBoatModels] = useLazyQuery(GET_BOAT_MODELS);
  const classes = useStyles();

  function submitForm() {
  }

  function completedAdd() {
    toast("New Warranty Registration has been created succesfully");
    resetState();
  }

  useEffect(() => {
    getAllDealers();
    getAllBoatModels();
  }, [])

  return (
    <Mutation mutation={ADD_WARRANTY_REGISTRATIONS} onCompleted={completedAdd}>
      {(addNewWarrantyRegistration, { data, loading, called, error }) => {
        if (error)
          return (
            <pre>
              Bad:{" "}
              {error.graphQLErrors.map(({ message }, i) => (
                <span key={i}>{message}</span>
              ))}
            </pre>
          );
        return (
          <form
            onSubmit={e => {
              let formData = { ...values };

              formData.admin_name = auth.getNickName();
              formData.admin_email = auth.getEmail();

              e.preventDefault();
              addNewWarrantyRegistration({
                variables: {
                  objects: [formData]
                }
              });

              return false;
            }}
            className={classes.container}
            autoComplete="off"
          >
            <Typography gutterBottom variant="h2" className={classes.addWarrantyRegistration}>
              Add Warranty Registration
            </Typography>

            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                wrap="nowrap"
              >
                {/* Row 1 */}

                <TextField
                  required
                  variant="outlined"
                  name="first_name"
                  label="Customer First Name"
                  onChange={handleChange}
                  value={values.first_name}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true
                  }}
                />

                <TextField
                  required
                  variant="outlined"
                  name="last_name"
                  label="Customer Last Name"
                  onChange={handleChange}
                  value={values.last_name}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true
                  }}
                />

                <TextField
                  required
                  variant="outlined"
                  name="phone"
                  label="Customer Phone"
                  type="phone"
                  onChange={(e) => handleChange(e, 'phone')}
                  value={values.phone}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true
                  }}
                />

                <TextField
                  required
                  variant="outlined"
                  name="email"
                  label="Customer Email"
                  type="email"
                  onChange={handleChange}
                  value={values.email}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>

              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                wrap="nowrap"
              >
                {/* Row 2 */}

                <TextField
                  name="profile_id"
                  label="Dealer"
                  variant="outlined"
                  select
                  className={classes.textField}
                  value={values.profile_id || ""}
                  onChange={handleChange}
                  SelectProps={{
                    native: true,
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                >
                  <option value=""></option>
                  {allDealerOptions.data && allDealerOptions.data.profiles.map((option) => (
                    <option key={option.profile_id} value={option.profile_id}>
                      {option.name}{option.status === 'archived' ? ' - Archived' : ''}
                    </option>
                  ))}
                </TextField>

                <TextField
                  required
                  variant="outlined"
                  name="hull_identification_number"
                  label="Hull ID# (HIN)"
                  onChange={handleChange}
                  value={values.hull_identification_number}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true
                  }}
                />

              </Grid>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                wrap="nowrap"
              >
                {/* Row 3 */}

                <TextField
                  required
                  variant="outlined"
                  name="brand"
                  label="Brand"
                  select
                  onChange={handleChange}
                  value={values.brand}
                  className={classes.textField}
                  SelectProps={{
                    native: true,
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                >
                  <option value=""></option>
                  <option key="Carolina Skiff" value="Carolina Skiff">Carolina Skiff</option>
                  <option key="Sea Chaser" value="Sea Chaser">Sea Chaser</option>
                  <option key="Fun Chaser" value="Fun Chaser">Fun Chaser</option>
                </TextField>

                <TextField
                  required
                  variant="outlined"
                  name="model"
                  label="Model"
                  select
                  onChange={(e) => {
                    handleChange(e);
                    setValues(values => ({
                      ...values,
                      model_id: e.target.options[e.target.selectedIndex].dataset.modelId || null
                    }));
                  }}
                  value={values.model}
                  className={classes.textField}
                  SelectProps={{
                    native: true,
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                >
                  <option value=""></option>
                  {
                    allBoatModels.data && allBoatModels.data.boat_models &&
                      allBoatModels.data.boat_models.map((boat) => {
                        return <option key={`${boat.model}`} value={boat.model} data-model-id={boat.id}>{boat.model} {boat.older_model ? ' - Older Model' : ''}</option>
                      })
                  }
                </TextField>

                <TextField
                  required
                  variant="outlined"
                  name="purchase_date"
                  label="Date of Purchase"
                  type="date"
                  value={values.purchase_date || " "}
                  onChange={handleChange}
                  className={classes.textField}
                />
              </Grid>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                wrap="nowrap"
              >
                {/* Row 4 */}

                <TextField
                  required
                  variant="outlined"
                  name="address"
                  label="Street Address"
                  onChange={handleChange}
                  value={values.address}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true
                  }}
                />

                <TextField
                  required
                  variant="outlined"
                  name="address2"
                  label="Address Line 2"
                  onChange={handleChange}
                  value={values.address2}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>

              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                wrap="nowrap"
              >
                {/* Row 5 */}

                <TextField
                  name="country"
                  label="Country"
                  variant="outlined"
                  required
                  select
                  className={classes.textField}
                  value={values.country || ""}
                  onChange={handleChange}
                  SelectProps={{
                    native: true,
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                >
                  <option value=""></option>
                  {CountryRegionData.map((option, index) => (
                    <option key={option} value={option[0]}>
                      {option[0]}
                    </option>
                  ))}
                </TextField>
                <TextField
                  required
                  variant="outlined"
                  name="city"
                  label="City"
                  placeholder="City"
                  className={classes.textField}
                  onChange={handleChange}
                  value={values.city || ""}
                />
                <TextField
                  name="region"
                  label="Region"
                  variant="outlined"
                  select
                  className={classes.textField}
                  value={values.region || ""}
                  onChange={handleChange}
                  SelectProps={{
                    native: true,
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                >
                  <option value=""></option>
                  {getRegions(values.country).map((option, index) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </TextField>
                <TextField
                  required
                  variant="outlined"
                  name="zipcode"
                  label="Zipcode"
                  placeholder="Zipcode"
                  className={classes.textField}
                  onChange={handleChange}
                  value={values.zipcode || ""}
                  required
                />
              </Grid>
              <Grid container>
                <Grid item xs={12} align="center">
                  <InputLabel
                    id="errorMsgs"
                    value={error}
                    width="100%"
                    ref={error}
                  ></InputLabel>
                </Grid>
              </Grid>


              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                wrap="nowrap"
                className={classes.dense}
              >
                <Typography gutterBottom variant="h2" className={classes.addWarrantyRegistration}>
                  Additional Information
              </Typography>
              </Grid>

              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                wrap="nowrap"
              >
                {/* Row 5 */}
                <TextField
                  variant="outlined"
                  name="motor_details"
                  label="Motor Detail"
                  placeholder="Motor Detail"
                  className={classes.textField}
                  onChange={handleChange}
                  value={values.motor_details || ""}
                  multiline
                />
                <TextField
                  variant="outlined"
                  name="accessories"
                  label="Accessories"
                  placeholder="Accessories"
                  className={classes.textField}
                  onChange={handleChange}
                  value={values.accessories || ""}
                  multiline
                />
              </Grid>


              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                wrap="nowrap"
              >
                {/* Row 5 */}
                <TextField
                  name="primary_use"
                  select
                  label="Primary Use"
                  className={classes.textField}
                  onChange={handleChange}
                  value={values.primary_use}
                  SelectProps={{
                    native: true,
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                  variant="outlined"
                >
                  <option />
                  <option key="Pleasure" value="Pleasure">Pleasure</option>
                  <option key="Business" value="Business">Business</option>
                  <option key="Fishing Charter" value="Fishing Charter">Fishing Charter</option>
                </TextField>
              </Grid>

              <Grid item xs={12} align="center" className={classes.dense}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.addWarrantyRegistration}
                  type="submit"
                >
                  ADD WARRANTY REGISTRATION
                </Button>
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Mutation>
  );
};

export default AddWarrantyRegistration;