import React from "react";
import { Route } from "react-router-dom";
import auth from "../Auth";
//import { ApolloProvider } from "react-apollo";
//import apollo from '../apollo'

function GuardedRoute(props) {
  const { component: Component, path } = props;
  //const client = apollo.clientForToken(auth.getIdToken())
  return (
    <Route
      exact
      path={path}
      render={props => {
        if (!auth.isAuthenticated()) return auth.login();
        return (
          //<ApolloProvider client={client}>
          <Component {...props} />
          //</ApolloProvider>
        );
      }}
    />
  );
}

export default GuardedRoute;
