import React, { Component } from "react";
import { Mutation } from "react-apollo";
import { gql } from "apollo-boost";
import { withStyles } from "@material-ui/core/styles";
import auth from "../../Auth";
import { Query } from "react-apollo";
import {GET_USER_INFO, GET_ACTIVE_WARRANTY_REGISTRATION_BY_HID, GET_BOAT_MODELS, GET_AWS_KEY} from "queries";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Grid, Paper, Typography, TextField, CircularProgress } from "@material-ui/core";
import { Dashboard as DashboardLayout } from "layouts";
import AWS from 'aws-sdk';
import { DropzoneArea } from "material-ui-dropzone";
import { v4 as uuidv4 } from 'uuid';

const ADD_WARRANTY_CLAIM = gql`
  mutation insert_warranty_claims(
    $profile_id: String
    $associate_name: String
    $associate_email: String
    $admin_name: String
    $admin_email: String
    $hull_identification_number: String
    $purchase_date: String
    $brand: String
    $model: String
    $model_id: uuid
    $first_name: String
    $last_name: String
    $phone: String
    $email: String
    $address: String
    $address2: String
    $city: String
    $region: String
    $country: String
    $zipcode: String
    $motor_details: String
    $issues: String
    $repairs: String
    $parts: String
    $labor_hours: String
    $labor_total: String
    $parts_used: String
    $sublet: String
    $other: String
    $claim_total: String
    $photos: json
  ) {
    insert_warranty_claims(
      objects: [
        {
          profile_id: $profile_id
          associate_name: $associate_name
          associate_email: $associate_email
          admin_name: $admin_name
          admin_email: $admin_email
          hull_identification_number: $hull_identification_number
          purchase_date: $purchase_date
          brand: $brand
          model: $model
          model_id: $model_id
          first_name: $first_name
          last_name: $last_name
          phone: $phone
          email: $email
          address: $address
          address2: $address2
          city: $city
          region: $region
          country: $country
          zipcode: $zipcode
          motor_details: $motor_details
          issues: $issues
          repairs: $repairs
          parts: $parts
          labor_hours: $labor_hours
          labor_total: $labor_total
          parts_used: $parts_used
          sublet: $sublet
          other: $other
          claim_total: $claim_total
          photos: $photos
        }
      ]
    ) {
      affected_rows
    }
  }
`;
const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value
});
const styles = theme => ({
  button: {
    margin: theme.spacing()
  },
  root: {
    padding: theme.spacing(4)
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: 275
  },
  prefixDropdown: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: 100
  },
  title: {
    fontSize: "18px",
    textAlign: "center",
    textTransform: "uppercase",
    margin: "1em",
    fontWeight: 300
  }
});
class Register extends Component {
  notify = () => toast("Pre Auth Claim Submission Successful");
  constructor() {
    super();
    this.state = {
      hull_identification_number: "",
      hull_identification_number2: "",
      hull_identification_match: false,
      purchase_date: "",
      brand: "",
      model: "",
      first_name: "",
      last_name: "",
      phone: "",
      associate_name: "",
      associate_email: "",
      admin_email: auth.getEmail(),
      admin_name: "",
      email: "",
      address: "",
      address2: "",
      city: "",
      region: "",
      country: "",
      zipcode: "",
      motor_details: "",
      issues: "",
      repairs: "",
      parts: "",
      labor_hours: "",
      labor_total: "",
      parts_used: "",
      sublet: "",
      other: "",
      claim_total: "",
      photos: [],
      searchHin: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleAreaChange = this.handleAreaChange.bind(this);
  }
  inputHullIdentification = event => {
    this.setState({ hull_identification_number: event.target.value });
  };
  confirmHullIdentification = event => {
    this.setState({ hull_identification_number2: event.target.value });
  };
  handleChange = evt => {
    this.setState({ [evt.target.name]: evt.target.value });
  };
  handleAreaChange = photos => {
    this.setState({ photos: photos });
  }
  completedAdd = payload => {
    this.notify();
    this.clearForm();
  };
  clearForm = () => {
    this.setState({
      hull_identification_number: "",
      hull_identification_number2: "",
      hull_identification_match: false,
      purchase_date: "",
      brand: "",
      model: "",
      first_name: "",
      last_name: "",
      phone: "",
      associate_name: "",
      associate_email: "",
      admin_email: auth.getEmail(),
      admin_name: "",
      email: "",
      address: "",
      address2: "",
      city: "",
      region: "",
      country: "",
      zipcode: "",
      motor_details: "",
      issues: "",
      repairs: "",
      parts: "",
      labor_hours: "",
      labor_total: "",
      parts_used: "",
      sublet: "",
      other: "",
      claim_total: "",
      photos: []
    });
  };
  render() {
    const { classes } = this.props;
    const {
      hull_identification_number,
      hull_identification_number2
    } = this.state;
    return (
      <DashboardLayout title="PRE AUTHORIZATION CLAIM">
        <div className={classes.root + " sectionContainer"} id="pre-authorization-claim-section-container">
          <Typography gutterBottom variant="h4" className="title">
            <span>PRE AUTHORIZATION CLAIM</span>
          </Typography>
          <Query query={GET_AWS_KEY} variables={{ id: "297122cb-9143-4ff9-9c6c-ad577d6157de" }}>
            {({ data: keysData, loading: keysLoading }) => {
              if (keysLoading)
                return (
                    <div className="flex center" style={{ padding: "2em 0" }}>
                      <CircularProgress color="secondary" />
                    </div>
                );
              return (
                <Mutation
                  mutation={ADD_WARRANTY_CLAIM}
                  onCompleted={this.completedAdd}
                >
                  {(registerWarrantyClaim, { data, loading, error }) => (
                    <form
                      onSubmit={e => {
                        e.preventDefault();
                        if (
                          hull_identification_number === hull_identification_number2
                        ) {
                          this.setState({
                            helperText: "",
                            hull_identification_match: false
                          });
                        } else {
                          this.setState({
                            helperText: "Hull Identification Numbers Do Not Match",
                            hull_identification_match: true
                          });
                          return;
                        }
                        if (this.state.purchase_date.length === 0) {
                          toast.error("Please Select a Purchase Date", {
                            position: toast.POSITION.TOP_LEFT
                          });
                          return;
                        }
                        
                        let uniquePhotoNames = [];
                        const s3 = new AWS.S3({
                          region: 'us-east-1',
                          apiVersion: '2006-03-01',
                          accessKeyId: keysData.keys_by_pk.name,
                          secretAccessKey: keysData.keys_by_pk.value
                        });
                        this.state.photos.map(function (warrantyFile) {
                          let uniquePhotoName = uuidv4().substring(0, 9) + warrantyFile.name;
                          uniquePhotoNames.push(uniquePhotoName);
                          const s3Params = {
                            Body: warrantyFile,
                            Bucket: "carolina-skiff-dealernet",
                            Key: "warranty_claims/" + auth.getSub() + "/" + uniquePhotoName
                          };
                          s3.putObject(s3Params, function (err, data) {
                            if (err) {
                              console.log(err, err.stack);
                              toast.error("Error Submitting Data to S3 Container", {
                                position: toast.POSITION.TOP_LEFT
                              });
                            }
                          });
                        });
      
                        registerWarrantyClaim({
                          variables: {
                            profile_id: auth.getSub(),
                            associate_name: this.state.associate_name,
                            associate_email: this.state.associate_email,
                            admin_name: this.state.admin_name,
                            admin_email: this.state.admin_email,
                            hull_identification_number: this.state.hull_identification_number,
                            purchase_date: this.state.purchase_date,
                            brand: this.state.brand,
                            model: this.state.model,
                            model_id: this.state.model_id,
                            first_name: this.state.first_name,
                            last_name: this.state.last_name,
                            phone: this.state.phone,
                            email: this.state.email,
                            address: this.state.address,
                            address2: this.state.address2,
                            city: this.state.city,
                            region: this.state.region,
                            country: this.state.country,
                            zipcode: this.state.zipcode,
                            motor_details: this.state.motor_details,
                            issues: this.state.issues,
                            repairs: this.state.repairs,
                            parts: this.state.parts,
                            labor_hours: this.state.labor_hours,
                            labor_total: this.state.labor_total,
                            parts_used: this.state.parts_used,
                            sublet: this.state.sublet,
                            other: this.state.other,
                            claim_total: this.state.claim_total,
                            photos: uniquePhotoNames
                          }
                        });
                      }}
                    >
                      <Paper className={classes.paper} style={{ padding: "2em" }}>
                        <Grid item xs={12} align="center">
                          <Typography
                            gutterBottom
                            variant="h6"
                            align="center"
                            className={classes.title}
                          >
                            Associate Information
                          </Typography>
                          <Grid container justify="space-between">
                            <TextField
                              required
                              id="associate_name"
                              name="associate_name"
                              label="Associate Name"
                              onChange={event =>
                                this.setState(
                                  byPropKey(
                                    "associate_name",
                                    event.target.value
                                  )
                                )
                              }
                              className={classes.textField}
                              value={this.state.associate_name}
                              variant="outlined"
                            />
                            <TextField
                              required
                              id="associate_email"
                              name="associate_email"
                              label="Associate Email"
                              onChange={event =>
                                this.setState(
                                  byPropKey(
                                    "associate_email",
                                    event.target.value
                                  )
                                )
                              }
                              className={classes.textField}
                              value={this.state.associate_email}
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={12} align="center">
                          <Typography
                            gutterBottom
                            variant="h6"
                            align="center"
                            className={classes.title}
                          >
                            Dealer Information
                          </Typography>
                          <Grid container justify="space-between">
                            <Query query={GET_USER_INFO} variables={{ id: auth.getSub() }}>
                              {({ data, loading, error }) => {
                                if (loading) return <p>Loading...</p>;
                                if (error) return <p>Error</p>;
      
                                if (this.state.admin_name === "") {
                                  this.setState({ admin_name: data.profiles_by_pk.company });
                                }
                                return (
                                  <TextField
                                    required
                                    id="admin_name"
                                    name="admin_name"
                                    label=""
                                    className={classes.textField}
                                    onChange={this.handleChange}
                                    margin="normal"
                                    value={this.state.admin_name}
                                    variant="outlined"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                  />
                                );
                              }}
                            </Query>
                            <TextField
                              required
                              id="admin_email"
                              name="admin_email"
                              label=""
                              className={classes.textField}
                              margin="normal"
                              value={auth.getEmail()}
                              variant="outlined"
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            gutterBottom
                            variant="h6"
                            align="center"
                            className={classes.title}
                          >
                            Product Information
                            </Typography>
      
                          <Grid container justify="space-between">
                            <Grid item xs={12} sm={3}>
                              <TextField
                                required
                                style={{ width: "100%" }}
                                id="hull_identification_number"
                                name="hull_identification_number"
                                label="Hull Identification Number"
                                onChange={event =>
                                  this.setState(
                                    byPropKey(
                                      "hull_identification_number",
                                      event.target.value
                                    )
                                  )
                                }
                                className={classes.textField}
                                value={this.state.hull_identification_number}
                                variant="outlined"
                                onBlur={() => {
                                  this.setState({searchHin: true});
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <TextField
                                required
                                style={{ width: "100%" }}
                                variant="outlined"
                                id="hull_identification_number2"
                                name="hull_identification_number2"
                                label="Retype Hull Identification Number"
                                onChange={event =>
                                  this.setState(
                                    byPropKey(
                                      "hull_identification_number2",
                                      event.target.value
                                    )
                                  )
                                }
                                className={classes.textField}
                                helperText={this.state.helperText}
                                error={this.state.hull_identification_match}
                                value={this.state.hull_identification_number2}
                                onBlur={() => {
                                  this.setState({searchHin: true});
                                }}
                              />
                              {
                                  this.state.searchHin &&
                                  this.state.hull_identification_number.length > 0 &&
                                  (this.state.hull_identification_number === this.state.hull_identification_number2) &&
                                  <Query query={GET_ACTIVE_WARRANTY_REGISTRATION_BY_HID} variables={{ hin: this.state.hull_identification_number }}>
                                    {({ data, loading, error }) => {
                                      if (loading) return <div></div>;
                                      if (error) {
                                        console.log(error);
                                        return <div></div>;
                                      }
                                      if (data.warranty_registration.length === 1) {
                                        let registration = data.warranty_registration[0];
                                        this.setState({
                                          brand: registration.brand,
                                          model: registration.model
                                        });
                                      }
                                      this.setState({ searchHin: false });
                                      return (
                                          <div>{JSON.stringify(data)}</div>
                                      );
                                    }}
                                  </Query>
                              }
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <TextField
                                required
                                style={{ width: "100%" }}
                                variant="outlined"
                                id="purchase_date"
                                name="purchase_date"
                                label="Purchase Date"
                                type="date"
                                onChange={this.handleChange}
                                value={this.state.purchase_date}
                                className={classes.textField}
                                InputLabelProps={{
                                  shrink: true
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Grid container justify="space-between">
                            <Grid item xs={12} sm={4}>
                              <TextField
                                  name="brand"
                                  select
                                  label="Select Brand"
                                  style={{ width: "350px" }}
                                  className={
                                      classes.textField + " " + classes.prefixDropdown
                                  }
                                  onChange={this.handleChange}
                                  value={this.state.brand}
                                  SelectProps={{
                                    native: true,
                                    MenuProps: {
                                      className: classes.menu
                                    }
                                  }}
                                  variant="outlined"
                                  margin="normal"
                              >
                                <option value=""></option>
                                <option key="Carolina Skiff" value="Carolina Skiff">Carolina Skiff</option>
                                <option key="Sea Chaser" value="Sea Chaser">Sea Chaser</option>
                                <option key="Fun Chaser" value="Fun Chaser">Fun Chaser</option>
                              </TextField>
                            </Grid>
                          <Query query={GET_BOAT_MODELS}>
                            {({ data, loading, error }) => {
                              if (loading) return <p>Loading...</p>;
                              if (error) return <p>Error {error.message}</p>;
                              return (
                                    <Grid item xs={12} sm={4}>
                                      <TextField
                                          name="model"
                                          label="Model"
                                          select
                                          className={
                                              classes.textField + " " + classes.prefixDropdown
                                          }
                                          onChange={(e) => {
                                            this.handleChange(e);
                                            this.setState({ model_id: e.target.options[e.target.selectedIndex].dataset.modelId || null });
                                          }}
                                          value={this.state.model}
                                          SelectProps={{
                                            native: true,
                                            MenuProps: {
                                              className: classes.menu
                                            }
                                          }}
                                          style={{ width: "350px" }}
                                          variant="outlined"
                                          margin="normal"
                                      >
                                        <option value=""></option>
                                        {
                                            data && data.boat_models &&
                                            data.boat_models.map((boat) => {
                                              return <option key={`${boat.model}`} value={boat.model} data-model-id={boat.id}>{boat.model} {boat.older_model ? ' - Older Model' : ''}</option>
                                            })
                                        }
                                      </TextField>
                                    </Grid>
                              );
                            }}
                          </Query>
                          </Grid>
                        </Grid>
                        <Typography
                          gutterBottom
                          variant="h6"
                          align="center"
                          className={classes.title}
                        >
                          Customer Information
                          </Typography>
                        <Grid container>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              required
                              id="first_name"
                              name="first_name"
                              label="First Name"
                              className={classes.textField}
                              onChange={this.handleChange}
                              margin="normal"
                              value={this.state.first_name}
                              style={{ width: "90%" }}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              required
                              id="last_name"
                              name="last_name"
                              label="Last Name"
                              className={classes.textField}
                              onChange={this.handleChange}
                              style={{ width: "90%" }}
                              margin="normal"
                              value={this.state.last_name}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              required
                              id="phone"
                              name="phone"
                              label="Phone"
                              className={classes.textField}
                              style={{ width: "90%" }}
                              onChange={(e) => this.handleChange(e, 'phone')}
                              margin="normal"
                              value={this.state.phone}
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
      
                        <Grid container>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              required
                              id="email"
                              name="email"
                              label="Email"
                              className={classes.textField}
                              onChange={this.handleChange}
                              margin="normal"
                              value={this.state.email}
                              style={{ width: "90%" }}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              required
                              id="address"
                              name="address"
                              label="Address"
                              className={classes.textField}
                              onChange={this.handleChange}
                              margin="normal"
                              value={this.state.address}
                              style={{ width: "90%" }}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              id="address2"
                              name="address2"
                              label="Address line 2 (Suites, Apt. # etc)"
                              className={classes.textField}
                              onChange={this.handleChange}
                              margin="normal"
                              value={this.state.address2}
                              style={{ width: "90%" }}
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
      
                        <Grid container>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              required
                              id="city"
                              name="city"
                              label="City"
                              className={classes.textField}
                              onChange={this.handleChange}
                              margin="normal"
                              value={this.state.city}
                              style={{ width: "90%" }}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              id="country"
                              name="country"
                              label="Country"
                              select
                              className={classes.textField}
                              onChange={this.handleChange}
                              value={this.state.country}
                              SelectProps={{
                                native: true,
                                MenuProps: {
                                  className: classes.menu
                                }
                              }}
                              margin="normal"
                              style={{ width: "90%" }}
                              variant="outlined"
                            >
                              <option />
                              <option key="US" value="US">
                                US
                                </option>
                            </TextField>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              id="region"
                              name="region"
                              label="State"
                              select
                              className={classes.textField}
                              value={this.state.region || ""}
                              onChange={this.handleChange}
                              SelectProps={{
                                native: true,
                                MenuProps: {
                                  className: classes.menu
                                }
                              }}
                              margin="normal"
                              style={{ width: "90%" }}
                              variant="outlined"
                            >
                              <option key="" value="" />
                              <option key="AL" value="AL">Alabama</option>
                              <option key="AK" value="AK">Alaska</option>
                              <option key="AZ" value="AZ">Arizona</option>
                              <option key="AR" value="AR">Arkansas</option>
                              <option key="CA" value="CA">California</option>
                              <option key="CO" value="CO">Colorado</option>
                              <option key="CT" value="CT">Connecticut</option>
                              <option key="DE" value="DE">Delaware</option>
                              <option key="FL" value="FL">Florida</option>
                              <option key="GA" value="GA">Georgia</option>
                              <option key="HI" value="HI">Hawaii</option>
                              <option key="ID" value="ID">Idaho</option>
                              <option key="IL" value="IL">Illinois</option>
                              <option key="IN" value="IN">Indiana</option>
                              <option key="IA" value="IA">Iowa</option>
                              <option key="KS" value="KS">Kansas</option>
                              <option key="KY" value="KY">Kentucky</option>
                              <option key="LA" value="LA">Louisiana</option>
                              <option key="ME" value="ME">Maine</option>
                              <option key="MD" value="MD">Maryland</option>
                              <option key="MA" value="MA">Massachusetts</option>
                              <option key="MI" value="MI">Michigan</option>
                              <option key="MN" value="MN">Minnesota</option>
                              <option key="MS" value="MS">Mississippi</option>
                              <option key="MO" value="MO">Missouri</option>
                              <option key="MT" value="MT">Montana</option>
                              <option key="NE" value="NE">Nebraska</option>
                              <option key="NV" value="NV">Nevada</option>
                              <option key="NH" value="NH">New Hampshire</option>
                              <option key="NJ" value="NJ">New Jersey</option>
                              <option key="NM" value="NM">New Mexico</option>
                              <option key="NY" value="NY">New York</option>
                              <option key="NC" value="NC">North Carolina</option>
                              <option key="ND" value="ND">North Dakota</option>
                              <option key="OH" value="OH">Ohio</option>
                              <option key="OK" value="OK">Oklahoma</option>
                              <option key="OR" value="OR">Oregon</option>
                              <option key="PA" value="PA">Pennsylvania</option>
                              <option key="RI" value="RI">Rhode Island</option>
                              <option key="SC" value="SC">South Carolina</option>
                              <option key="SD" value="SD">South Dakota</option>
                              <option key="TN" value="TN">Tennessee</option>
                              <option key="TX" value="TX">Texas</option>
                              <option key="UT" value="UT">Utah</option>
                              <option key="VT" value="VT">Vermont</option>
                              <option key="VA" value="VA">Virginia</option>
                              <option key="WA" value="WA">Washington</option>
                              <option key="WV" value="WV">West Virginia</option>
                              <option key="WI" value="WI">Wisconsin</option>
                              <option key="WY" value="WY">Wyoming</option>
                            </TextField>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              id="zipcode"
                              name="zipcode"
                              label="Zip / Postal Code"
                              className={classes.textField}
                              onChange={this.handleChange}
                              margin="normal"
                              value={this.state.zipcode}
                              style={{ width: "90%" }}
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
                        <Typography
                          gutterBottom
                          variant="h6"
                          align="center"
                          className={classes.title}
                        >
                          Claim Information
                          </Typography>
      
                        <Grid container justify="space-around">
                          <Grid item xs={12} sm={6}>
                            <TextField
                              id="motor_details"
                              name="motor_details"
                              label="Motor(s)"
                              multiline
                              margin="normal"
                              variant="outlined"
                              rows="4"
                              className={classes.textField}
                              onChange={this.handleChange}
                              value={this.state.motor_details}
                              style={{ width: "90%" }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              id="issues"
                              name="issues"
                              label="Issue(s)"
                              margin="normal"
                              multiline
                              variant="outlined"
                              rows="4"
                              className={classes.textField}
                              onChange={this.handleChange}
                              value={this.state.issues}
                              style={{ width: "90%" }}
                            />
                          </Grid>
                        </Grid>
      
                        <Grid container justify="space-around">
                          <Grid item xs={12} sm={6}>
                            <TextField
                              id="repairs"
                              name="repairs"
                              label="Repair(s)"
                              margin="normal"
                              multiline
                              variant="outlined"
                              rows="4"
                              className={classes.textField}
                              onChange={this.handleChange}
                              value={this.state.repairs}
                              style={{ width: "90%" }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              id="parts"
                              name="parts"
                              label="Part(s)"
                              margin="normal"
                              variant="outlined"
                              multiline
                              rows="4"
                              className={classes.textField}
                              onChange={this.handleChange}
                              value={this.state.parts}
                              style={{ width: "90%" }}
                            />
                          </Grid>
                        </Grid>
                        <Typography
                          gutterBottom
                          variant="h6"
                          align="center"
                          className={classes.title}
                        >
                          Labor Details
                          </Typography>
      
                        <Grid container justify="space-around">
                          <Grid item sm={3} xs={12}>
                            <TextField
                              id="labor_hours"
                              name="labor_hours"
                              label="Labor Hours ($)"
                              className={classes.textField}
                              onChange={this.handleChange}
                              value={this.state.labor_hours}
                              style={{ width: "90%" }}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item sm={3} xs={12}>
                            <TextField
                              id="labor_total"
                              name="labor_total"
                              label="Labor Total ($)"
                              className={classes.textField}
                              onChange={this.handleChange}
                              value={this.state.labor_total}
                              style={{ width: "90%" }}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item sm={3} xs={12}>
                            <TextField
                              id="parts_used"
                              name="parts_used"
                              label="Parts Used ($)"
                              className={classes.textField}
                              onChange={this.handleChange}
                              value={this.state.parts_used}
                              style={{ width: "90%" }}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item sm={3} xs={12}>
                            <TextField
                              id="sublet"
                              name="sublet"
                              label="Sublet ($)"
                              className={classes.textField}
                              onChange={this.handleChange}
                              value={this.state.sublet}
                              style={{ width: "90%" }}
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
      
                        <Grid container>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              id="other"
                              name="other"
                              label="Other ($)"
                              className={classes.textField}
                              onChange={this.handleChange}
                              value={this.state.other}
                              style={{ width: "90%", marginTop: "1em" }}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              id="claim_total"
                              name="claim_total"
                              label="Claim Total ($)"
                              className={classes.textField}
                              onChange={this.handleChange}
                              value={this.state.claim_total}
                              style={{ width: "90%", marginTop: "1em" }}
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
      
                        <Typography
                          gutterBottom
                          variant="h6"
                          align="center"
                          className={classes.title}
                        >
                          Photos
                        </Typography>
                        <Grid
                          container
                          direction="row"
                          justify="center"
                          alignItems="center"
                        >
                          <Grid
                            item
                            xs={12}
                            align="center"
                            className={classes.dropZoneAreaStyle}
                          >
                            {
                              !(keysData && keysData.keys_by_pk) && <p>File uploads are disabled, because the AWS keys couldn't be retrieved.</p>
                            }
                            {
                              keysData && keysData.keys_by_pk &&
                                <DropzoneArea
                                    required
                                    id="dropZoneArea"
                                    filesLimit={10}
                                    maxFileSize={15000000}
                                    onChange={this.handleAreaChange}
                                />
                            }
                          </Grid>
                        </Grid>
                        <Grid item xs={12} align="center">
                          {loading && <p>Loading...</p>}
                          {error && (
                            <p>
                              Pre Auth Claim Could Not be Submitted. Please Try again.
                            </p>
                          )}
                          <Button
                            color="primary"
                            variant="contained"
                            className={classes.button}
                            type="submit"
                            style={{ width: "15em", margin: "2em 1em" }}
                          >
                            SUBMIT
                          </Button>
                          <Button
                            color="primary"
                            variant="contained"
                            className={classes.button}
                            onClick={this.clearForm}
                            style={{ width: "15em", margin: "2em 1em" }}
                          >
                            CANCEL
                          </Button>
                        </Grid>
                      </Paper>
                    </form>
                  )}
                </Mutation>
              );
            }}
          </Query>
        </div>
      </DashboardLayout>
    );
  }
}

export default withStyles(styles)(Register);
