import React from 'react';
import './PdfDocumentStyles.scss';

export const CommericalSalesCreditClaimPdfDocument = ({ data, requirementData }) => {

  return (
    <div className="document">
      <div className="page">
        <div className="sectionHeader">Government/Commercial Sales Credit Claim</div>
        <div className="rowContainer">
          <div className="row">
            <div className="column">
              <p className="field_text">
                Dealer Name: {data?.dealer_name || ""}
              </p>
              <p className="field_text">
                Contact Name: {data?.contact_name || ""}
              </p>
              <p className="field_text">
                Contact Phone: {data?.phone || ""}
              </p>
              <p className="field_text">
                Fax Number: {data?.fax || ""}
              </p>
              <p className="field_text">
                Address: {data?.address || ""}
              </p>
              <p className="field_text">
                Address 2: {data?.address2 || ""}
              </p>
              <p className="field_text">City: {data?.city || ""}</p>
              <p className="field_text">State: {data?.state || ""}</p>
              <p className="field_text">Country: {data?.country || ""} </p>
              <p className="field_text">
                Zip Code: {data?.zipcode || ""}
              </p>
              <p className="field_text" style={{ wordBreak: "break-all", width: "40vw" }}>
                Additional Information: {data?.additional_info || ""}
              </p>
              <p className="field_header" style={{ width: "40vw" }}>
                Carolina Skiff LLC will credit 5% of the Dealer boat cost for any Carolina Skiff product sold to a licensed commercial agency such as, Utility Commissions, Government Agency, Local State Agency, Boat Rental, Commercial Guide Fisherman or any other type of commercial business approved by Carolina Skiff LLC.
              </p>
              {requirementData && requirementData?.length > 0 &&
                <div style={{ display: 'table', width: '40vw' }}>
                  <table className="table" cellPadding={5} cellSpacing={0} >
                    <tr className="tableRow">
                      <th className="tableColHeader">Buyer Name</th>
                      <th className="tableColHeader">Item/Product</th>
                      <th className="tableColHeader">Serial No</th>
                      <th className="tableColHeader">Dealer Cost</th>
                      <th className="tableColHeader">% Credit</th>
                      <th className="tableColHeader">Total Credit</th>
                    </tr>
                    {requirementData?.map((item) => {
                      return (
                        <tr className="tableRow">
                          <td className="tableCol">
                            <p className="tableCell">{item?.buyer_name || ""}</p>
                          </td>
                          <td className="tableCol">
                            <p className="tableCell">{item?.item_name || ""}</p>
                          </td>
                          <td className="tableCol">
                            <p className="tableCell">{item?.serial_no || ""}</p>
                          </td>
                          <td className="tableCol">
                            <p className="tableCell">{item?.dealer_cost ? `$${item?.dealer_cost}` : ""}</p>
                          </td>
                          <td className="tableCol">
                            <p className="tableCell">{item?.credit ? `${item?.credit}%` : ""}{item?.credit || ""}</p>
                          </td>
                          <td className="tableCol">
                            <p className="tableCell">{item?.total_credit ? `$${item?.total_credit}` : ""}</p>
                          </td>
                        </tr>
                      )
                    }
                    )}
                  </table>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

