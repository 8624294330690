import React, { useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Grid, Typography } from "@material-ui/core";
import "../CoOpTabs/RequestRowDetail.scss";
import styles from "layouts/Dashboard/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import "../../../../Administration/CoopRequests/AdminRequestDetail.scss";
import PDFimg from "../../../../../images/pdf-file-format.png";
import excelimg from "../../../../../images/excel.png";
import { AdvertisingClaimFormPDFDetail } from "./AdvertisingClaimFormPDFDetail";
import AdminStatusButtons from "pages/Administration/CoopRequests/AdminStatusButtons";
import { CSVLink } from "react-csv";
import CommentBox from "pages/Administration/CoopRequests/CommentBox";
import moment from "moment";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import {
  Table,
  TableHeaderRow,
  Grid as Grids,
} from "@devexpress/dx-react-grid-material-ui";
import { isPdf } from "common/colors";
import PdfImage from "../../../../../images/pdf-icon-red-and-white-color-for-free-png.webp";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    maxHeight: "95%",
    overflow: "scroll",
    top: "3.5% !important",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #707070",
    boxShadow: "0px 3px 6px 0px #00000029",
    padding: theme.spacing(4),
    width: "768px",
    maxWidth: "90%",
  },
  PrimaryButton: {
    backgroundColor: "#456790",
    color: "#fff",
    fontSize: "15px",
    "&:hover": {
      backgroundColor: "#2167BC",
    },
  },
  CustomSuccessButton: {
    backgroundColor: "#3B8010",
    color: "#fff",
    fontSize: "15px",
    "&:hover": {
      backgroundColor: "#5D9930",
    },
    padding: "0.58rem 1rem",
    display: "inline-flex",
    alignItems: "center",
    borderRadius: "3px",
    verticalAlign: "middle",
    textDecoration: "none",
  },
}));

const AdvertisingClaimFormDetail = ({
  comments,
  userName,
  isDisplay,
  isEdit,
  row,
  type,
  setEdit,
  requirementDataArray,
  co_op_id,
}) => {
  const classes = useStyles();
  const [openPrint, setOpenPrint] = useState(false);
  const [isComment, setIsComment] = useState(false);
  const [text, setText] = useState("");

  const capitalize = (s) => (s && s[0].toUpperCase() + s.slice(1)) || "";

  const handleOpenPrint = () => {
    setOpenPrint(true);
  };
  const handleClosePrint = () => {
    setOpenPrint(false);
  };

  const data1 = [
    ["Dealer Name", row?.dealer_name || ""],
    ["Contact Name", row?.contact_name || ""],
    ["Contact Phone", row?.phone || ""],
    ["Address", row?.address || ""],
    ["Address 2", row?.address2 || ""],
    ["City", row?.city || ""],
    ["State", row?.state || ""],
    ["Country", row?.country || ""],
    ["Zip Code", row?.zipcode || ""],
    ["Fax Number", row?.fax || ""],
    ["Additional Information", row?.additional_info || ""],
  ];

  // Add an empty row for separation
  const separator = [
    ["", ""],
    ["", ""],
  ];

  const headers2 = [
    [
      "Issue Date",
      "Advertising Description",
      "Gross Cost",
      "% Approved",
      "Cost of Approved %",
      "Program Credit",
      "CO-OP Credit",
    ],
  ];

  let data2 = [];
  if (requirementDataArray) {
    data2 = requirementDataArray?.map((item) => [
      `${item?.issue_date || ""}`,
      `${item?.advertising_description || ""}`,
      `$${item?.gross_cost || ""}`,
      `${item?.approved || ""}%`,
      `$${item?.cost_of_approved || ""}`,
      `${item?.program_credit || ""}%`,
      `$${item?.co_op_credit || ""}`,
    ]);
  }
  let combinedData = [...data1, ...separator];
  if (requirementDataArray) {
    combinedData = [...data1, ...separator, ...headers2, ...data2];
  }

  const tableBody = (
    <tbody>
      <tr>
        <td>Dealer Name:</td>
        <td>{row?.dealer_name || ""}</td>
      </tr>
      <tr>
        <td>Contact Name:</td>
        <td>{row?.contact_name || ""}</td>
      </tr>
      <tr>
        <td>Contact Phone:</td>
        <td>{row?.phone || ""}</td>
      </tr>
      <tr>
        <td>Fax Number:</td>
        <td>{row?.fax || ""}</td>
      </tr>
      <tr>
        <td>Address:</td>
        <td>{row?.address || ""}</td>
      </tr>
      <tr>
        <td>Address 2:</td>
        <td>{row?.address2 || ""}</td>
      </tr>
      <tr>
        <td>Claim Date/Time:</td>
        <td>{row?.date_submitted || ""}</td>
      </tr>
      <tr>
        <td>Contact Name:</td>
        <td>{row?.profile?.name || ""}</td>
      </tr>
      <tr>
        <td>Contact Email:</td>
        <td>{row?.profile?.email || ""}</td>
      </tr>
      <tr>
        <td>City:</td>
        <td>{row?.city || ""}</td>
      </tr>
      <tr>
        <td>Country:</td>
        <td>{row?.country || ""}</td>
      </tr>
      <tr>
        <td>State:</td>
        <td>{row?.state || ""}</td>
      </tr>
      <tr>
        <td>Zip Code:</td>
        <td>{row?.zipcode || ""}</td>
      </tr>
      <tr>
        <td>Additional Information:</td>
        <td className="wordBreak">{row?.additional_info || ""}</td>
      </tr>
    </tbody>
  );

  const generatePdf = () => {
    const input = document.getElementById("pdf-content");
    html2canvas(input, { scale: 1 })
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "pt", "a4");
        pdf.addImage(imgData, "PNG", 0, 0);
        pdf.save(`advertising_claim_${row?.dealer_name}.pdf`);
      })
      .catch((err) => {
        console.error("Error generating PDF: ", err);
      });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" || event.key === " ") {
      handleClosePrint();
    }
  };

  const columns = [
    {
      name: "issue_date",
      title: "Issue Date",
    },
    {
      name: "advertising_description",
      title: "Advertising Description",
    },
    {
      name: "gross_cost",
      title: "Gross Cost",
      getCellValue: (row) => `$${row?.gross_cost}`,
    },
    {
      name: "approved",
      title: "% Approved",
      getCellValue: (row) => `${row?.approved}%`,
    },
    {
      name: "cost_of_approved",
      title: "Cost of Approved",
      getCellValue: (row) => `$${row?.cost_of_approved}`,
    },
    {
      name: "program_credit",
      title: "Program Credit%",
      getCellValue: (row) => `${row?.program_credit}%`,
    },
    {
      name: "co_op_credit",
      title: "CO-OP Credit",
      getCellValue: (row) => `$${row?.co_op_credit}`,
    },
  ];

  return (
    <div className="request-detail-pg admin-side">
      {type === "dealer" && (
        <>
          {isEdit && (
            <Button
              color="primary"
              variant="contained"
              className={classes.PrimaryButton}
              type="submit"
              onClick={() => setEdit({ data: row })}
              style={{ margin: "2em 1em 2em 0em" }}
            >
              Edit
            </Button>
          )}
          <Button
            className={`${classes.PrimaryButton} MuiButtonBase-root MuiButton-root MuiButton-containedSecondary MuiButton-contained`}
            onClick={generatePdf}
          >
            <>
              <img
                src={PDFimg}
                alt="PDF"
                width="18px"
                style={{ marginRight: "8px" }}
              />
              Print To Pdf
            </>
          </Button>
          <div id="pdf-content" className="offscreen">
            <AdvertisingClaimFormPDFDetail
              data={row}
              requirementData={requirementDataArray}
            />
          </div>
        </>
      )}
      {type === "admin" && (
        <>
          <Button
            color="primary"
            variant="contained"
            className={classes.PrimaryButton}
            type="submit"
            onClick={handleOpenPrint}
            style={{ margin: "2em 2em 2em 0em" }}
          >
            {" "}
            <img
              src={PDFimg}
              alt="PDF"
              width="18px"
              style={{ marginRight: "8px" }}
            />
            PRINT TO PDF
          </Button>
          <div id="pdf-content" className="offscreen">
            <AdvertisingClaimFormPDFDetail
              data={row}
              requirementData={requirementDataArray}
            />
          </div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={openPrint}
            width="fullWidth"
            onClose={handleClosePrint}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={openPrint}>
              <div className={classes.paper}>
                <div className="customModalBody">
                  <table
                    cellSpacing="0"
                    className="request-detail-table"
                    width="100%"
                    style={{ padding: "0px" }}
                  >
                    {tableBody}
                  </table>
                  <Typography
                    gutterBottom
                    variant="h5"
                    className={classes.subtitle3}
                    align="left"
                    color="primary"
                  >
                    Photo(s)/Video Files:
                  </Typography>
                  <br></br>
                  <div className="imgs-row-section">
                    <Grid container spacing={4}>
                      {row?.paid_invoice_url && (
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                          <div className="imgs-itembox">
                            {isPdf(row?.paid_invoice_url) ? (
                              <>
                                <a
                                  href={`${process.env.REACT_APP_MEDIA_URL}/${row?.paid_invoice_url}`}
                                  target="_blank"
                                >
                                  <img
                                    src={PdfImage}
                                    alt="paid_invoice_url"
                                    className="#"
                                    height={200}
                                    weight={200}
                                  />
                                </a>
                              </>
                            ) : (
                              <img
                                src={`${process.env.REACT_APP_MEDIA_URL}/${row?.paid_invoice_url}`}
                                alt="paid_invoice_url"
                                className="#"
                              />
                            )}
                          </div>
                        </Grid>
                      )}
                      {row?.tear_sheet_url && (
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                          <div className="imgs-itembox">
                            {isPdf(row?.tear_sheet_url) ? (
                              <>
                                <a
                                  href={`${process.env.REACT_APP_MEDIA_URL}/${row?.tear_sheet_url}`}
                                  target="_blank"
                                >
                                  <img
                                    src={PdfImage}
                                    alt="tear_sheet_url"
                                    className="#"
                                    height={200}
                                    weight={200}
                                  />
                                </a>
                              </>
                            ) : (
                              <img
                                src={`${process.env.REACT_APP_MEDIA_URL}/${row?.tear_sheet_url}`}
                                alt="tear_sheet_url"
                                className="#"
                              />
                            )}
                          </div>
                        </Grid>
                      )}
                      {row?.claim_form_url && (
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                          <div className="imgs-itembox">
                            {isPdf(row?.claim_form_url) ? (
                              <>
                                <a
                                  href={`${process.env.REACT_APP_MEDIA_URL}/${row?.claim_form_url}`}
                                  target="_blank"
                                >
                                  <img
                                    src={PdfImage}
                                    alt="claim_form_url"
                                    className="#"
                                    height={200}
                                    weight={200}
                                  />
                                </a>
                              </>
                            ) : (
                              <img
                                src={`${process.env.REACT_APP_MEDIA_URL}/${row?.claim_form_url}`}
                                alt="claim_form_url"
                                className="#"
                              />
                            )}
                          </div>
                        </Grid>
                      )}
                      {row?.affidavit_url && (
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                          <div className="imgs-itembox">
                            {isPdf(row?.affidavit_url) ? (
                              <>
                                <a
                                  href={`${process.env.REACT_APP_MEDIA_URL}/${row?.affidavit_url}`}
                                  target="_blank"
                                >
                                  <img
                                    src={PdfImage}
                                    alt="affidavit_url"
                                    className="#"
                                    height={200}
                                    weight={200}
                                  />
                                </a>
                              </>
                            ) : (
                              <img
                                src={`${process.env.REACT_APP_MEDIA_URL}/${row?.affidavit_url}`}
                                alt="affidavit_url"
                                className="#"
                              />
                            )}
                          </div>
                        </Grid>
                      )}
                    </Grid>
                  </div>

                  <span
                    onKeyDown={handleKeyPress}
                    onClick={() => handleClosePrint()}
                  >
                    <Button
                      className={`${classes.PrimaryButton} MuiButtonBase-root MuiButton-root MuiButton-containedSecondary MuiButton-contained`}
                      onClick={generatePdf}
                    >
                      <>
                        <img
                          src={PDFimg}
                          alt="PDF"
                          width="18px"
                          style={{ marginRight: "8px" }}
                        />
                        Print To Pdf
                      </>
                    </Button>
                  </span>
                  <Button
                    color="secondary"
                    variant="contained"
                    className={classes.Button}
                    onClick={() => handleClosePrint()}
                    style={{ width: "120px", margin: "2em 1em" }}
                  >
                    CLOSE
                  </Button>
                </div>
              </div>
            </Fade>
          </Modal>
          <CSVLink
            data={combinedData}
            filename={"advertising_claim_" + row?.dealer_name + ".csv"}
            className={classes.CustomSuccessButton}
          >
            <img
              src={excelimg}
              alt="PDF"
              width="20px"
              style={{ marginRight: "8px" }}
            />
            Export to EXCEL
          </CSVLink>
        </>
      )}
      <table cellSpacing="0" className="request-detail-table" width="100%">
        {tableBody}
      </table>
      {row?.advertising_claim_form_data_requirements?.length > 0 && (
        <Grids
          rows={row?.advertising_claim_form_data_requirements}
          columns={columns}
        >
          <Table />
          <TableHeaderRow />
        </Grids>
      )}
      <table cellSpacing="0" className="request-detail-table" width="100%">
        <tbody>
          {comments && comments?.length > 0 && (
            <tr>
              <td>Comments:</td>
              <td>{""}</td>
            </tr>
          )}
          {comments &&
            comments?.length > 0 &&
            comments.map((item) => {
              return (
                <tr>
                  {item?.status ? (
                    <td>{capitalize(item?.status)} Comments:</td>
                  ) : (
                    <td>
                      <div>{item?.profile_name || ""}</div>
                      <span>
                        {moment(item?.created_at).format("MMMM D, YYYY h:mm a")}
                      </span>
                    </td>
                  )}
                  <td>
                    <div
                      dangerouslySetInnerHTML={{ __html: item?.comment || "" }}
                    ></div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <Typography
        gutterBottom
        variant="h5"
        className={classes.subtitle3}
        align="left"
      >
        Photo(s)/Video Files:
      </Typography>
      <br></br>
      <div className="imgs-row-section">
        <Grid container spacing={4}>
          {row?.paid_invoice_url && (
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <div className="imgs-itembox">
                {isPdf(row?.paid_invoice_url) ? (
                  <>
                    <a
                      href={`${process.env.REACT_APP_MEDIA_URL}/${row?.paid_invoice_url}`}
                      target="_blank"
                    >
                      <img
                        src={PdfImage}
                        alt="paid_invoice_url"
                        className="#"
                        height={200}
                        weight={200}
                      />
                    </a>
                  </>
                ) : (
                  <img
                    src={`${process.env.REACT_APP_MEDIA_URL}/${row?.paid_invoice_url}`}
                    alt="paid_invoice_url"
                    className="#"
                  />
                )}
              </div>
            </Grid>
          )}
          {row?.tear_sheet_url && (
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <div className="imgs-itembox">
                {isPdf(row?.tear_sheet_url) ? (
                  <>
                    <a
                      href={`${process.env.REACT_APP_MEDIA_URL}/${row?.tear_sheet_url}`}
                      target="_blank"
                    >
                      <img
                        src={PdfImage}
                        alt="tear_sheet_url"
                        className="#"
                        height={200}
                        weight={200}
                      />
                    </a>
                  </>
                ) : (
                  <img
                    src={`${process.env.REACT_APP_MEDIA_URL}/${row?.tear_sheet_url}`}
                    alt="tear_sheet_url"
                    className="#"
                  />
                )}
              </div>
            </Grid>
          )}
          {row?.claim_form_url && (
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <div className="imgs-itembox">
                {isPdf(row?.claim_form_url) ? (
                  <>
                    <a
                      href={`${process.env.REACT_APP_MEDIA_URL}/${row?.claim_form_url}`}
                      target="_blank"
                    >
                      <img
                        src={PdfImage}
                        alt="claim_form_url"
                        className="#"
                        height={200}
                        weight={200}
                      />
                    </a>
                  </>
                ) : (
                  <img
                    src={`${process.env.REACT_APP_MEDIA_URL}/${row?.claim_form_url}`}
                    alt="claim_form_url"
                    className="#"
                  />
                )}
              </div>
            </Grid>
          )}
          {row?.affidavit_url && (
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <div className="imgs-itembox">
                {isPdf(row?.affidavit_url) ? (
                  <>
                    <a
                      href={`${process.env.REACT_APP_MEDIA_URL}/${row?.affidavit_url}`}
                      target="_blank"
                    >
                      <img
                        src={PdfImage}
                        alt="affidavit_url"
                        className="#"
                        height={200}
                        weight={200}
                      />
                    </a>
                  </>
                ) : (
                  <img
                    src={`${process.env.REACT_APP_MEDIA_URL}/${row?.affidavit_url}`}
                    alt="affidavit_url"
                    className="#"
                  />
                )}
              </div>
            </Grid>
          )}
        </Grid>
      </div>
      <hr style={{ backgroundColor: "#BED4E5" }}></hr>

      {type === "admin" && (
        <AdminStatusButtons
          co_op_id={co_op_id}
          id={row?.id}
          type="co-op-advertising-claim"
          statusType={row?.status}
        />
      )}
      {!isDisplay && (
        <>
          {!isComment && (
            <Button onClick={() => setIsComment(true)}>
              Add your comments here
            </Button>
          )}
          {isComment && (
            <CommentBox
              co_op_id={co_op_id}
              isComment={isComment}
              setIsComment={setIsComment}
              text={text}
              setText={setText}
              userName={userName}
            />
          )}
        </>
      )}
    </div>
  );
};
export default withStyles(styles)(AdvertisingClaimFormDetail);
