import React, { Component } from "react";
import { Mutation } from "react-apollo";
import { gql } from "apollo-boost";
import { Button } from "@material-ui/core";

const styles = {
  button: {
    margin: 8,
    width: "205px"
  },
  actions: {
      display: 'flex',
      justifyContent: 'center'
  },
  completedContainer: {
      textAlign: 'center',
      padding: '2em 0px'
  },
  completedTitle: {
      fontSize: 14,
      color: '#1f3340',
      textTransform: 'uppercase',
      textAlign: 'center'  
  }
};

const UPDATE_NEW_DEALERS = gql`
  mutation updateNewDealerFulfilledBy(
    $id: uuid!
    $fulfilledby: String!
    $fulfilledon: date!
  ) {
    update_new_dealer_requests(
      where: { id: { _eq: $id } }
      _set: { fulfilledby: $fulfilledby, fulfilledon: $fulfilledon }
    ) {
      affected_rows
    }
  }
`;

export default class NewDealerFulfilledBy extends Component {
  constructor({ tag, id }) {
    super();
    this.state = {
      tag: tag,
      id: id
    };
  }

  componentDidUpdate = prevProps => {
    if (this.props.tag !== prevProps.tag) {
      this.setState({
        tag: this.props.tag
      });
    }
  };

  componentWillReceiveProps = () => {
    //console.log("componentWillReceiveProps");
  };

  completedAdd = payload => {
    this.setState({ tag: "fulfilledby" });
  };

  render() {
    const tag = this.state.tag;
    return (
      <Mutation mutation={UPDATE_NEW_DEALERS}>
        {(newdealers, { data }) => {
          if (tag) {
            return (
              <div style={styles.completedContainer}>
                {" "}
                <span style={styles.completedTitle}>Fulfilled {tag}</span>
              </div>
            );
          } else {
            return (
              <div style={styles.completedContainer}>
                <p style={styles.completedTitle}>Completed New Dealer By:</p>
                <div style={styles.actions}>
                  <Button
                    variant="contained"
                    color="secondary"
                    style={styles.button}
                    onClick={e => {
                      newdealers({
                        variables: {
                          id: this.state.id,
                          fulfilledby: "via Email",
                          fulfilledon: "now()"
                        }
                      });
                      this.completedAdd();
                    }}
                  >
                    Via Email
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    style={styles.button}
                    onClick={e => {
                     newdealers({
                        variables: {
                          id: this.state.id,
                          fulfilledby: "Via Phone",
                          fulfilledon: "now()"
                        }
                      });
                      this.completedAdd();
                    }}
                  >
                    Via Phone
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    style={styles.button}
                    onClick={e => {
                      newdealers({
                        variables: {
                          id: this.state.id,
                          fulfilledby: "Via Other",
                          fulfilledon: "now()"
                        }
                      });
                      this.completedAdd();
                    }}
                  >
                    Via Other
                  </Button>
                </div>
              </div>
            );
          }
        }}
      </Mutation>
    );
  }
}
