import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router";
import compose from "recompose/compose";
import PropTypes from "prop-types";
const styles = {};

function EditDealerDetails(props) {
    var has_skiff, has_seachaser, has_funchaser =""
    const { item } = props;
    //console.log(item)
    if(item.has_skiff === true) {
        has_skiff = "CAROLINA SKIFF"
    } 
    if(item.has_seachaser === true) {
        has_seachaser = "SEA CHASER"
    } 
    if(item.has_funchaser === true) {
        has_funchaser = "FUN CHASER"
    }
    return (
        <div>
            {has_skiff && has_seachaser && has_funchaser ? (  
                "All"
            ) : (
            <div>
                {has_skiff} {has_seachaser} {has_funchaser} 
            </div>
            )}
        </div>
    );
}

EditDealerDetails.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(withRouter, withStyles(styles))(EditDealerDetails);