import auth0 from "auth0-js";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";

const AUTH_CONFIG = {
  roleUrl: "https://rbac-tutorial-app/role",
  permissionsUrl: "https://rbac-tutorial-app/permissions"
};

class Auth {
  constructor() {
    this.auth0 = new auth0.WebAuth({
      domain: "dev-v-8-slj6.auth0.com",
      clientID: "MzOcwoze6f529Uk5GQOXLFHv5bvjetOV",
      redirectUri: `${process.env.REACT_APP_PUBLIC_URL}/callback`,
      responseType: "token id_token",
      scope: "openid email profile"
    });

    this.cookies = new Cookies();
    //this.authFlag = 'isLoggedIn';
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
  }

  login() {
    this.auth0.authorize();
  }

  getIdToken() {
    if (!this.idToken) {
      this.idToken = this.cookies.get("idToken");
    }
    return this.idToken;
  }

  getSub() {
    if (!this.sub) {
      this.sub = this.cookies.get("sub");
    }
    return this.sub;
  }

  getNickName() {
    if (!this.nickname) {
      this.nickname = this.cookies.get("nickname");
    }
    return this.nickname;
  }
  getEmail() {
    if (!this.email) {
      this.email = this.cookies.get("email");
    }
    return this.email;
  }
  getRole() {
    if (!this.role) {
      this.role = this.cookies.get("role");
    }
    return this.role;
  }
  getPicture() {
    if (!this.picture) {
      this.picture = this.cookies.get("picture");
    }
    return this.picture;
  }

  getExpiresAt() {
    if (!this.expiresAt) {
      this.expiresAt = this.cookies.get("expiresAt");
    }
    return this.expiresAt;
  }

  handleAuthentication() {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (err) {
          toast(err?.errorDescription);
          setTimeout(() => {
            window.location.href = "/";
          },2000)
          return reject(err);
        }
        if (!authResult || !authResult.idToken) {

          return reject(err);
        }
        this.setSession(authResult);
        resolve();
      });
    });
  }

  setPermissions(permissions) {
    this.cookies.set("permissions", permissions);
  }

  getPermissions() {
    if (!this.permissions) {
      this.permissions = this.cookies.get("permissions");
    }
    return this.permissions;
  }

  setSession(authResult) {
    console.log(authResult,"authResult")
    this.idToken = authResult.idToken;
    this.cookies.set("idToken", authResult.idToken);
    this.cookies.set("sub", authResult.idTokenPayload.sub);
    this.cookies.set("nickname", authResult.idTokenPayload.nickname);
    this.cookies.set("email", authResult.idTokenPayload.email);
    this.cookies.set("picture", authResult.idTokenPayload.picture);
    this.cookies.set("role", authResult.idTokenPayload[AUTH_CONFIG.roleUrl]);
    // set the time that the id token will expire at
    //console.log("TEST PERMISSIONS");
    //console.log(authResult.idTokenPayload[AUTH_CONFIG.permissionsUrl]);
    this.cookies.set("permissions", authResult.idTokenPayload[AUTH_CONFIG.permissionsUrl]);
    this.expiresAt = authResult.expiresIn * 1000 + new Date().getTime();
    this.cookies.set("expiresAt", this.expiresAt);
    //console.log(authResult.idTokenPayload[AUTH_CONFIG.roleUrl]);
  }

  logout() {
    this.cookies.remove("idToken");
    this.cookies.remove("expiresAt");
    this.cookies.remove("sub");
    this.cookies.remove("nickname");
    this.cookies.remove("email");
    this.cookies.remove("picture");
    this.cookies.remove("role");
    this.cookies.remove("permissions");
    this.auth0.logout({
      returnTo: process.env.REACT_APP_PUBLIC_URL,
      clientID: "MzOcwoze6f529Uk5GQOXLFHv5bvjetOV"
    });
  }

  loadSession() {
    if (this.isAuthenticated()) {
      return new Promise((resolve, reject) => {
        // not returning from Auth0 (no hash)
        if (!window.location.hash) {
          this.auth0.checkSession({}, (err, authResult) => {
            if (err) return reject(err);
            if (!authResult || !authResult.idToken || !authResult.accessToken) {
              return resolve(false);
            }
            this.setSession(authResult);
            resolve(true);
          });
          //return;
        }

        // returning from Auth0
        this.auth0.parseHash((err, authResult) => {
          if (err) return reject(err);
          // clear hash
          window.history.replaceState(null, null, " ");
          this.setSession(authResult);
          resolve(true);
        });
      });
    }
  }
  // silentAuth() {
  //   console.log("silent")
  //   if(this.isAuthenticated()) {
  //     return new Promise((resolve, reject) => {
  //       this.auth0.checkSession({}, (err, authResult) => {
  //         console.log("err/r", err, authResult)
  //         if (err) {
  //           localStorage.removeItem(this.authFlag);
  //           return reject(err);
  //         }
  //         this.setSession(authResult);
  //         resolve();
  //       });
  //     });
  //   }
  // }

  isAuthenticated() {
    // Check whether the current time is past the token's expiry time
    return new Date().getTime() < this.getExpiresAt();
  }
}

const auth = new Auth();

export default auth;
