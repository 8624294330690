import { gql } from "apollo-boost";

export const ADD_MEDIA_PRE_APPROVAL_FORMS = gql`
  mutation insert_media_pre_approval_forms(
    $objects: [media_pre_approval_forms_insert_input!]!
  ) {
    insert_media_pre_approval_forms(objects: $objects) {
      returning {
        id
      }
    }
  }
`;

export const getAllMediaPreApprovalForms = gql`
  subscription media_pre_approval_forms($profile_id: String!) {
    media_pre_approval_forms(
      where: { profile_id: { _eq: $profile_id } }
      order_by: { created_at: desc }
    ) {
      id
      status
      date_submitted
      created_at
      dealer_name
      contact_name
      phone
      address
      address2
      state
      country
      city
      zipcode
      fax
      advertising_title
      media_name
      approved_credit
      additional_info
      co_op_request {
        id
        co_op_comments {
          id
          co_op_id
          comment
          status
          created_at
          profile_name
        }
      }
    }
  }
`;

export const ADD_SPECIAL_PROJECT_FORMS = gql`
  mutation insert_special_project_forms(
    $objects: [special_project_forms_insert_input!]!
  ) {
    insert_special_project_forms(objects: $objects) {
      returning {
        id
      }
    }
  }
`;

export const getAllSpecialProjectForms = gql`
  subscription special_project_forms($profile_id: String!) {
    special_project_forms(
      where: { profile_id: { _eq: $profile_id } }
      order_by: { created_at: desc }
    ) {
      created_at
      id
      status
      date_submitted
      dealer_name
      contact_name
      phone
      address
      address2
      city
      state
      country
      zipcode
      fax
      additional_info
      description
      total_cost
      devoted
      cost_devoted
      total_credit
      co_op_request {
        id
        co_op_comments {
          id
          co_op_id
          comment
          status
          created_at
          profile_name
        }
      }
    }
  }
`;

export const GET_MEDIA_PRE_APPROVAL_FORMS_INFO = gql`
  query media_pre_approval_forms($id: uuid!) {
    media_pre_approval_forms_by_pk(id: $id) {
      id
      status
      date_submitted
      dealer_name
      contact_name
      phone
      address
      address2
      city
      zipcode
      fax
      advertising_title
      media_name
      approved_credit
      additional_info
      country
      state
    }
  }
`;
export const GET_SPECIAL_PROJECT_FORMS_INFO = gql`
  query special_project_forms($id: uuid!) {
    special_project_forms_by_pk(id: $id) {
      id
      status
      date_submitted
      dealer_name
      contact_name
      phone
      address
      address2
      city
      state
      country
      zipcode
      fax
      additional_info
      description
      total_cost
      devoted
      cost_devoted
      total_credit
    }
  }
`;

export const UPDATE_MEDIA_PRE_APPROVAL_FORM = gql`
  mutation update_media_pre_approval_forms(
    $id: uuid!
    $dealer_name: String
    $contact_name: String
    $phone: String
    $address: String
    $address2: String
    $city: String
    $zipcode: String
    $fax: String
    $advertising_title: String
    $media_name: String
    $approved_credit: String
    $additional_info: String
    $country: String
    $state: String
  ) {
    update_media_pre_approval_forms(
      where: { id: { _eq: $id } }
      _set: {
        dealer_name: $dealer_name
        contact_name: $contact_name
        phone: $phone
        address: $address
        address2: $address2
        city: $city
        zipcode: $zipcode
        fax: $fax
        advertising_title: $advertising_title
        media_name: $media_name
        approved_credit: $approved_credit
        additional_info: $additional_info
        country: $country
        state: $state
      }
    ) {
      affected_rows
    }
  }
`;
export const UPDATE_SPECIAL_PROJECT_FORM = gql`
  mutation update_special_project_forms(
    $id: uuid!
    $dealer_name: String
    $contact_name: String
    $phone: String
    $address: String
    $address2: String
    $city: String
    $zipcode: String
    $fax: String
    $additional_info: String
    $country: String
    $state: String
    $description: String
    $total_cost: String
    $devoted: String
    $cost_devoted: String
    $total_credit: String
  ) {
    update_special_project_forms(
      where: { id: { _eq: $id } }
      _set: {
        dealer_name: $dealer_name
        contact_name: $contact_name
        phone: $phone
        address: $address
        address2: $address2
        city: $city
        zipcode: $zipcode
        fax: $fax
        country: $country
        state: $state
        description: $description
        total_cost: $total_cost
        devoted: $devoted
        cost_devoted: $cost_devoted
        total_credit: $total_credit
        additional_info: $additional_info
      }
    ) {
      affected_rows
    }
  }
`;

export const ADD_COMMERICAL_SALES_CREDIT_CLAIM = gql`
  mutation insert_government_commerical_sales_credit_claim(
    $objects: [government_commerical_sales_credit_claim_insert_input!]!
  ) {
    insert_government_commerical_sales_credit_claim(objects: $objects) {
      returning {
        id
      }
    }
  }
`;

export const getAllCommericalSalesCreditClaim = gql`
  subscription government_commerical_sales_credit_claim($profile_id: String!) {
    government_commerical_sales_credit_claim(
      where: { profile_id: { _eq: $profile_id } }
      order_by: { created_at: desc }
    ) {
      id
      status
      date_submitted
      created_at
      dealer_name
      contact_name
      phone
      address
      address2
      city
      country
      state
      zipcode
      fax
      additional_info
      file1
      file2
      file3
      file4
      profile {
        email
        name
      }
      government_commerical_sales_credit_claim_data(
        order_by: { created_at: desc }
      ) {
        id
        government_commerical_sales_credit_claim_id
        buyer_name
        item_name
        serial_no
        dealer_cost
        credit
        total_credit
      }
      co_op_request {
        id
        co_op_comments {
          id
          comment
          co_op_id
          status
          profile_name
          created_at
        }
      }
    }
  }
`;

export const ADD_COMMERICAL_SALES_CREDIT_CLAIM_DATA = gql`
  mutation insert_government_commerical_sales_credit_claim_data(
    $objects: [government_commerical_sales_credit_claim_data_insert_input!]!
  ) {
    insert_government_commerical_sales_credit_claim_data(objects: $objects) {
      returning {
        id
      }
    }
  }
`;

export const DELETE_COMMERICAL_SALES_CREDIT_CLAIM_DATA = gql`
  mutation deleteGovernmentCommericalSalesCreditClaimData($ids: [uuid!]!) {
    delete_government_commerical_sales_credit_claim_data(
      where: { id: { _in: $ids } }
    ) {
      affected_rows
    }
  }
`;

export const GET_COMMERCIAL_SALES_CREDIT_CLAIM_INFO = gql`
  query government_commerical_sales_credit_claim_by_pk($id: uuid!) {
    government_commerical_sales_credit_claim_by_pk(id: $id) {
      id
      status
      date_submitted
      dealer_name
      contact_name
      phone
      address
      address2
      city
      zipcode
      country
      state
      fax
      additional_info
      country
      state
      file1
      file2
      file3
      file4
      government_commerical_sales_credit_claim_data(
        order_by: { created_at: asc }
      ) {
        id
        government_commerical_sales_credit_claim_id
        buyer_name
        item_name
        serial_no
        dealer_cost
        credit
        total_credit
      }
    }
  }
`;
export const GET_COMMERCIAL_SALES_CREDIT_CLAIM_DATA_INFO = gql`
  query government_commerical_sales_credit_claim_data(
    $government_commerical_sales_credit_claim_id: uuid!
  ) {
    government_commerical_sales_credit_claim_data(
      where: {
        government_commerical_sales_credit_claim_id: {
          _eq: $government_commerical_sales_credit_claim_id
        }
      }
    ) {
      id
      government_commerical_sales_credit_claim_id
      buyer_name
      item_name
      serial_no
      dealer_cost
      credit
      total_credit
    }
  }
`;
export const UPDATE_COMMERICAL_SALES_CREDIT_CLAIM = gql`
  mutation update_government_commerical_sales_credit_claim(
    $id: uuid!
    $dealer_name: String
    $contact_name: String
    $phone: String
    $address: String
    $address2: String
    $city: String
    $zipcode: String
    $fax: String
    $additional_info: String
    $country: String
    $state: String
    $file1: String
    $file2: String
    $file3: String
    $file4: String
    $date_submitted: date
    $status: String
  ) {
    update_government_commerical_sales_credit_claim(
      where: { id: { _eq: $id } }
      _set: {
        dealer_name: $dealer_name
        contact_name: $contact_name
        phone: $phone
        address: $address
        address2: $address2
        city: $city
        zipcode: $zipcode
        fax: $fax
        country: $country
        state: $state
        additional_info: $additional_info
        file1: $file1
        file2: $file2
        file3: $file3
        file4: $file4
        date_submitted: $date_submitted
        status: $status
      }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_COMMERICAL_SALES_CREDIT_CLAIM_DATA = gql`
  mutation update_government_commerical_sales_credit_claim_data(
    $id: uuid!
    $government_commerical_sales_credit_claim_id: uuid
    $buyer_name: String
    $item_name: String
    $serial_no: String
    $dealer_cost: String
    $credit: String
    $total_credit: String
    $updated_at: timestamptz
  ) {
    update_government_commerical_sales_credit_claim_data(
      where: { id: { _eq: $id } }
      _set: {
        government_commerical_sales_credit_claim_id: $government_commerical_sales_credit_claim_id
        buyer_name: $buyer_name
        item_name: $item_name
        serial_no: $serial_no
        dealer_cost: $dealer_cost
        credit: $credit
        total_credit: $total_credit
        updated_at: $updated_at
      }
    ) {
      affected_rows
    }
  }
`;

export const ADD_ADVERTISING_CLAIM_FORMS = gql`
  mutation insert_advertising_claim_form(
    $objects: [advertising_claim_form_insert_input!]!
  ) {
    insert_advertising_claim_form(objects: $objects) {
      returning {
        id
      }
    }
  }
`;

export const DELETE_ADVERTISING_CLAIM_FORMS = gql`
  mutation delete_advertising_claim_form_data_requirements($ids: [uuid!]!) {
    delete_advertising_claim_form_data_requirements(
      where: { id: { _in: $ids } }
    ) {
      affected_rows
    }
  }
`;

export const ADD_ADVERTISING_CLAIM_REQUIREMENTS_FORMS = gql`
  mutation insert_advertising_claim_form_data_requirements(
    $objects: [advertising_claim_form_data_requirements_insert_input!]!
  ) {
    insert_advertising_claim_form_data_requirements(objects: $objects) {
      returning {
        id
      }
    }
  }
`;

export const GET_ALL_ADVERTISNG_CLAIM_FORMS = gql`
  subscription advertising_claim_form($profile_id: String!) {
    advertising_claim_form(
      where: { profile_id: { _eq: $profile_id } }
      order_by: { created_at: desc }
    ) {
      created_at
      id
      status
      date_submitted
      dealer_name
      contact_name
      phone
      fax
      address
      city
      country
      state
      zipcode
      additional_info
      paid_invoice_url
      tear_sheet_url
      claim_form_url
      affidavit_url
      photo_of_sign_url
      profile {
        email
        name
      }
      co_op_request {
        id
        co_op_comments {
          id
          co_op_id
          comment
          created_at
          profile_name
        }
      }
      advertising_claim_form_data_requirements(order_by: { created_at: asc }) {
        advertising_claim_form_id
        issue_date
        advertising_description
        id
        gross_cost
        approved
        cost_of_approved
        program_credit
        co_op_credit
        created_at
        updated_at
      }
    }
  }
`;

export const ADD_CO_OP_REQUESTS = gql`
  mutation insert_co_op_requests($objects: [co_op_requests_insert_input!]!) {
    insert_co_op_requests(objects: $objects) {
      returning {
        id
      }
    }
  }
`;

export const UPDATE_ADVERTISING_CLAIM_FORM = gql`
  mutation update_advertising_claim_form(
    $id: uuid!
    $additional_info: String
    $address: String
    $address2: String
    $affidavit_url: String
    $claim_form_url: String
    $contact_name: String
    $city: String
    $zipcode: String
    $fax: String
    $dealer_name: String
    $paid_invoice_url: String
    $approved_credit: String
    $tear_sheet_url: String
    $country: String
    $state: String
    $phone: String
    $photo_of_sign_url: String
    $updated_at: timestamptz
  ) {
    update_advertising_claim_form(
      where: { id: { _eq: $id } }
      _set: {
        additional_info: $additional_info
        address: $address
        address2: $address2
        affidavit_url: $affidavit_url
        claim_form_url: $claim_form_url
        contact_name: $contact_name
        city: $city
        zipcode: $zipcode
        fax: $fax
        dealer_name: $dealer_name
        paid_invoice_url: $paid_invoice_url
        photo_of_sign_url: $photo_of_sign_url
        tear_sheet_url: $tear_sheet_url
        country: $country
        state: $state
        updated_at: $updated_at
        phone: $phone
      }
    ) {
      affected_rows
    }
  }
`;

export const GET_ALL_ADVERTISING_CLAIM_REQUIREMENT_FORMS = gql`
  subscription {
    advertising_claim_form_data_requirements {
      id
      advertising_claim_form {
        id
      }
      advertising_description
      co_op_credit
      approved
      cost_of_approved
      created_at
      gross_cost
      issue_date
      program_credit
      updated_at
    }
  }
`;

export const UPDATE_ADVERTISING_CLAIM_REQUIREMENTS_DATA_FORM = gql`
  mutation update_advertising_claim_form_data_requirements(
    $id: uuid!
    $advertising_description: String!
    $approved: String!
    $co_op_credit: String!
    $cost_of_approved: String!
    $gross_cost: String!
    $issue_date: date!
    $program_credit: String!
    $updated_at: timestamptz!
  ) {
    update_advertising_claim_form_data_requirements(
      where: { id: { _eq: $id } }
      _set: {
        advertising_description: $advertising_description
        approved: $approved
        co_op_credit: $co_op_credit
        cost_of_approved: $cost_of_approved
        gross_cost: $gross_cost
        issue_date: $issue_date
        program_credit: $program_credit
        updated_at: $updated_at
      }
    ) {
      affected_rows
    }
  }
`;

export const ADD_CO_OP_REQUEST_FORMS = gql`
  mutation insert_co_op_requests($objects: [co_op_requests_insert_input!]!) {
    insert_co_op_requests(objects: $objects) {
      returning {
        id
      }
    }
  }
`;

export const GET_DEALER_CO_OP_REQUESTS_DATA = gql`
  subscription co_op_requests($profile_id: String!, $status: String!) {
    co_op_requests(
      where: { profile_id: { _eq: $profile_id }, status: { _eq: $status } }
      order_by: { date_submitted: desc }
    ) {
      id
      status
      date_submitted
      form_type
      profile {
        email
      }
      status
      co_op_comments(order_by: { created_at: asc }) {
        id
        comment
        created_at
        status
        profile_name
      }
      advertising_claim_forms {
        id
        status
        date_submitted
        dealer_name
        contact_name
        phone
        fax
        address
        city
        country
        state
        zipcode
        additional_info
        paid_invoice_url
        tear_sheet_url
        claim_form_url
        affidavit_url
        photo_of_sign_url
        profile {
          name
          email
        }
        advertising_claim_form_data_requirements(
          order_by: { created_at: asc }
        ) {
          advertising_claim_form_id
          issue_date
          advertising_description
          id
          gross_cost
          approved
          cost_of_approved
          program_credit
          co_op_credit
          created_at
          updated_at
        }
      }
      media_pre_approval_forms {
        id
        status
        date_submitted
        dealer_name
        contact_name
        phone
        address
        address2
        city
        zipcode
        country
        state
        fax
        advertising_title
        media_name
        approved_credit
        additional_info
        profile {
          name
          email
        }
      }
      special_project_forms {
        id
        status
        date_submitted
        dealer_name
        contact_name
        phone
        address
        address2
        city
        zipcode
        country
        state
        fax
        additional_info
        description
        total_cost
        devoted
        cost_devoted
        total_credit
        profile {
          name
          email
        }
      }
      government_commerical_sales_credit_claims {
        id
        status
        date_submitted
        dealer_name
        contact_name
        phone
        address
        address2
        city
        zipcode
        country
        state
        fax
        additional_info
        profile {
          name
          email
        }
        file1
        file2
        file3
        file4
        government_commerical_sales_credit_claim_data(
          order_by: { created_at: asc }
        ) {
          id
          government_commerical_sales_credit_claim_id
          buyer_name
          item_name
          serial_no
          dealer_cost
          credit
          total_credit
        }
      }
      boat_show_credits {
        created_at
        id
        status
        date_submitted
        dealer_name
        contact_name
        phone
        address
        address2
        city
        state
        country
        zipcode
        fax
        additional_info
        booth_cost
        booth_size
        devoted
        booth_cost
        devoted_cost
        booth_space_url
        booth_space_url_tool
        co_op_request {
          id
          co_op_comments {
            id
            co_op_id
            comment
            status
            created_at
            profile_name
          }
        }
      }
    }
  }
`;

export const ADD_BOAT_REQUEST_FORM = gql`
  mutation insert_boat_show_credit(
    $objects: [boat_show_credit_insert_input!]!
  ) {
    insert_boat_show_credit(objects: $objects) {
      returning {
        id
      }
    }
  }
`;

export const GET_BOAT_CREDIT_DATA = gql`
  subscription boat_show_credit($profile_id: String!) {
    boat_show_credit(
      where: { profile_id: { _eq: $profile_id } }
      order_by: { created_at: desc }
    ) {
      created_at
      id
      status
      date_submitted
      dealer_name
      contact_name
      phone
      address
      address2
      city
      state
      country
      zipcode
      fax
      additional_info
      booth_cost
      booth_size
      devoted
      booth_cost
      devoted_cost
      booth_space_url
      booth_space_url_tool
      co_op_request {
        id
        co_op_comments {
          id
          co_op_id
          comment
          status
          created_at
          profile_name
        }
      }
    }
  }
`;

export const GET_BOAT_SHOW_CREDIT = gql`
  query boat_show_credit($id: uuid!) {
    boat_show_credit_by_pk(id: $id) {
      created_at
      id
      status
      date_submitted
      dealer_name
      contact_name
      phone
      address
      address2
      city
      state
      country
      zipcode
      fax
      additional_info
      booth_cost
      booth_size
      devoted
      booth_cost
      devoted_cost
      booth_space_url
      booth_space_url_tool
      co_op_request {
        id
        co_op_comments {
          id
          co_op_id
          comment
          status
          created_at
          profile_name
        }
      }
    }
  }
`;

export const UPDATE_BOAT_SHOW_CREDIT_FORM = gql`
  mutation update_boat_show_credit(
    $id: uuid!
    $dealer_name: String
    $contact_name: String
    $phone: String
    $fax: String
    $address: String
    $address2: String
    $city: String
    $country: String
    $state: String
    $zipcode: String
    $booth_size: String
    $devoted: String
    $booth_cost: String
    $devoted_cost: String
    $additional_info: String
    $updated_at: timestamptz
    $booth_space_url: String
    $booth_space_url_tool: String
    $date_submitted: date
  ) {
    update_boat_show_credit(
      where: { id: { _eq: $id } }
      _set: {
        dealer_name: $dealer_name
        contact_name: $contact_name
        phone: $phone
        fax: $fax
        address: $address
        address2: $address2
        city: $city
        country: $country
        state: $state
        zipcode: $zipcode
        booth_size: $booth_size
        devoted: $devoted
        booth_cost: $booth_cost
        devoted_cost: $devoted_cost
        additional_info: $additional_info
        updated_at: $updated_at
        booth_space_url: $booth_space_url
        booth_space_url_tool: $booth_space_url_tool
        date_submitted: $date_submitted
      }
    ) {
      affected_rows
    }
  }
`;
