import React, { useState } from "react";
import auth from "Auth";
import {
  SelectionState,
  RowDetailState,
  PagingState,
  IntegratedPaging,
  IntegratedSelection,
  FilteringState,
  IntegratedFiltering
} from "@devexpress/dx-react-grid";
import {
  Grid as DataGrid,
  Table,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  PagingPanel,
  TableFilterRow
} from "@devexpress/dx-react-grid-material-ui";
import { withRouter } from "react-router";
import compose from "recompose/compose";
import { DataTypeProvider } from '@devexpress/dx-react-grid';

// Shared layouts
import { getDealers } from "queries";
import { Subscription } from "react-apollo";
// Externals
import PropTypes from "prop-types";

// Material helpers
import { Typography, withStyles, Popover, Paper, List, ListItem, ListItemText, ListItemIcon, Checkbox, ListSubheader } from "@material-ui/core";
import EditUserButton from "./EditUserButton";
import EditDealerDetails from "./EditDealerDetails";
import ArchiveUserButton from "./ArchiveUserButton";
import UnArchiveUserButton from "./UnArchiveUserButton";
import DealersGridCommandIcons from "./DealersGridCommandIcons";
const RowDetail = ({ row }) => (
  <div>
    <table cellSpacing="0" className="detailTable">
      <tbody>
        <tr>
          <td>Full Name:</td>
          <td>{row.name}</td>
        </tr>
        <tr>
          <td>Email:</td>
          <td>{row.email}</td>
        </tr>
        <tr>
          <td>Phone:</td>
          <td>{row.phone}</td>
        </tr>
        <tr>
          <td>Address:</td>
          <td>
            {row.address}&nbsp;{row.address2}<br/>
            {row.city}&nbsp;{row.region}, {row.country} {row.zipcode}<br/>
          </td>
        </tr>
        <tr>
          <td>Brands:</td>
          <td><EditDealerDetails item={row} /></td>
        </tr>
        <tr>
          <td>
            <EditUserButton item={row} />
          </td>
          <td>
            {row.status === "archived" ? ( 
              <UnArchiveUserButton item={row} />
            ) : (
              <ArchiveUserButton item={row} />
            )}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
);

const styles = theme => ({
  button: {
    margin: theme.spacing()
  },
  input: {
    display: "none"
  },
  root: {
    padding: theme.spacing()
  },
  item: {
    height: "100%"
  },
  marginFix: {
    marginTop: 19,
    marginBottom: 10,
    marginLeft: 5,
    marginRight: 5
  },
  gridContainer: {
    display: 'flex',
    flexDirection: 'column'
  }
});

function DealersGrid(props) {
  const { classes } = props;
  const [columns] = useState([
    { name: "created_at", title: "DATE ADDED" },
    { name: "company", title: "NAME" },
    { name: "brand", title: "BRAND",
      getCellValue: row =>
      <EditDealerDetails item={row} />
          ? <EditDealerDetails item={row} />
          : undefined
    },
    { name: "email", title: "EMAIL" }
  ]);
  const [selection, setSelection] = useState([]);
  const [dateColumns] = useState(['created_at']);
  const getRowId = row => row.profile_id;
  const DateFormatter = ({ value }) => value.replace(/(\d{4})-(\d{2})-(\d{2})(T\d{2}:\d{2}:\d{2}.\d{5,6}\+\d{2}:\d{2})/, '$2.$3.$1');
  const DateTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={DateFormatter}
    {...props}
  />
);
  const [dealerStatus, setDealerStatus] = useState(localStorage.getItem('includeArchived') === 'true' ? '%' : 'active');

  return (
    <div className={classes.root}>
      <Subscription subscription={getDealers} variables={{ id: auth.getSub(), status: dealerStatus }}>
        {({ data, loading, error }) => {
          if (loading) return <p>Loading...</p>;
          if (error) return <p>Error {JSON.stringify(error)}</p>;
          return (
            <div className={classes.gridContainer}>
              <Typography gutterBottom variant="h2" className="addDealer">
                Total rows selected: {selection.length}
                <DealersGridCommandIcons profiles={data.profiles} saveSettings={(includeArchived) => setDealerStatus(includeArchived ? '%' : 'active')} />
              </Typography>
              <DataGrid
                rows={data.profiles}
                columns={columns}
                getRowId={getRowId}
              >
                <DateTypeProvider
                  for={dateColumns}
                />
                <SelectionState
                  selection={selection}
                  onSelectionChange={setSelection}
                />
                <FilteringState defaultFilters={[]} />
                <IntegratedFiltering />
                <PagingState defaultCurrentPage={0} pageSize={10} />
                <IntegratedSelection />
                <IntegratedPaging />
                <RowDetailState defaultExpandedRowIds={[0]} />
                <Table />
                <TableHeaderRow />
                <TableFilterRow />
                <TableRowDetail contentComponent={RowDetail} />
                <TableSelection showSelectAll />
                <PagingPanel />
              </DataGrid>
            </div>
          );
        }}
      </Subscription>
    </div>
  );
}

DealersGrid.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(withRouter, withStyles(styles))(DealersGrid);
