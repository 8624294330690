import React from 'react'
import {
  Button
} from '@material-ui/core';

const styles = {
  button: {
    margin: 8,
    width: "205px"
  }
};
const WarrantyClaimDenyButton = props => {
  return <Button color="secondary" variant="contained" onClick={props.addClick} style={styles.button}>Approve</Button>
}

export default WarrantyClaimDenyButton