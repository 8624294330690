import React from 'react';
import './../../../CoopPreapprovalClaimForms/NewRequest/PdfDocumentStyles.scss';

export const AdvertisingClaimFormPDFDetail = ({ data, requirementData }) => {

  return (
    <div className="document">
      <div className="page">
        <div className="sectionHeader">CO-OP ADVERTISING CLAIM FORM</div>
        <div className="rowContainer">
          <div className="row">
            <div className="column">
              <p className="field_text">
                Dealer Name: {data?.dealer_name || ""}
              </p>
              <p className="field_text">
                Contact Name: {data?.contact_name || ""}
              </p>
              <p className="field_text">
                Contact Phone: {data?.phone || ""}
              </p>
              <p className="field_text">
                Fax Number: {data?.fax || ""}
              </p>
              <p className="field_text">
                Address: {data?.address || ""}
              </p>
              <p className="field_text">
                Address 2: {data?.address2 || ""}
              </p>
              <p className="field_text">City: {data?.city || ""}</p>
              <p className="field_text">State: {data?.state}</p>
              <p className="field_text">Country: {data?.country || ""} </p>
              <p className="field_text">
                Zip Code: {data?.zipcode || ""}
              </p>
              <p className="field_text" style={{ wordBreak: "break-all", width: "40vw" }}>
                Additional Information: {data?.additional_info || ""}
              </p>

              {requirementData && requirementData?.length > 0 &&
                <>
                  <p className="field_header" style={{ width: "40vw" }}>
                    For qualifications and requirements of the Co-op advertising program, please check your Dealer Manual under the Marketing Guide section.
                  </p>
                  <div>
                    <table className="table" cellPadding={5} cellSpacing={0} style={{ width: "40vw" }}>
                      <tr className="tableRow">
                        <th className="tableColHeader">Issue Date</th>
                        <th className="tableColHeader">Advertising Description</th>
                        <th className="tableColHeader">Gross Cost</th>
                        <th className="tableColHeader">% Approved</th>
                        <th className="tableColHeader">Cost of Approved</th>
                        <th className="tableColHeader">Program Credit</th>
                        <th className="tableColHeader">CO-OP Credit</th>
                      </tr>
                      {requirementData?.map((item) => {
                        return (
                          <tr className="tableRow">
                            <td className="tableCol">
                              <p className="tableCell">{item?.issue_date || ""}</p>
                            </td>
                            <td className="tableCol">
                              <p className="tableCell">{item?.advertising_description || ""}</p>
                            </td>
                            <td className="tableCol">
                              <p className="tableCell">${item?.gross_cost || ""}</p>
                            </td>
                            <td className="tableCol">
                              <p className="tableCell">{item?.approved || ""}%</p>
                            </td>
                            <td className="tableCol">
                              <p className="tableCell">${item?.cost_of_approved || ""}</p>
                            </td>
                            <td className="tableCol">
                              <p className="tableCell">{item?.program_credit || ""}%</p>
                            </td>
                            <td className="tableCol">
                              <p className="tableCell">${item?.co_op_credit || ""}</p>
                            </td>
                          </tr>
                        )
                      }
                      )}
                    </table>
                  </div>
                </>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

