import React from "react";
import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router";
import compose from "recompose/compose";
import PropTypes from "prop-types";

const styles = {
  button: {
    margin: 8
  },
  contentHeaderMenuLink: {
    textDecoration: "none",
    color: "white",
    padding: 8
  },
  content: {
    padding: "16px"
  }
};

function MediaPreApprovalFormEditButton(props) {
  const { item, history } = props;
  function handleEditUser(id) {
    history.push(`/media-pre-approval-form/${id}/edit`);
  }

  return (
    <Button variant="contained" color="primary" style={styles.button} onClick={() => handleEditUser(item.id)}>
      Edit
    </Button>
  );
}

MediaPreApprovalFormEditButton.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(withRouter, withStyles(styles))(MediaPreApprovalFormEditButton);
