import React from "react";
import { Link, withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@material-ui/core";
import {
  AttachMoney,
  DirectionsBoat,
  Description,
  ExpandLess,
  ExpandMore,
  Inbox,
  PermMedia,
  Person,
  Star
} from "@material-ui/icons";
import AddIcon from '@material-ui/icons/Add';
import PauseIcon from '@material-ui/icons/Pause';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';

import "./SidebarContentAdmin.scss";
import auth from "../Auth";
import SidebarLink from "./SidebarLink";
import { compose } from "recompose";

const styles = {
  root: {
    padding: "2px"
  },
  sidebar: {
    width: 256,
    height: "100%"
  },
  sidebarLink: {
    display: "block",
    padding: "16px 0px",
    color: "#757575",
    textDecoration: "none"
  },
  divider: {
    margin: "8px 0",
    height: 1,
    backgroundColor: "#757575"
  },
  content: {
    padding: "4px",
    height: "100%",
    backgroundColor: "white"
  }
};
class ListItemLinkNestedWarranty extends React.Component {
  renderLink = itemProps => <Link to={this.props.to} {...itemProps} />;

  state = {
    open: false
  };

  handleClick = () => {
    this.setState(state => ({ open: !state.open }));
  };
  render() {
    const { icon, primary } = this.props;
    const style = {
      padding: "2px"
    };

    return (
      <div>
        <ListItem button onClick={this.handleClick} style={style}>
          <ListItemIcon className="navItemIcon">{icon}</ListItemIcon>
          <ListItemText className="navLinks" primary={primary} />
          {this.state.open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={this.state.open} timeout="auto" unmountOnExit>
          <List component="div">
            <SidebarLink
              linkDestination="/warranty-claims"
              linkText="View Claims"
            />
            <SidebarLink
              linkDestination="/warranty-registrations"
              linkText="View Registrations"
            />
            <SidebarLink
              linkDestination="/warranty-register"
              linkText="Register"
            />
            <SidebarLink
              linkDestination="/warranty-preauth-claim"
              linkText="Pre-Auth Claim"
            />
            <SidebarLink
              linkDestination="/warranty-download-forms"
              linkText="Download Forms"
            />
          </List>
        </Collapse>
      </div>
    );
  }
}

ListItemLinkNestedWarranty.propTypes = {
  icon: PropTypes.node.isRequired,
  state: PropTypes.object,
  primary: PropTypes.node.isRequired
};

class ListItemLinkNestedFormsDatasheets extends React.Component {
  renderLink = itemProps => <Link to={this.props.to} {...itemProps} />;

  state = {
    open: false,
    childOpen: false
  };

  handleClick = () => {
    this.setState(state => ({ open: !state.open, childOpen: true }));
  };

  handleClickChild = () => {
    this.setState(state => ({ childOpen: !state.childOpen }));
  }

  render() {
    const { icon, primary, history } = this.props;
    const style = {
      padding: "2px"
    };

    return (
      <div className="custom-dropbox"> {/* className={`${this.state.open ? "drop-menu-active" : ""}`} */}
        <ListItem button onClick={this.handleClick} style={style}>
          <ListItemIcon className="navItemIcon">{icon}</ListItemIcon>
          <ListItemText className="navLinks" primary={primary} />
          {this.state.open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={this.state.open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              button // Add the button attribute to make the ListItem clickable
              onClick={this.handleClickChild}
            >
              <Link to="/forms-co-op-pre-approval-claim-forms" className="navLinks">
                <ListItemText primary="CO-OP Pre-Approval Claim Forms" />
              </Link>
            </ListItem>
            <Collapse in={this.state.childOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button>
                  <Link to={"/pre-approval-claim?type=new"}>
                    <AddIcon />
                    <ListItemText primary="New Request" />
                  </Link>
                </ListItem>
                <ListItem button>
                  <Link to="/pre-approval-claim?type=pending">
                    <PauseIcon />
                    <ListItemText primary="Pending" />
                  </Link>
                </ListItem>
                <ListItem button>
                  <Link to="/pre-approval-claim?type=denied">
                    <ClearIcon />
                    <ListItemText primary="Denied" />
                  </Link>
                </ListItem>
                <ListItem button>
                  <Link to="/pre-approval-claim?type=approved">
                    <CheckIcon />
                    <ListItemText primary="Approved" />
                  </Link>
                </ListItem>
              </List>
            </Collapse>
            <ListItem button disabled>
              <Link to="/forms-msrp-datasheets" className="navLinks">
                <ListItemText primary="MSRP Data Sheets" />
              </Link>
            </ListItem>
          </List>
        </Collapse>
      </div>
    );
  }
}

ListItemLinkNestedFormsDatasheets.propTypes = {
  icon: PropTypes.node.isRequired,
  state: PropTypes.object,
  primary: PropTypes.node.isRequired
};

function ListItemLink(props) {
  const { icon, primary, to } = props;
  const style = {
    padding: "2px"
  };
  return (
    <div>
      {auth.isAuthenticated() ? (
        <ListItem button component={Link} to={to} style={style}>
          <ListItemIcon className="navItemIcon">{icon}</ListItemIcon>
          <ListItemText className="navLinks" primary={primary} />
        </ListItem>
      ) : (
        ""
      )}
    </div>
  );
}

function hasPermissions(userPermissions, permission) {
  if (userPermissions.includes("dealer:DEALER_ALL")) return true;

  if (userPermissions.includes(permission)) return true;
  return false;
}

ListItemLink.propTypes = {
  icon: PropTypes.node.isRequired,
  primary: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  nested: PropTypes.string
};

function ComponentProperty(props) {
  const { classes } = props;
  const userPermissions = props.userPermissions;
  const styleSkiffMerch = { padding: "2px" };
  const style = props.style
    ? { ...styles.sidebar, ...props.style }
    : styles.sidebar;

  return (
    <div className={classes.root} style={styles.content}>
      <List component="nav" className={props.root}>
        <ListItemLink
          to="/dashboard"
          primary="Dashboard"
          className="navItemIcon"
          icon={<Inbox />}
        />
        {hasPermissions(userPermissions, "dealer:DEALER_LEADS") ? (
          <ListItemLink
            to="/leads"
            primary="Leads"
            className="navItemIcon"
            icon={<Star />}
          />
        ) : (
          ""
        )}

        {hasPermissions(userPermissions, "dealer:DEALER_WARRANTY") ? (
          <ListItemLinkNestedWarranty
            primary="Warranty"
            className="navItemIcon"
            icon={<Description />}
          />
        ) : (
          ""
        )}
        {hasPermissions(userPermissions, "dealer:DEALER_FORMS_DATASHEETS") ? (
          <ListItemLinkNestedFormsDatasheets
            primary="Forms & Data Sheets"
            className="navItemIcon"
            icon={<Description />}
          />
        ) : (
          ""
        )}
        {hasPermissions(userPermissions, "dealer:DEALER_MEDIA") ? (
          <ListItemLink
            to="/media"
            primary="Media"
            className="navItemIcon"
            icon={<PermMedia />}
          />
        ) : (
          ""
        )}
        {hasPermissions(userPermissions, "dealer:DEALER_ORDER_BOATS") ? (
          ""
        ) : (
          ""
        )}
        {hasPermissions(userPermissions, "dealer:DEALER_CSI") ? (
          <ListItemLink
            to="/csi"
            primary="CSI"
            className="navItemIcon"
            icon={<Star />}
          />
        ) : (
          ""
        )}
        {hasPermissions(userPermissions, "dealer:DEALER_FACTORY_CONTACTS") ? (
          <ListItemLink
            to="/factory-contacts"
            primary="Factory Contacts"
            className="navItemIcon"
            icon={<Person />}
          />
        ) : (
          ""
        )}
        {hasPermissions(userPermissions, "dealer:DEALER_BOAT_SHOWS") ? (
          <ListItemLink
            to="/boat-shows"
            primary="Boat Shows"
            className="navItemIcon"
            icon={<DirectionsBoat />}
          />
        ) : (
          ""
        )}
        {hasPermissions(userPermissions, "dealer:DEALER_SKIFF_MERCHANDISE") ? (
          <ListItem
            button
            to="#"
            style={styleSkiffMerch}
            component={Link}
            onClick={() => window.open('https://carolinaskiff.mybrightsites.com')}
          >
            <ListItemIcon className="navItemIcon"><AttachMoney /></ListItemIcon>
            <ListItemText className="navLinks" primary="Carolina Skiff Merchandise" />
          </ListItem>
        ) : (
          ""
        )}
        {hasPermissions(userPermissions, "dealer:DEALER_SKIFF_MERCHANDISE") ? (
          <ListItem
            button
            to="#"
            style={styleSkiffMerch}
            component={Link}
            onClick={() => window.open('https://seachaser.mybrightsites.com')}
          >
            <ListItemIcon className="navItemIcon"><AttachMoney /></ListItemIcon>
            <ListItemText className="navLinks" primary="Sea Chaser Merchandise" />
          </ListItem>
        ) : (
          ""
        )}
      </List>
    </div>
  );
}

ComponentProperty.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(withRouter, withStyles(styles))(ComponentProperty);
