import React from "react";
import { Query } from "react-apollo";
import { getAllWarranties, getWarranties } from "../../queries";
import PropTypes from "prop-types";
import {
  Button,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { Print } from "@material-ui/icons";
import {
  SelectionState,
  RowDetailState,
  SearchState,
  PagingState,
  CustomPaging,
  IntegratedPaging,
  IntegratedFiltering,
  IntegratedSelection,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  PagingPanel,
  Toolbar,
  SearchPanel,
} from "@devexpress/dx-react-grid-material-ui";
import {
  Grid as MaterialGrid,
  Paper as MaterialPaper,
  Typography as MaterialTypography,
} from "@material-ui/core";
// Shared layouts
import { Dashboard as DashboardLayout } from "layouts";
import { withStyles } from "@material-ui/core";
// Component styles
import styles from "theme/skin";
import debounce from "lodash/debounce";
import AddWarrantyRegistration from "./WarrantyRegistration/AddWarrantyRegistration";
import WarrantyRegistrationTabs from "./WarrantyRegistration/WarrantyRegistrationTabs";
import { CSVLink } from "react-csv";
import GridContainer from "@material-ui/core/Grid";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import Tooltip from "@material-ui/core/Tooltip";

const searchMessages = {
  searchPlaceholder: "HIN / Dealer / Date Submitted..",
};

class WarrantyRegistrations extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      apolloArgs: {
        page: 0,
        limit: 25,
        search: "",
      },
      warrantyData: [],
      columns: [
        { name: "warranty_id", title: "#" },
        { name: "submittedon", title: "Submitted" },
        { name: "hull_identification_number", title: "HIN" },
        { name: "model", title: "Model" },
        {
          name: "admin_name",
          title: "Dealer",
          getCellValue: (row) => row?.profiles?.name || "",
        },
        { name: "status", title: "Status" },
      ],
      pageSizes: [25, 50, 100, 250, 500],
    };
    this.setSelectionValue = (value) => this.setState({ selection: value });
    this.onCurrentPageChange = (value) => this.onCurrentPageChange.bind(this);
    this.changeApolloSearchArgs = this.changeApolloSearchArgs.bind(this);

    this.changeApolloSearchArgs = debounce(this.changeApolloSearchArgs, 3000);
  }

  isOnlyNumbers = (str) => {
    return /^\d+$/.test(str.trim());
  };

  getQueryVariables = () => {
    const { search, page, limit } = this.state.apolloArgs;
    let where = {};
    if (search) {
      const strFirstThree = search.toLowerCase().substring(0, 3);
      var isValidDate = new Date(search);
      if (["ehk", "ekh", "csy", "agg", "see", "us-"].includes(strFirstThree)) {
        where = {
          _or: [{ hull_identification_number: { _ilike: "%" + search + "%" } }],
        };
      } else if (!isNaN(isValidDate.valueOf()) && search.includes("-")) {
        where = {
          _or: [{ submittedon: { _eq: `%${search}%` } }],
        };
      } else if (this.isOnlyNumbers(search)) {
        where = {
          _or: [
            {
              warranty_id: { _eq: parseInt(search) },
            },
          ],
        };
      } else {
        where = {
          _or: [
            { model: { _ilike: `%${search}%` } },
            { admin_name: { _ilike: `%${search}%` } },
            { status: { _ilike: `%${search}%` } },
          ],
        };
      }
    }

    return {
      skip: page * limit,
      limit,
      where,
    };
  };

  createRowDetail(refreshWarrantyRegistrations) {
    return ({ row }) => (
      <div>
        <List dense={true}>
          <WarrantyRegistrationTabs
            refreshWarrantyRegistrations={refreshWarrantyRegistrations}
            rowData={row}
            id={row?.id}
          />
        </List>
      </div>
    );
  }

  changeSearchValue = (search, refetch) => {
    this.setState(
      (state) => ({ search }),
      () => {
        this.changeApolloSearchArgs(search, refetch);
      }
    );
  };

  changeApolloSearchArgs = (search, refetch) => {
    this.setState(
      (state) => ({
        apolloArgs: { ...state.apolloArgs, search: search, page: 0 },
      }),
      () => {
        refetch(this.getQueryVariables());
      }
    );
  };

  changePageSize = (pageSize, refetch) => {
    this.setState(
      (state) => ({ apolloArgs: { ...state.apolloArgs, limit: pageSize } }),
      () => {
        const { apolloArgs } = this.state;
        refetch({
          skip: apolloArgs.page * apolloArgs.limit,
        });
      }
    );
  };
  mergeArrays = (arrays) => {
    if (arrays?.length > 0) {
      return Object.keys(arrays).map((index) => arrays[index]);
    }
    return [];
  };
  loadMoreRegistrations = (currentPage, refetch, fetchMore) => {
    this.setState(
      (state) => ({
        ...state,
        apolloArgs: { ...state.apolloArgs, page: currentPage },
      }),
      () => {
        const {
          apolloArgs: { search },
        } = this.state;

        if (search) {
          refetch(this.getQueryVariables());
        } else {
          fetchMore({
            variables: this.getQueryVariables(),
            updateQuery: (previousResult, { fetchMoreResult }) => {
              if (!fetchMoreResult) {
                return previousResult;
              }

              return {
                ...previousResult,
                data: fetchMoreResult?.data,
              };
            },
          });
        }
      }
    );
  };

  render() {
    const { columns, selection, search, pageSizes, apolloArgs } = this.state;
    const { classes } = this.props;
    const { page, limit } = apolloArgs;

    return (
      <DashboardLayout title="Warranty Registration">
        <Query
          query={getWarranties}
          notifyOnNetworkStatusChange={true}
          fetchPolicy="cache-and-network"
          onCompleted={(data) => {
            this.setState({
              warrantyData: data?.warranty_registration,
            });
          }}
        >
          {({ data, loading: loadingDownload, error, fetchMore, refetch }) => {
            // if (loadingDownload) {
            //   return (
            //     <div className="flex center" style={{ padding: "2em 0" }}>
            //       <p>Loading...</p>
            //       <CircularProgress />
            //     </div>
            //   );
            // }
            return (
              <Query
                query={getAllWarranties}
                notifyOnNetworkStatusChange={true}
                variables={this.getQueryVariables()}
                fetchPolicy="cache-and-network"
              >
                {({ data, loading, error, fetchMore, refetch }) => {
                  if (loadingDownload)
                    return (
                      <div className="flex center" style={{ padding: "2em 0" }}>
                        <p>Loading...</p>
                        <CircularProgress />
                      </div>
                    );
                  if (error) {
                    return <p>Error</p>;
                  }
                  return (
                    <div className={classes.root + " sectionContainer"}>
                      <Typography gutterBottom variant="h4" className="title">
                        <span>Warranty Registrations</span>
                      </Typography>

                      <MaterialPaper
                        className={classes.paper}
                        style={{ margin: "2em 0px" }}
                      >
                        <MaterialGrid container>
                          <MaterialGrid item xs align="center">
                            <AddWarrantyRegistration />
                          </MaterialGrid>
                        </MaterialGrid>
                      </MaterialPaper>

                      <Paper className="tableStyles">
                        <div className={classes.header}>
                          <span className={classes.headerTitle}>
                            Warranty Registrations
                          </span>
                          <GridContainer
                            container
                            justify="space-between"
                            className={classes.iconHeaderContainer}
                          >
                            <GridContainer item xs={12} sm={3}></GridContainer>
                            <GridContainer item xs={12} sm={3}>
                              <CSVLink
                                data={data?.warranty_registration?.map(
                                  (item) => {
                                    const { profiles, ...rest } = item;
                                    const Dealer = profiles?.name || "";
                                    return { ...rest, Dealer };
                                  }
                                )}
                                target="_blank"
                                filename={"export-warranty-registrations.csv"}
                              >
                                <Tooltip title="Download To CSV">
                                  <CloudDownloadOutlinedIcon />
                                </Tooltip>
                              </CSVLink>
                            </GridContainer>

                            <GridContainer item xs={12} sm={3}>
                              <CSVLink
                                data={
                                  this.state.warrantyData?.map((item) => {
                                    const { profiles, ...rest } = item;
                                    const Dealer = profiles?.name || "";
                                    return { ...rest, Dealer };
                                  }) || []
                                }
                                target="_blank"
                                filename={"export-warranty-registrations.csv"}
                              >
                                <Tooltip title="Download All To CSV">
                                  <DownloadIcon />
                                </Tooltip>
                              </CSVLink>
                            </GridContainer>
                          </GridContainer>
                        </div>
                        {loading ? (
                          <div
                            className="flex center"
                            style={{ padding: "2em 0" }}
                          >
                            <p>Loading...</p>
                            <CircularProgress />
                          </div>
                        ) : (
                          <Grid
                            rows={data?.warranty_registration || []}
                            columns={columns}
                          >
                            <SearchState
                              value={search}
                              onValueChange={(value) =>
                                this.changeSearchValue(value, refetch)
                              }
                            />
                            <PagingState
                              pageSize={apolloArgs.limit}
                              currentPage={apolloArgs.page}
                              onCurrentPageChange={(_currentPage) =>
                                this.loadMoreRegistrations(
                                  _currentPage,
                                  refetch,
                                  fetchMore
                                )
                              }
                              onPageSizeChange={(_pageSize) =>
                                this.changePageSize(_pageSize, refetch)
                              }
                            />
                            <CustomPaging
                              totalCount={
                                data?.warranty_registration_aggregate?.aggregate
                                  ?.totalCount || 0
                              }
                            />
                            <RowDetailState />
                            <Table />
                            <TableHeaderRow />
                            <TableRowDetail
                              contentComponent={this.createRowDetail(refetch)}
                            />
                            <PagingPanel pageSizes={pageSizes} />
                            <Toolbar />
                            <SearchPanel messages={searchMessages} />
                          </Grid>
                        )}
                        {/* <Grid
                          rows={data?.warranty_registration}
                          columns={[
                            { name: "warranty_id", title: "#" },
                            { name: "submittedon", title: "Submitted" },
                            {
                              name: "hull_identification_number",
                              title: "HIN",
                            },
                            { name: "model", title: "Model" },
                            {
                              name: "admin_name",
                              title: "Dealer",
                              getCellValue: (row) => row?.profiles?.name || "",
                            },
                            { name: "status", title: "Status" },
                          ]}
                        >
                          <SearchState
                            value={search}
                            onValueChange={changeSearchValue}
                          />
                          <SortingState
                            defaultSorting={[
                              { columnName: "warranty_id", direction: "desc" },
                            ]}
                          />
                          <PagingState defaultCurrentPage={0} pageSize={10} />
                          <IntegratedFiltering />
                          <IntegratedSorting />
                          <IntegratedPaging />
                          <RowDetailState defaultExpandedRowIds={[0]} />
                          <Table />
                          <TableHeaderRow showSortingControls />
                          <TableRowDetail
                            contentComponent={createRowDetail()}
                          />
                          <PagingPanel />
                          <Toolbar />
                          <SearchPanel messages={searchMessages} />
                        </Grid> */}
                      </Paper>
                    </div>
                  );
                }}
              </Query>
            );
          }}
        </Query>
      </DashboardLayout>
    );
  }
}
WarrantyRegistrations.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(WarrantyRegistrations);
