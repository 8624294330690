import React, { Component } from "react";
import { Mutation } from "react-apollo";
import { gql } from "apollo-boost";
import { Button } from "@material-ui/core";
import { toast } from "react-toastify";

const styles = {
  button: {
    margin: 8,
    backgroundColor: "black",
    color: "white"
  },
  actions: {
      display: 'flex',
      justifyContent: 'center'
  },
  completedContainer: {
      textAlign: 'center',
      padding: '2em 0px'
  },
  completedTitle: {
      fontSize: 14,
      color: '#1f3340',
      textTransform: 'uppercase',
      textAlign: 'center'  
  }
};
const UPDATE_CAREERS = gql`
  mutation updateCareers(
    $id: uuid!
    $status: String
  ) {
    update_careers(
      where: { id: { _eq: $id } }
      _set: { status: $status }
    ) {
      affected_rows
      returning {
        id
        status
      }
    }
  }
`;

export default class CareersDenyButton extends Component {
  constructor({ tag, id }) {
    super();
    this.state = {
      tag: tag,
      id: id
    };
  }

  componentDidUpdate = prevProps => {
    if (this.props.tag !== prevProps.tag) {
      this.setState({
        tag: this.props.tag
      });
    }
  };

  componentWillReceiveProps = () => {
    //console.log("componentWillReceiveProps");
  };

  completedAdd = payload => {
    this.setState({ tag: "Closed" });
    toast("This Job Post was Closed");
  };

  render() {
    const tag = this.state.tag;
    return (
      <Mutation mutation={UPDATE_CAREERS}>
        {(careers, { data }) => {
          if (tag) {
            return (
              <div style={styles.completedContainer}>
                {" "}
                <span style={styles.completedTitle}>The Job Has been {tag}</span>
              </div>
            );
          } else {
            return (
              <Button
                variant="contained"
                color="primary"
                style={styles.button}
                onClick={e => {
                  careers({
                    variables: {
                      id: this.state.id,
                      status: "Closed"
                    }
                  });
                  this.completedAdd();
                }}
              >
                Close Job
              </Button>
            );
          }
        }}
      </Mutation>
    );
  }
}
