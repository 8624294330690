export default theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.border}`,
    display: 'flex',
    alignItems: 'center',
    height: '64px',
    zIndex: theme.zIndex.appBar
  },
  toolbar: {
    minHeight: 'auto',
    width: '100%',
  },
  title: {
    marginLeft: '155px',
    color: theme.palette.common.white
  },
  menuButton: {
    marginLeft: '45px',
    color: theme.palette.common.white
  },
  notificationsButton: {
    margin: 'auto',
    //color: theme.palette.common.white
  },
  signOutButton: {
    marginLeft: theme.spacing(),
    color: theme.palette.common.white,
    fontWeight: 'normal',
    fontSize: '14px'
  }
});
