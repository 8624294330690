import React, { useState } from "react";
import PropTypes from "prop-types";
import {Mutation, Query} from "react-apollo";
import { useQuery } from "@apollo/client";
import {GET_BOAT_MODEL, UPDATE_BOAT_MODEL} from "../../../queries";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography, CircularProgress } from "@material-ui/core";
// Shared layouts
import { Dashboard as DashboardLayout } from "layouts";
// Component styles
import styles from "theme/skin";
import EditUser from "../Settings/EditUser";
import { withRouter } from "react-router";
import compose from "recompose/compose";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import useForm from "../../../components/useForm";
import {toast} from "react-toastify";

function MyDealersEdit(props) {

  const [model, setModel] = useState('');
  const [olderModel, setOlderModel] = useState(false);

  const { classes } = props;
  const customStyles = {
    updateBtn: {
      padding: "8px",
      fontSize: "14px",
      marginTop: "32px",
      fontWeight: "100"
    },
    paper: {
      margin: "2em 0px",
      padding: "32px"
    }
  }

  const { loading, error, data } = useQuery(GET_BOAT_MODEL, {
    variables: {id: props.match.params.boatId },
    onCompleted: (completedData) =>
    {
      setModel(completedData.boat_models_by_pk.model)
      setOlderModel(completedData.boat_models_by_pk.older_model);
    }
  });

  function onUpdatecompleted() {
    toast("Boat model was been successfully updated");
  }
  
  return (
      <DashboardLayout title="Edit Dealer">
        <div className={classes.root + " sectionContainer"}>
          <Typography gutterBottom variant="h4" className="title">
            <span>Edit Boat Model</span>
          </Typography>

          <Paper className={classes.paper} style={customStyles.paper}>
            <Grid container>
              <Grid item xs align="center">
                {loading &&
                          <div className="flex center" style={{ padding: "2em 0" }}>
                            <p>Loading...</p>
                            <CircularProgress />
                          </div>
                }
                {error && <p>Error ${error.message}</p>}
                <Mutation mutation={UPDATE_BOAT_MODEL} onCompleted={onUpdatecompleted}>
                  {(updateBoatModel, { data, loading, called, error }) => {
                    if (error)
                      return (
                          <pre>
                                Bad:{" "}
                            {error.graphQLErrors.map(({ message }, i) => (
                                <span key={i}>{message}</span>
                            ))}
                              </pre>
                      );
                    return (
                        <form
                            onSubmit={e => {
                              e.preventDefault();
                              updateBoatModel({
                                variables: {
                                  id: props.match.params.boatId,
                                  model: model,
                                  older_model: olderModel
                                }
                              });
                              return false;
                            }}
                            className={classes.container}
                            autoComplete="off"
                        >
                          <Grid
                              container
                              direction="row"
                              justify="space-between"
                              alignItems="center"
                          >
                            <Grid
                                container
                                direction="row"
                                justify="space-between"
                                alignItems="center"
                                wrap="nowrap"
                            >
                              <TextField
                                  required
                                  variant="outlined"
                                  name="model"
                                  label="Model"
                                  onChange={(e) => setModel(e.target.value) }
                                  value={model}
                                  className={classes.textField}
                                  style={{width: "100%", margin: "1em"}}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                              />
                              <FormControlLabel
                                  control={
                                    <Checkbox
                                        name="older_model"
                                        checked={olderModel}
                                        onChange={() => setOlderModel(!olderModel)}
                                        value="true"
                                    />
                                  }
                                  label="Older&nbsp;Model"
                              />
                            </Grid>
                            <Grid container>
                              <Grid item xs={12} align="center">
                                <InputLabel
                                    id="errorMsgs"
                                    value={error}
                                    width="100%"
                                    ref={error}
                                ></InputLabel>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} align="center">
                              <Button
                                  variant="contained"
                                  color="primary"
                                  style={customStyles.updateBtn}
                                  type="submit"
                              >
                                UPDATE BOAT MODEL
                              </Button>
                            </Grid>
                          </Grid>
                        </form>
                    );
                  }}
                </Mutation>
                
                
                
                
              </Grid>
            </Grid>
          </Paper>
        </div>
      </DashboardLayout>
  );
}

MyDealersEdit.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(withRouter, withStyles(styles))(MyDealersEdit);
