import React from "react";
import WarrantyClaimMediaItem from "./WarrantyClaimMediaItem";
import { withStyles } from "@material-ui/core";
import PropTypes from "prop-types";

const styles = {
  cardContainer: {
    display: "flex",
    flexWrap: "wrap"
  },
  marginFix: {
    marginTop: 19,
    marginBottom: 10,
    marginLeft: 5,
    marginRight: 5
  }
};
function WarrantyClaimMedia(props) {
  const { classes } = props;
  const mediaItems = props.photos.map((mediaItem, index) => {
    //console.log(mediaItem);
    return <WarrantyClaimMediaItem key={index} profileid={props.profileid} item={mediaItem} />;
  });
  return (
    <div>
      <div className={classes.cardContainer}>
        {classes.cardContainer.display}
        {mediaItems}
      </div>
    </div>
  );
}

WarrantyClaimMedia.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(WarrantyClaimMedia);
