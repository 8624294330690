import React, { Component } from "react";
import WarrantyClaimApproveButton from "./WarrantyClaimApproveButton";
import WarrantyClaimDenyButton from "./WarrantyClaimDenyButton";
import WarrantyClaimUpdateButton from "./WarrantyClaimUpdateButton";
import WarrantyClaimUpdateComment from "./WarrantyClaimUpdateComment";
import WarrantyClaimEditButton from "./WarrantyClaimEditButton";
export default class WarrantyClaimUpdate extends Component {

    constructor({status, warranty}) {
        super()
        //console.log(warranty)
        this.state = {
            status: status,
            btnText: "",
            warranty: warranty,
            isEmptyState: true
        }
    }

    triggerEditWarrantyState = () => {
        this.setState({
          ...this.state,
          status: "under review",
          btnText: "Mark Under Review",
          isEmptyState: false,
          isEditWarrantyState: true
        })
      }

    triggerDenyWarrantyState = () => {
        this.setState({
          ...this.state,
          status: "denied",
          btnText: "Deny Claim",
          isEmptyState: false,
          isEditWarrantyState: true
        })
      }

    triggerApproveWarrantyState = () => {
        this.setState({
            ...this.state,
            status: "approved",
            btnText: "Approve Claim",
            isEmptyState: false,
            isEditWarrantyState: true
        })
    }

    triggerSubmitWarrantyState = () => {
          this.setState({
            ...this.state,
            status: "new",
            isEmptyState: true,
            isEditWarrantyState: false
          })
        }

    render() {
        return (
        <div style={{textAlign: "center", padding: 10}}>
            {this.state.isEmptyState && (this.state.status === "new" || this.state.status === "under review") && <WarrantyClaimApproveButton id={this.state.warranty.id} addClick={this.triggerApproveWarrantyState} />}

            {this.state.isEmptyState && this.state.status === "new" &&  <WarrantyClaimUpdateButton id={this.state.warranty.id} addClick={this.triggerEditWarrantyState} />}

            {this.state.isEmptyState && (this.state.status === "new" || this.state.status === "under review") &&  <WarrantyClaimDenyButton id={this.state.warranty.id} addClick={this.triggerDenyWarrantyState} />}

            {this.state.isEditWarrantyState && <WarrantyClaimUpdateComment btnStatus={this.state.status} btnText={this.state.btnText} warranty={this.state.warranty} addClick={this.triggerSubmitWarrantyState} />}

            {this.state.isEmptyState && <WarrantyClaimEditButton id={this.state.warranty.id} />}
        </div>
        );
    }

}