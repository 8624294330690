import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { 
    Grid,
    Paper,
    Typography
  } from '@material-ui/core';
import { Dashboard as DashboardLayout } from "layouts";

// Component styles
import styles from "theme/skin";

  function PreauthClaim(props) {
    const { classes } = props;  
    return (
      <DashboardLayout title="Download Forms">
          <div className={classes.root}>
            <Paper className={classes.paper}>
              <Grid container>
                <Grid item xs align="center">
                  <Typography gutterBottom variant="h4">
                    - Download Forms -
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
         </div>
      </DashboardLayout>
    );
}

PreauthClaim.propTypes = {
    classes: PropTypes.object.isRequired,
};
  
export default withStyles(styles)(PreauthClaim);