import React, { useState } from 'react';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableRowDetail
} from '@devexpress/dx-react-grid-material-ui';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { RowDetailState } from '@devexpress/dx-react-grid';
import { RequestRowDetail } from '../../FormsDatasheets/CoopPreapprovalClaimForms/NewRequest/MediaPreApprovalFormRequestRowDetails';
import '../../FormsDatasheets/CoopPreapprovalClaimForms/ApprovalForms/CoOpTabs/RequestRowDetail.scss';
import { Typography } from "@material-ui/core";


const data = [
  { date_submitted: '07/07/2022', form: 'Media Pre-Approval', submitted_by: 'johndoe@gmail.com', status: 'Pending' },
  { date_submitted: '07/26/2022', form: 'Government/Commercial Sales Credit Clam', submitted_by: 'jane@gmail.com', status: 'Pending' },
  { date_submitted: '07/07/2022', form: 'Media Pre-Approval', submitted_by: 'johndoe@gmail.com', status: 'Pending' },
  { date_submitted: '07/26/2022', form: 'Government/Commercial Sales Credit Clam', submitted_by: 'jane@gmail.com', status: 'Approved' },
  { date_submitted: '07/07/2022', form: 'Media Pre-Approval', submitted_by: 'johndoe@gmail.com', status: 'Pending' },
  { date_submitted: '07/26/2022', form: 'Government/Commercial Sales Credit Clam', submitted_by: 'jane@gmail.com', status: 'Pending' },
  { date_submitted: '07/07/2022', form: 'Media Pre-Approval', submitted_by: 'johndoe@gmail.com', status: 'Pending' },
  { date_submitted: '07/26/2022', form: 'Government/Commercial Sales Credit Clam', submitted_by: 'jane@gmail.com', status: 'Denied' },
  { date_submitted: '07/07/2022', form: 'Media Pre-Approval', submitted_by: 'johndoe@gmail.com', status: 'Pending' },
  { date_submitted: '07/26/2022', form: 'Government/Commercial Sales Credit Clam', submitted_by: 'jane@gmail.com', status: 'Pending' },
];

const columns = [
  { name: 'date_submitted', title: 'Date Submitted' },
  { name: 'form', title: 'Form' },
  { name: 'submitted_by', title: 'Submitted By' },
  { name: 'status', title: 'Status' },
];

const LastColumnCell = ({ column, value }) => (
  <Table.Cell style={column.name === 'status' ? { color: '#5BA732', fontWeight: 'bold' } : {}}>
    {value}
  </Table.Cell>
);

const PreAuthClaimForms = (props) => {
  const [expandedRowIds, setExpandedRowIds] = useState([0]);

  const toggleRowExpand = (rowId) => {
    setExpandedRowIds((prevState) =>
      prevState.includes(rowId)
        ? prevState.filter((id) => id !== rowId)
        : [...prevState, rowId]
    );
  };

  const TableRow = ({ tableRow, ...restProps }) => (
    <Table.Row
      {...restProps}
      onClick={() => toggleRowExpand(tableRow.rowId)}
      style={{ cursor: 'pointer' }}
    />
  );
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
    '.MuiAppBar-root': {
      backgroundColor: "#000"
    }
  }));
  const classes = useStyles();

  return (
    <form>
        <Typography
            gutterBottom
            variant="h5"
            className={classes.subtitle2}
            align="center"
        >PRE-AUTH CLAIM FORMS</Typography>   

        <div className="request-table-pg">      
        <Grid rows={data} columns={columns}>
            {/* <RowDetailState expandedRowIds={expandedRowIds} /> */}
            <Table cellComponent={(props) => <LastColumnCell {...props} />} rowComponent={TableRow} />
            {/* <TableRowDetail contentComponent={RequestRowDetail} /> */}
            <TableHeaderRow />
        </Grid>
        </div>
    </form>
  );
};

export default PreAuthClaimForms;