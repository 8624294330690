import React from "react";
import AlbumMediaItem from "./AlbumMediaItem";
import { withStyles } from "@material-ui/core";
import PropTypes from "prop-types";

const styles = {
  cardContainer: {
    display: "flex",
    flexWrap: "wrap"
  }
};
function AlbumMediaList(props) {
  const { classes } = props;
  const mediaItems = props.items.map((mediaItem, index) => {
    return <AlbumMediaItem key={index} item={mediaItem} />;
  });

  return (
    <div className={classes.cardContainer}>
      {classes.cardContainer.display}
      {mediaItems}
    </div>
  );
}

AlbumMediaList.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AlbumMediaList);
