import React from "react";
import {
    Image,
    Text,
    View
} from "@react-pdf/renderer";

const APP_SERVER = "https://carolina-skiff-dealernet.s3.amazonaws.com/warranty_claims/";

function WarrantyClaimPdfImages(props) {
  //console.log(props.photos)
  const { profileid } = props;
  return (
    <View>
    {props.photos.map((mediaItem, index) => (
        <Image key={index} style={{width:'125px', height: '125px'}} src={APP_SERVER + profileid + "/" + mediaItem} />
      ))}
    </View>
  )
}

export default WarrantyClaimPdfImages;
