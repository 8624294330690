export default theme => ({
  root: {
    padding: theme.spacing(4)
  },
  button: {
    marginTop: "1em",
    margin: theme.spacing()
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: 350
  },
  prefixDropdown: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    width: 100
  },
  tableStriped: {
    "& tbody tr:nth-of-type(odd)": {
      backgroundColor: "#f8fcff"
    }
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    textTransform: "uppercase",
    padding: "2em 1em",
  },
  headerTitle: {
    fontSize:"20px",
  },
  heading: {
    textAlign: "center",
    fontSize: "20px",
    padding: "1em 0px",
    textTransform: "uppercase"
  },
  tableStyles: {
    "td": {
      border: "0px !important",
    },
  },
  detailTable: {
    width: "100%",
    fontSize: "14px",
    tableLayout: "inherit",
    padding: "2em 1em",
    "tr": {
      "td": {
        padding:"0.5em 1em",
        color: "#4479a3",
        borderBottom: "1px solid #4479a3 !important",
      },
      "td:first-child": {
        fontWeight: "bold",
      },
    },
  },
  description: {
    textAlign: "center",
    fontSize: "10px"
  },
  msrpTitle: {
    fontSize: "20px",
    textTransform: "uppercase",
    padding: "1.5em 0px",
    fontWeight: 300,
    borderBottom: "1px solid #2d6c9b",
    width: "85%"
  },
  subTitle: {
    fontSize: "10px",
    textAlign: "center"
  },
  title: {
    fontSize: "18px",
    textAlign: "center",
    textTransform: "uppercase",
    margin: "1em",
    fontWeight: 300
  },
  iconHeaderContainer: {
    width: "100px",
  },
  printIcon: {
    border: "1px solid #2d6c9b",
    borderRadius: "50%",
    height: "25px",
    width: "25px",
  }
});
