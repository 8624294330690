import React, { useState } from "react";
import "../ApprovalForms/CoOpTabs/RequestRowDetail.scss";
import { SpecialProjectFormPdfDocument } from "./SpecialProjectFormPdfDocument";
import SpecialProjectFormEditButton from "../ApprovalForms/SpecialProjectFormEditButton";
import AdminStatusButtons from "pages/Administration/CoopRequests/AdminStatusButtons";
import PDFimg from '../../../../images/pdf-file-format.png';
import { makeStyles } from "@material-ui/styles";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import { CSVLink } from "react-csv";
import excelimg from "./../../../../images/excel.png"
import CommentBox from "pages/Administration/CoopRequests/CommentBox";
import moment from "moment";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const useStyles = makeStyles((theme) => ({
  PrimaryButton: {
    backgroundColor: '#456790',
    color: '#fff',
    fontSize: '15px',
    "&:hover": {
      backgroundColor: '#2167BC',
    }
  },
  CustomSuccessButton: {
    backgroundColor: '#3B8010',
    color: '#fff',
    fontSize: '15px',
    "&:hover": {
      backgroundColor: '#5D9930',
    },
    padding: "0.58rem 1rem",
    display: "inline-flex",
    alignItems: "center",
    borderRadius: "3px",
    verticalAlign: "middle",
    textDecoration: "none"
  },
  modal: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    maxHeight: '95%',
    overflow: 'scroll',
    top: '3.5% !important',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #707070',
    boxShadow: '0px 3px 6px 0px #00000029',
    padding: theme.spacing(4),
    width: '768px',
    maxWidth: '90%',
  },
}));

export const SpecialProjectFormRequestRowDetail = ({ comments, userName, isDisplay, isEdit, row, type, co_op_id }) => {
  const classes = useStyles();
  const capitalize = s => (s && s[0].toUpperCase() + s.slice(1)) || ""

  const [openPrint, setOpenPrint] = useState(false);
  const [isComment, setIsComment] = useState(false);
  const [text, setText] = useState("")

  const handleOpenPrint = () => {
    setOpenPrint(true);
  };
  ;
  const handleClosePrint = () => {
    setOpenPrint(false);
  };

  const tableBody = <tbody>
    <tr>
      <td>Dealer Name:</td>
      <td>{row?.dealer_name || ""}</td>
    </tr>
    <tr>
      <td>Contact Name:</td>
      <td>{row?.contact_name || ""}</td>
    </tr>
    <tr>
      <td>Contact Phone:</td>
      <td>{row?.phone || ""}</td>
    </tr>
    <tr>
      <td>Fax Number:</td>
      <td>{row?.fax || ""}</td>
    </tr>
    <tr>
      <td>Address:</td>
      <td>{row?.address || ""}</td>
    </tr>
    <tr>
      <td>Address 2:</td>
      <td>{row?.address2 || ""}</td>
    </tr>
    <tr>
      <td>City:</td>
      <td>{row?.city || ""}</td>
    </tr>
    <tr>
      <td>State:</td>
      <td>{row?.state || ""}</td>
    </tr>
    <tr>
      <td>Country:</td>
      <td>{row?.country || ""}</td>
    </tr>
    <tr>
      <td>Zip Code:</td>
      <td>{row?.zipcode || ""}</td>
    </tr>
    <tr>
      <td>Description of Project:</td>
      <td>{row?.description || ""}</td>
    </tr>
    <tr>
      <td>Total cost of project:</td>
      <td>{row?.total_cost ? `$${row?.total_cost}` : ""}</td>
    </tr>
    <tr>
      <td>% Devoted:</td>
      <td>{row?.devoted ? `${row?.devoted}%` : ""}</td>
    </tr>
    <tr>
      <td>Cost of devoted:</td>
      <td>{row?.cost_devoted ? `$${row?.cost_devoted}` : ""}</td>
    </tr>
    <tr>
      <td>Total Credit:</td>
      <td>{row?.total_credit ? `$${row?.total_credit}` : ""}</td>
    </tr>
    <tr>
      <td>Additional Information:</td>
      <td className='wordBreak'>{row?.additional_info || ""}</td>
    </tr>
    {comments && comments?.length > 0 &&
      <tr>
        <td>Comments:</td>
        <td>{""}</td>
      </tr>
    }
    {comments && comments?.length > 0 && comments.map((item) => {
      return (
        <tr>
          {item?.status ? <td>{capitalize(item?.status)} Comments:</td> : <td>
            <div>{item?.profile_name || ""}</div>
            <span>{moment(item?.created_at).format('MMMM D, YYYY h:mm a')}</span>
          </td>
          }
          <td><div dangerouslySetInnerHTML={{ __html: (item?.comment) || "" }}></div></td>
        </tr>
      )
    })
    }
  </tbody>

  const data = [
    ["Dealer Name", row?.dealer_name || ""],
    ["Contact Name", row?.contact_name || ""],
    ["Contact Phone", row?.phone || ""],
    ["Fax Number", row?.fax || ""],
    ["Address", row?.address || ""],
    ["Address 2", row?.address2 || ""],
    ["City", row?.city || ""],
    ["State", row?.state || ""],
    ["Country", row?.country || ""],
    ["Zip Code", row?.zipcode || ""],
    ["Description of Project", row?.description || ""],
    ["Total cost of project", `$${row?.total_cost}` || ""],
    ["% Devoted", `${row?.devoted}%` || ""],
    ["Cost of devoted", `$${row?.cost_devoted}` || ""],
    ["Total Credit", `$${row?.total_credit}` || ""],
    ["Additional Information", row?.additional_info || ""]
  ];

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleClosePrint();
    }
  };

  const generatePdf = () => {
    const input = document.getElementById('pdf-content');
    html2canvas(input, { scale: 1 })
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'pt', 'a4');
        pdf.addImage(imgData, 'PNG', 0, 0);
        pdf.save(`special_project_form_${row?.dealer_name}.pdf`);
      })
      .catch(err => {
        console.error("Error generating PDF: ", err);
      });
  }

  return (
    <div className="request-detail-pg">
      {
        type !== "admin" &&
        <>
          {isEdit && <SpecialProjectFormEditButton item={row} />}
          <Button className={`${classes.PrimaryButton} MuiButtonBase-root MuiButton-root MuiButton-containedSecondary MuiButton-contained`}
            onClick={generatePdf}><><img src={PDFimg} alt="PDF" width='18px' style={{ marginRight: "8px" }} />Print To Pdf</></Button>
          <div id="pdf-content" className="offscreen">
            <SpecialProjectFormPdfDocument
              data={row}
            />
          </div>
        </>
      }
      {
        type === "admin" &&
        <>
          <Button
            color="primary"
            variant="contained"
            className={classes.PrimaryButton}
            type="submit"
            onClick={handleOpenPrint}
            style={{ margin: "2em 2em 2em 0em" }}
          >   <img src={PDFimg} alt="PDF" width='18px' style={{ marginRight: "8px" }} />
            PRINT TO PDF
          </Button>
          <div id="pdf-content" className="offscreen">
            <SpecialProjectFormPdfDocument
              data={row}
            />
          </div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={openPrint}
            width="fullWidth"
            onClose={handleClosePrint}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={openPrint}>
              <div className={classes.paper}>
                <div className='customModalBody'>
                  <table cellSpacing="0" className="request-detail-table" width="100%" style={{ padding: '0px' }}>
                    {tableBody}
                  </table>
                  <br></br>
                  <span onKeyDown={handleKeyPress} onClick={() => handleClosePrint()}>
                    <Button className={`${classes.PrimaryButton} MuiButtonBase-root MuiButton-root MuiButton-containedSecondary MuiButton-contained`}
                      onClick={generatePdf}><><img src={PDFimg} alt="PDF" width='18px' style={{ marginRight: "8px" }} />Print To Pdf</></Button>
                  </span>
                  <Button
                    color="secondary"
                    variant="contained"
                    className={classes.Button}
                    onClick={() => handleClosePrint()}
                    style={{ width: "120px", margin: "2em 1em" }}
                  >
                    CLOSE
                  </Button>
                </div>
              </div>
            </Fade>
          </Modal>
          <CSVLink data={data} filename={"special_project_form_" + row?.dealer_name + ".csv"} className={classes.CustomSuccessButton}>
            <img src={excelimg} alt="PDF" width='20px' style={{ marginRight: "8px" }} />
            Export to EXCEL
          </CSVLink>
        </>
      }
      <table cellSpacing="0" className="request-detail-table" width="100%">
        {tableBody}
      </table>
      {type === "admin" &&
        <AdminStatusButtons co_op_id={co_op_id} id={row?.id} type="special-project-request" statusType={row?.status} />
      }
      {
        !isDisplay &&
        <>
          {
            !isComment &&
            <Button onClick={() => setIsComment(true)}>
              Add your comments here
            </Button>
          }
          {
            isComment &&
            <CommentBox co_op_id={co_op_id} isComment={isComment} setIsComment={setIsComment} text={text} setText={setText} userName={userName} />
          }
        </>
      }
    </div>
  )
};
