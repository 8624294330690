import auth from "Auth";

const rules = {
  visitor: {
    static: ["posts:list", "home-page:visit"]
  },
  dealer: {
    static: auth.getPermissions(),
    dynamic: {
      "posts:edit": ({ userId, postOwnerId }) => {
        if (!userId || !postOwnerId) return false;
        return userId === postOwnerId;
      }
    }
  },
  admin: {
    static: [
      "admin:ADMIN_LEADS",
      "admin:ADMIN_MY_DEALERS",
      "admin:ADMIN_CAREERS",
      "admin:ADMIN_WARRANTY_CLAIMS",
      "admin:ADMIN_WARRANTY_LOOKUP",
      "admin:ADMIN_CSI",
      "admin:ADMIN_MEDIA",
      "admin:ADMIN_WARRANTY_INQ",
      "admin:ADMIN_CO_OP_REQ",
      "admin:ADMIN_NEW_DEALER_REQ",
      "admin:ADMIN_CONTACT_REQ",
      "admin:ADMIN_ALL"
    ]
  },
  Admin: {
    static: auth.getPermissions()
  }
};

export default rules;
