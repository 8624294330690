import React from "react";
import { withStyles } from '@material-ui/core/styles';
import { Mutation } from "react-apollo";
import { gql } from "apollo-boost";
import { Button } from '@material-ui/core';
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { toast } from "react-toastify";
import useForm from "components/useForm";
import PropTypes from 'prop-types';
import { Subscription } from "react-apollo";
import MenuItem from "@material-ui/core/MenuItem";
import { getAllAvailableDealersSubscription } from "queries";

const styles = theme => ({
    button: {
        margin: 8,
    },
    container: {
        display: "flex",
        flexWrap: "wrap"
    },
    textField: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        width: "100%",
        textAlign: "center"
    },
    dense: {
        marginTop: 19
    },
    formControl: {
        margin: theme.spacing(3)
    }
});

const UPDATE_LEAD = gql`
  mutation updateLead(
    $id: uuid!
    $profile_id: String!
  ) {
    update_leads(
      where: { id: { _eq: $id } }
      _set: { profile_id: $profile_id}
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`;
    
const LeadsTransferToDealer = (props) => {
    const message = "";
    const { classes } = props;
    const { values, handleChange, resetState } = useForm(submitForm);
    function submitForm() {
      //console.log(values);
    }
    function completedAdd(payload) {
        toast("Lead Successfully Transfered to New Dealer");
        resetState();
    }
    return (
        
        <Mutation mutation={UPDATE_LEAD} onCompleted={completedAdd}>
        {(leads, { data }) => {
            if (message) {
                return <div> {message} </div>
            }
            else {
                return (
                <div style={{textAlign: "center"}}>
                <form
                    onSubmit={e => {
                    //values.profile_id = auth.getSub();
                    props.addClick()
                    e.preventDefault();
                    leads({
                        variables: {
                            id:props.lead.id, 
                            profile_id:values.new_dealer
                        }
                    }); 

                    return false;
                    }}
            autoComplete="off"
          >
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >

              {/* Row 1 */}
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                wrap="nowrap"
              >
              <Subscription subscription={getAllAvailableDealersSubscription}>
                  {({ loading, error, data }) => {
                    if (loading)
                      return (
                        <TextField
                          variant="outlined"
                          key="Loading"
                          value="Loading.."
                        ></TextField>
                      );
                    if (error) {
                      console.log(error);
                      return <p>Error...</p>;
                    }
                    return (
                      <TextField
                        id="new_dealer"
                        name="new_dealer"
                        label="Transfer Dealer... "
                        value={values.new_dealer || ""}
                        select
                        variant="outlined"
                        onChange={handleChange}
                        className={classes.textField}
                        ref="new_dealer"
                        inputProps={{
                          name: "new_dealer",
                          id: "new_dealer"
                        }}
                      >
                        {!loading && data.profiles.length > 0
                          ? data.profiles.map((option, index) => (
                              <MenuItem key={option.profile_id} value={option.profile_id}>
                                {option.name}
                              </MenuItem>
                            ))
                          : ""}
                      </TextField>
                    );
                  }}
                </Subscription>
              </Grid>
              <Grid item xs={12} align="center">
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  type="submit"
                >
                <span>Complete Transfer</span>
                </Button>
              </Grid>
            </Grid>
          </form>
                </div>
                )
            }
        }
        }
    </Mutation>
    );

};

LeadsTransferToDealer.propTypes = {
  classes: PropTypes.object.isRequired
};
export default withStyles(styles)(LeadsTransferToDealer);